import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import classNames from 'classnames';

import type {ReadingsMultipleDto} from '@/_generatedApi';
import {
  EnergyReadingsService,
} from '@/_generatedApi';
import OdometerGaugeGraph from '@/components/graphs/odometer-gauge-graph/OdometerGaugeGraph';
import { useRepeatFunctionPeriodically } from '@/hooks/use-interval';
import { useShowToast } from '@/hooks/use-show-toast';
import { DATA_REFRESH_INTERVAL } from '@/utils/variables';

import styles from './dashboard.module.css';
import ElectricityDashboardFilters, {
} from "@/components/ui/filters/ElectricityDashboardFilters";
import {useDashboardFilter} from "@/hooks/useDashboardFilter";
import LinearProgress from "@mui/material/LinearProgress";
import { Medium } from "@/constants/mediums";

type ReadingsPageProps = {
  medium: Medium
}

const ReadingsPage: FC<ReadingsPageProps> = ({medium}) => {
  const { t } = useTranslation();
  const { showGenericErrorToast } = useShowToast();

  const {filter, updateFilter, remainingFiltersToFetch} = useDashboardFilter([], medium);

  const [odometers, setOdometers] = useState<ReadingsMultipleDto[]>(
    []
  );

  const [loadingData, setLoadingData] = useState(true);

  const fetchOdometersConsumption = useCallback(async () => {
    if (Object.keys(filter).length < 3) {
      setOdometers([]);

      return;
    }

    try {
      setLoadingData(true);
      const data = (
        await EnergyReadingsService.getEnergyReadingsConsumption({
          ...filter,
          medium,
          consumptionStartDate: undefined,
          consumptionEndDate: undefined,
          consumptionUnit: undefined
        })
      ).data;

      if (data) {
        setOdometers(data);
        setLoadingData(false)
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [filter, showGenericErrorToast]);

  useEffect(() => {
    fetchOdometersConsumption();
  }, [fetchOdometersConsumption]);

  useRepeatFunctionPeriodically(
    () => fetchOdometersConsumption(),
    DATA_REFRESH_INTERVAL
  );

  return (
    <div className={classNames(styles.page)}>
      <div className={classNames(styles.filtersWrapper)}>
        <ElectricityDashboardFilters
          filter={filter}
          updateFilter={updateFilter}
          withDate={false}
          medium={medium}
        />
      </div>

      {remainingFiltersToFetch.length > 0 && (
        <Alert severity="info">
          {t('odometersSettingsPage.remainingFilters')}:
          <ul>
            {remainingFiltersToFetch.map((filterName) => (
              <li key={filterName}>
                {t(`electricityPage.dashboardPage.${filterName}`)}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {!loadingData && remainingFiltersToFetch.length === 0 && odometers.length === 0 && (
        <Alert severity="warning">{t('common.noDataFound')}</Alert>
      )}

      <div style={{height: 24}}>
        {loadingData && (
          <LinearProgress/>
        )}
      </div>

      <div className={classNames(styles.odometersWrapper)}>
        {odometers.map((odometerEntity) => (
          <div
            key={odometerEntity.odometer.id}
            className={classNames(styles.odometer)}
          >
            <OdometerGaugeGraph
              consumption={odometerEntity.currentConsumption}
              maxConsumption={odometerEntity.odometer.maxHourlyValue}
              totalConsumption={odometerEntity.currentValue}
              lastThreeMonthData={odometerEntity.data}
              linkToDetail={`/energy/${medium}/readings/${odometerEntity.odometer.id}`}
              title={odometerEntity.odometer.name}
              unit={odometerEntity.odometer.unit}
              medium={medium}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReadingsPage;
