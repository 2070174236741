import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AnyFunction } from '@/types/core-types';
import { COLORS } from '@/utils/variables';

import WithLink from '../../../routing/WithLink';

export type Variant = 'add' | 'update' | 'delete' | 'common';

const CButton: FC<
  PropsWithChildren<{
    type?: 'button' | 'submit' | 'reset';
    size?: 'small' | 'medium' | 'large';
    variant?: Variant;
    href?: string;
    onClick?: AnyFunction;
    onMouseDown?: AnyFunction;
    target?: '_blank';
    disabled?: boolean;
    style?: CSSProperties;
    startIcon?: ReactNode | null;
    endIcon?: ReactNode;
    disableElevation?: boolean;
  }>
> = ({
  children,
  type = 'button',
  size = 'medium',
  variant,
  startIcon,
  href,
  disableElevation = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const getColor = (variant?: Variant): string => {
    const colorMap = {
      add: '#62BCA0',
      update: '#546EB3',
      delete: '#EE6666',
      common: 'primary',
    } as const;

    return variant ? colorMap[variant] : 'primary';
  };

  const ColorButton = styled(MuiButton)<ButtonProps>(() => ({
    color:
      variant === 'add'
        ? (COLORS.purple.main as unknown as string)
        : (COLORS.white as unknown as string),
    backgroundColor: getColor(variant),
    '&:hover': {
      backgroundColor: getColor(variant),
    },
  }));

  const getIcon = (variant?: Variant) => {
    const colorMap = {
      add: <AddIcon />,
      update: <EditIcon />,
      delete: <DeleteIcon />,
      common: null,
    } as const;

    return variant && colorMap[variant];
  };

  const getTranslation = (variant?: Variant) => {
    const translationMap = {
      add: t('common.add'),
      update: t('common.edit'),
      delete: t('common.delete'),
      common: null,
    } as const;

    return variant ? translationMap[variant] : t('common.cancel');
  };

  return (
    <WithLink href={href}>
      <ColorButton
        type={type}
        size={size}
        startIcon={
          startIcon === null ? null : startIcon ? startIcon : getIcon(variant)
        }
        variant={variant ? 'contained' : 'outlined'}
        disableElevation={disableElevation}
        {...rest}
      >
        {children || getTranslation(variant)}
      </ColorButton>
    </WithLink>
  );
};

export default CButton;
