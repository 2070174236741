import { FC, PropsWithChildren, ReactElement } from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import { GridValueSetterParams } from '@mui/x-data-grid';

export type FixedLengthArray<T, L extends number> = [T, ...T[]] & {
  length: L;
};

export type ValueOf<T> = T[keyof T];

export type Maybe<T> = T | null | undefined;

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;

export type FlatObjectKeys<
  T extends Record<string, unknown>,
  K = keyof T
> = K extends string
  ? T[K] extends Record<string, unknown>
    ? `${K}.${FlatObjectKeys<T[K]>}`
    : `${K}`
  : never;

/* ------------------------------------------------------ */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction<P extends any[] = any[], T = any> = (...args: P) => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyAsyncFunction<P extends any[] = any[], T = any> = (
  ...args: P
) => Promise<T>;

export type Required<T> = {
  [P in keyof T]-?: T[P];
};

/* ------------------------------------------------------ */

export type EmptyObject = Record<string, never>;

export type UnknownObject = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>;

/* ------------------------------------------------------ */

export enum RouteType {
  AUTH = 'auth',
  UNAUTH = 'unauth',
  DEFAULT = 'default',
}

export type Menu = Record<
  string,
  {
    name: string;
    icon?: ReactElement;
    isMenuHidden?: boolean | 'mobile' | 'desktop';
    menu?: Menu;
    isRoot?: boolean;
  }
>;

export type Routes = Record<
  Uppercase<string>,
  {
    path: string;
    type: RouteType;
    icon?: ReactElement;
    Layout?: FC<PropsWithChildren>;
    component?: ReactElement;
    routes?: Routes;
    isDisabled?: boolean;
    isMenuHidden?: boolean | 'mobile' | 'desktop';
    isRoot?: boolean;
  }
>;

export type CTableValueSetterParams = GridValueSetterParams;

export type PaginationOptions = {
  onPageChange: (page: number) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
};

export type BranchOfficeListItemDto = {
  id: string;
  name: string;
  city: string;
  superiorBranchOffice: {
    id: string;
    name: string;
  };
  comment: string;
};

export type WorkshopListItemDto = {
  id: number;
  name: string;
  location: string;
  branchOffice: {
    id: string;
    name: string;
  };
  comment: string;
};

export type CenterListItemDto = {
  id: string;
  name: string;
  location: string;
  branchOffice: {
    id: string;
    name: string;
  };
  workshop: {
    id: string;
    name: string;
  };
  comment: string;
};

export type TextVariant = Variant;

export type DateView = ['year' | 'day' | 'month'];
