import { FC, PropsWithChildren, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import Main from './Main';

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
  }, [theme]);

  return (
    <Box
      style={{
        height: '100vh',
        width: '100%',
      }}
    >
      <Main>{children}</Main>
    </Box>
  );
};

export default DefaultLayout;
