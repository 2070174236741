/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadingsMultipleDto } from '../models/ReadingsMultipleDto';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnergyReadingsService {
  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getEnergyReadingsConsumption({
    mediumId,
    medium,
    typeId,
    branchOfficeId,
    workshopId,
    centerId,
    odometerId,
    groupId,
    consumptionStartDate,
    consumptionEndDate,
    consumptionUnit,
  }: {
    mediumId?: number;
    medium?: string;
    typeId?: number | string;
    branchOfficeId?: number | string;
    workshopId?: number | string;
    centerId?: number | string;
    odometerId?: number | string;
    groupId?: number | string;
    consumptionStartDate?: string;
    consumptionEndDate?: string;
    consumptionUnit?:
      | '1 minute'
      | '15 minutes'
      | '1 hour'
      | '1 day'
      | '1 week'
      | '1 month'
      | '3 months'
      | '6 months'
      | '1 year';
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<ReadingsMultipleDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/energy/readings/consumption',
      query: {
        mediumId: mediumId,
        medium: medium,
        typeId: typeId,
        branchOfficeId: branchOfficeId,
        workshopId: workshopId,
        centerId: centerId,
        odometerId: odometerId,
        groupId: groupId,
        consumptionStartDate: consumptionStartDate,
        consumptionEndDate: consumptionEndDate,
        consumptionUnit: consumptionUnit,
      },
    });
  }
}
