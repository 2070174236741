import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import classNames from 'classnames';
import ReactECharts from 'echarts-for-react';

import type { ReadingDto } from '@/_generatedApi';
import OdometerGaugeCounter from '@/components/graphs/odometer-gauge-graph/OdometerGaugeCounter';
import OdometerGraphDetail from '@/components/graphs/odometer-gauge-graph/OdometerGraphDetail';

import OverviewCard from '../../ui/overview-card/OverviewCard';

import styles from './odometerGaugeGraph.module.css';
import { Medium } from '@/constants/mediums';

const GraphTooltipDetail = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 700,
    maxWidth: 700,
  },
}));

const OdometerGaugeGraph: FC<{
  consumption: number;
  maxConsumption: number;
  totalConsumption: number;
  lastThreeMonthData: ReadingDto[];
  linkToDetail: string;
  title?: string;
  unit?: string;
  medium: Medium
}> = ({
  consumption,
  maxConsumption,
  totalConsumption,
  lastThreeMonthData,
  linkToDetail,
  title,
  unit,
  medium,
}) => {
  const { t } = useTranslation();

  const chartMaxValue = (consumption > maxConsumption * 1.2) ? consumption : maxConsumption * 1.2;

  const color = medium === Medium.electricity ? '#4bcfa7' : (medium === Medium.water ? '#5470C6' : '#FFD700')

  const option = useMemo(() => ({
    series: [
      {
        type: 'gauge',
        min: 0,
        max: chartMaxValue,
        splitNumber: 12,
        axisLine: {
          lineStyle: {
            width: 15,
            color: [
              [maxConsumption / chartMaxValue, color],
              [1, 'red'],
            ]
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 14,
          lineStyle: {
            width: 2,
            color: '#9EB8C6',
          },
        },
        axisLabel: {
          distance: 25,
          color: '#9EB8C6',
          fontSize: 10,
          formatter: function (value: number) {
            return Math.round(value);
          }
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 20,
          itemStyle: {
            borderWidth: 8,
            color: color,
            borderColor: color,
          },
        },
        pointer: {
          itemStyle: {
            color: color
          }
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: consumption,
          },
        ],
      },
    ],
  }), [consumption, maxConsumption]);


  return (
    <OverviewCard
      title={{
        text: title || t('dashboardPage.electricity'),
        variant: 'h5',
        align: 'center',
      }}
      href={linkToDetail}
      titleMaxLines={2}
    >
      <GraphTooltipDetail
        title={<OdometerGraphDetail lastThreeMonthData={lastThreeMonthData} unit={unit} />}
      >
        <div>
          <ReactECharts
            style={{ height: 300, width: '100%' }}
            option={option}
            className={classNames(styles.graph)}
          />
          <OdometerGaugeCounter consumption={consumption} totalConsumption={totalConsumption} unit={unit} />
        </div>
      </GraphTooltipDetail>
    </OverviewCard>
  );
};

export default OdometerGaugeGraph;
