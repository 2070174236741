import { FC } from 'react';
import { styled } from '@mui/material';

export type BudgeProps = {
  text: string;
  color: string;
};

const Budge: FC<BudgeProps> = ({ text, color }) => {
  const Container = styled('div')(() => ({
    backgroundColor: color,
    color: 'white',
    width: 'fit-content',
    height: 'auto',
    fontSize: 10,
    borderRadius: '7px',
    padding: '4px 9px',
  }));

  return <Container>{text}</Container>;
};

export default Budge;
