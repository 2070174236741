import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/system';

import ProfileForm from '@/components/profile/ProfileForm';
import Avatar from '@/layouts/dashboard-layout/Avatar';

const ProfilePage: FC = () => {
  const theme = useTheme();

  const ProfilePageRoot = styled('div')(() => ({
    padding: theme.spacing(1),
    display: 'flex',
    width: '100%',
    gap: '20px',
    flexDirection: 'column',
    paddingBottom: '24px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: '100px',
      paddingBottom: 0,
    },
  }));

  const ProfilePageAvatarContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      gap: '20px',
    },
  }));

  return (
    <ProfilePageRoot>
      <ProfilePageAvatarContainer>
        <Avatar
          color={theme.palette.primary.main}
          size={130}
          fontSize="42px"
          ableMenu={false}
        />
      </ProfilePageAvatarContainer>

      <Box sx={{ width: '100%' }}>
        <ProfileForm />
      </Box>
    </ProfilePageRoot>
  );
};

export default ProfilePage;
