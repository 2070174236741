import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupDto,
  GroupRequestDto,
  GroupService, PowerFactorDto, PowerFactorRequestDto,
  PowerFactorService
} from "@/_generatedApi";
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import Modal from '@/components/ui/common/modal/CModal';
import { Option } from '@/components/ui/fields/types';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateFilterData, updateObjectInArray } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';
import { nullFieldsToUndefined } from '@/utils/validation';
import PowerFactorFilters, { PowerFactorFilter, PowerFactorFiltersProps } from "@/components/ui/filters/PowerFactorFilters";
import PowerFactorForm, {
  PowerFactorFormOutputValues
} from "@/components/settings-powerFactors/SettingsPowerFactorForm";

const fetchAllPowerFactors = async () => {
  return (
    await PowerFactorService.getPowerFactorList({
      sortBy: 'name',
      descending: false,
      skip: 0,
      take: MAX_TAKE_ITEMS,
    })
  ).data
};

const fetchPowerFactorsForPage = async (
  page: number,
  itemsPerPage: number,
  filter: PowerFactorFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return await PowerFactorService.getPowerFactorList({
    sortBy: 'name',
    descending: false,
    skip,
    take: itemsPerPage,
    ...filter,
  });
};

export const PowerFactorContext = createContext<Option[] | null>(null);

const SettingsPowerFactorPage: FC = () => {
  const { t } = useTranslation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const [showModal, setShowModal] = useState(false);
  const [countOfPowerFactors, setCountOfPowerFactors] = useState(0);
  const [powerFactors, setPowerFactors] = useState<PowerFactorDto[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);

  const [chosenPowerFactor, setChosenPowerFactor] =
    useState<PowerFactorDto | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<PowerFactorFilter>({});

  const powerFactorSettingsColumnsDef: ColDef<PowerFactorDto>[] = [
    { key: 'name', nested: false, isDate: false, sortable: true },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchPowerFactorsForPage(
        currentPage,
        rowsPerPage,
        filter
      );
      setPowerFactors(filteredData.data as PowerFactorDto[]);
      setCountOfPowerFactors(filteredData.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, filter, rowsPerPage, showGenericErrorToast]);

  const handleAddPowerFactor = () => {
    setChosenPowerFactor(null);
    setShowModal(true);
  };

  const handleEditPowerFactor = (powerFactor: PowerFactorDto) => {
    if (!powerFactor) return;
    setChosenPowerFactor(powerFactor);
    setShowModal(true);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (
    values: PowerFactorFormOutputValues,
    id?: number
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    if (id) {
      try {
        const data = (
          await PowerFactorService.putPowerFactorUpdate({
            id,
            requestBody: valuesWithoutNullFields as PowerFactorRequestDto,
          })
        ).data;

        if (data) {
          setPowerFactors(
            (powerFactors) =>
              updateObjectInArray(powerFactors, data) as PowerFactorDto[]
          );
        }

        setChosenPowerFactor(null);
        setShowModal(false);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      } catch (e) {
        showGenericErrorToast();
      }
    } else {
      try {
        const data = (
          await PowerFactorService.postPowerFactorCreate({
            requestBody: valuesWithoutNullFields as PowerFactorRequestDto,
          })
        ).data;

        if (data) {
          fetchData();
        }

        setShowModal(false);
        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      } catch (e) {
        showGenericErrorToast();
      }
    }
  };

  const handleDeletePowerFactor = async (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!id) return;

    try {
      await PowerFactorService.deletePowerFactorDelete({ id });

      if (powerFactors.length === 1 && currentPage !== 1) {
        setCurrentPage((page) => page - 1);
      }

      fetchData();

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));
      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleCancel = () => {
    setChosenPowerFactor(null);
    setShowModal(false);
  };

  const updateFilter = async (
    key: string,
    value: string | boolean | undefined
  ) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  return (
    <>
      <CDataGrid
        colDef={powerFactorSettingsColumnsDef}
        translationKey="powerFactorSettingsPage"
        data={powerFactors}
        onAdd={handleAddPowerFactor}
        onDelete={handleDeletePowerFactor}
        onEdit={(values) => {
          const id = values?.id;
          const powerFactor = powerFactors.find(item => item.id.toString() === id);
          powerFactor && handleEditPowerFactor(powerFactor);
        }}
        filters={
          <PowerFactorFilters updateFilter={updateFilter} filter={filter} />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfPowerFactors,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <Modal
        open={showModal}
        title={t('navigation.power-factors')}
        onClose={() => setShowModal(false)}
        type={chosenPowerFactor ? 'edit' : 'create'}
        width={1000}
      >
        <PowerFactorForm
          powerFactor={chosenPowerFactor}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Modal>
    </>
  );
};
export default SettingsPowerFactorPage;
