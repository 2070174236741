import { FC, PropsWithChildren, useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import LangSwitcher from '@/components/lang-switcher/LangSwitcher';
import { ReactComponent as PoweredByLogo } from '@/img/poweredBy.svg';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  }, [theme]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '100vh',
        backgroundImage: 'url(/background.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          textAlign: 'center',
          paddingX: '16px',
        }}
      >
        <img
          src="/logoDark.svg"
          alt="logo"
          style={{ width: '100%', height: 'auto' }}
        />
        {children}
        <Box sx={{ marginTop: '60px' }}>
          <PoweredByLogo />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '16px',
          left: '16px',
          [theme.breakpoints.up('md')]: {
            bottom: '56px',
            left: '56px',
          },
        }}
      >
        <LangSwitcher />
      </Box>
    </Grid>
  );
};

export default AuthLayout;
