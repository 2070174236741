import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText, Typography
} from "@mui/material";
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { PowerFactorDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import Select from '@/components/ui/fields/Select';
import { useOdometers } from '@/hooks/data/use-odometers';
import { FieldArray } from "formik";

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', { count: 4 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    cosPhiBreakingPoint: yup.number().required('validation.required'),
    realPowerOdometerId: yup.number().required('validation.required'),
    apparentPowerOdometersIds: yup.array().of(yup.number()),
  });

export type PowerFactorFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type PowerFactorFormProps = {
  powerFactor: PowerFactorDto | null;
  onSubmit: (values: PowerFactorFormOutputValues, id?: number) => void;
  onCancel: () => void;
};

const PowerFactorForm: FC<PowerFactorFormProps> = ({
  powerFactor,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dialogType = powerFactor ? 'update' : 'add';

  const { odometersOptions } = useOdometers();

  const initialValues = {
    name: powerFactor?.name || '',
    cosPhiBreakingPoint: powerFactor?.cosPhiBreakingPoint || 0.95,
    realPowerOdometerId: powerFactor?.realPowerOdometer?.id || 0,
    apparentPowerOdometersIds: powerFactor?.apparentPowerOdometers?.map(item => item.id) || [0],
  };

  const handleSubmit = async (values: PowerFactorFormOutputValues) => {
    onSubmit(
      values as PowerFactorFormOutputValues,
      powerFactor ? powerFactor.id : undefined
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('powerFactorSettingsPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />

            <Field<number>
              name="cosPhiBreakingPoint"
              render={(props) => (
                <Input
                  type="number"
                  label={t('powerFactorSettingsPage.cosPhiBreakingPoint')}
                  {...props}
                  required
                  data-cy-field="cosPhiBreakingPoint"
                />
              )}
            />

            <Field<number>
              name="realPowerOdometerId"
              render={(props) => (
                <Select
                  options={odometersOptions}
                  label={t('powerFactorSettingsPage.realPowerOdometer')}
                  {...props}
                  required
                  data-cy-field="realPowerOdometer"
                  clearable
                />
              )}
            />

            <FieldArray
              name="apparentPowerOdometersIds"
              render={({ push }) => (
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper', mb: 3 }}
                >
                  <Typography variant="body1" mb="24px" ml={2}>
                    {t('powerFactorSettingsPage.apparentPowerOdometers')}
                  </Typography>
                  {values.apparentPowerOdometersIds.map((item, index) => (
                    <Field<number>
                      name={`apparentPowerOdometersIds.${index}`}
                      render={(props) => (
                        <>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Select
                                  options={odometersOptions}
                                  {...props}
                                  data-cy-field="apparentPowerOdometer"
                                  clearable
                                  width={500}
                                />
                              }
                            />
                          </ListItem>
                          <Divider component="li" />
                        </>
                      )}
                    />
                  ))}
                  <ListItem sx={{ justifyContent: 'space-evenly' }}>
                    <Button
                      type="button"
                      onClick={() => {push(0)}}
                      variant="add"
                    ></Button>
                  </ListItem>
                </List>
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default PowerFactorForm;
