import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';

import { FieldProps } from '@/components/ui/form/types';
import Tooltip from '@/components/ui/tooltip/Tooltip';

import { FieldCommonProps } from './types';
import Wrapper from './Wrapper';

const Password: FC<FieldCommonProps & FieldProps<string>> = ({
  label,
  placeholder,
  help,
  onChange,
  error,
  required,
  value,
  ...rest
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper
      label={label}
      help={help}
      error={error}
      required={required}
      render={(ids) => (
        <OutlinedInput
          sx={{ backgroundColor: 'white' }}
          id={ids.current.input}
          aria-describedby={ids.current.helper}
          label={label}
          placeholder={placeholder}
          onChange={({ target: { value } }) => onChange(value)}
          error={!!error}
          required={required}
          value={value || ''}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={t(showPassword ? 'common.hide' : 'common.show')}>
                <IconButton
                  aria-label={
                    t('loginPage.toggle password visibility') ??
                    'toggle password visibility'
                  }
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          {...rest}
        />
      )}
    />
  );
};

export default Password;
