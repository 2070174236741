/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CenterDto } from '../models/CenterDto';
import type { CenterRequestDto } from '../models/CenterRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CenterService {
  /**
   * @returns any Centers list.
   * @throws ApiError
   */
  public static getCenterList({
    take,
    skip,
    deleted,
    name,
    branchOfficeId,
    workshopId,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    name?: string;
    branchOfficeId?: string;
    workshopId?: string;
    sortBy?: 'id' | 'name' | 'comment' | 'workshop' | 'branchOffice';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<CenterDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/center/',
      query: {
        deleted: deleted,
        name: name,
        branchOfficeId: branchOfficeId,
        workshopId: workshopId,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postCenterCreate({
    requestBody,
  }: {
    /**
     * Create new center
     */
    requestBody: CenterRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: CenterDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/center/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getCenterFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: CenterDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/center/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putCenterUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update center
     */
    requestBody: CenterRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: CenterDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/center/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Center not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteCenterDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/center/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Center not found`,
      },
    });
  }
}
