import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import type {ReadingDto} from '@/_generatedApi';
import { getTranslatedDatePart, prettyFormatNumber } from '@/utils/format';
import format from "date-fns/format";
import { prettyUnit } from "@/utils/formatString";

const OdometerGraphDetail: FC<{
  lastThreeMonthData: ReadingDto[];
  unit?: string
}> = ({ lastThreeMonthData, unit }) => {
  const { t, i18n } = useTranslation();

  function createData(month: string, value: number, consumption: number, maxValueDate?: string|null, maxValue?: number|null) {
    return { month, value, consumption, maxValueDate, maxValue };
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: 14,
    },
  }));

  const rows = lastThreeMonthData.map((monthData) =>
    createData(
      getTranslatedDatePart(
        new Date(monthData.fromDate),
        'month',
        i18n.language
      ),
      monthData.finalValue,
      monthData.consumption,
      monthData.maxValueDate && format(new Date(monthData.maxValueDate), 'dd.MM. HH:mm'),
      monthData.maxValue,
    )
  );

  return (
    <TableContainer sx={{ maxHeight: 440, maxWidth: 800 }} component={Paper}>
      <Table sx={{ width: '100%' }} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              {t('electricityReadingsPage.gauge.detail.month')}
            </StyledTableCell>
            <StyledTableCell align="right">
              {t('electricityReadingsPage.gauge.detail.value')} [{prettyUnit(unit)}]
            </StyledTableCell>
            <StyledTableCell align="right">
              {t('electricityReadingsPage.gauge.detail.consumption')} [{prettyUnit(unit)}]
            </StyledTableCell>
            <StyledTableCell align="right" colSpan={2}>
              {t('electricityReadingsPage.gauge.detail.maxValueDate')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.month}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.month}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {prettyFormatNumber(row.value, i18n.language)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {prettyFormatNumber(row.consumption, i18n.language)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {row.maxValueDate}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {row.maxValue && prettyFormatNumber(row.maxValue * 4, i18n.language)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OdometerGraphDetail;
