import {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {TFunction} from 'i18next';
import * as yup from 'yup';

import {WorkshopDto} from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import {WorkshopContext} from '@/pages/settings-workshops-page/SettingsWorkshopPage';

import Select from '../ui/fields/Select';
import Textarea from '../ui/fields/Textarea';
import {SettingsWorkshopPlan} from "@/components/settings-workshops/SettingsWorkshopPlan";
import {useFabricJSEditor} from "fabricjs-react";

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', {count: 4}) as string)
      .max(50, t('validation.maxChars', {count: 50}) as string)
      .required('validation.required'),
    branchOfficeId: yup.number().required('validation.required'),
    comment: yup
      .string()
      .nullable()
      .min(2, t('validation.minChars', {count: 2}) as string)
      .max(255, t('validation.maxChars', {count: 255}) as string),
    plan: yup.string(),
  });

export type SettingsWorkshopFormInputValues = {
  name: string | null;
  branchOfficeId: number | null;
  comment: string | null;
  plan: string | null;
};

export type SettingsWorkshopFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type SettingsWorkshopFormProps = {
  workshop: WorkshopDto | null;
  onSubmit: (values: SettingsWorkshopFormOutputValues, id?: number) => void;
  onCancel: () => void;
};

const SettingsWorkshopForm: FC<SettingsWorkshopFormProps> = ({
                                                               workshop,
                                                               onSubmit,
                                                               onCancel,
                                                             }) => {
  const {t} = useTranslation();
  const {branchOffices} = useContext(WorkshopContext);
  const dialogType = workshop ? 'update' : 'add';

  const initialValues = {
    name: workshop?.name || null,
    branchOfficeId: workshop?.branchOffice?.id || null,
    comment: workshop?.comment || null,
    plan: workshop?.plan || '[]'
  };

  const handleSubmit = async (values: SettingsWorkshopFormInputValues) => {
    values.plan = JSON.stringify(editor?.canvas?.toJSON(['data']));
    onSubmit(
      values as SettingsWorkshopFormOutputValues,
      workshop?.id ? workshop.id : undefined
    );
  };

  const {editor, selectedObjects, onReady} = useFabricJSEditor();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({isSubmitting, resetForm}) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('workshopsSettingsPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />
            <Field<string>
              name="comment"
              render={(props) => (
                <Textarea
                  label={t('workshopsSettingsPage.comment')}
                  {...props}
                  data-cy-field="comment"
                />
              )}
            />
            <Field<number>
              name="branchOfficeId"
              render={(props) => (
                <Select
                  options={branchOffices}
                  label={t('workshopsSettingsPage.branchOffice')}
                  {...props}
                  required
                  data-cy-field="branchOffice"
                  clearable
                />
              )}
            />
            <Field<string>
              name="plan"
              render={(props) => (
                <SettingsWorkshopPlan
                  editor={editor}
                  selectedObjects={selectedObjects || []}
                  onReady={onReady}
                  initialValue={initialValues.plan}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default SettingsWorkshopForm;
