import { useEffect, useState } from 'react';

import { CenterDto, CenterService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useCenters = () => {
  const [centers, setCenters] = useState<CenterDto[]>([]);
  const [centersOptions, setCentersOptions] = useState<Option[]>([]);

  const fetchData = async () => {
    const data = (
      await CenterService.getCenterList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as CenterDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setCenters(data as CenterDto[]);
    setCentersOptions(options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    centers,
    centersOptions,
  };
};
