import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';

import { FieldProps } from '@/components/ui/form/types';
import Tooltip from '@/components/ui/tooltip/Tooltip';

import { FieldCommonProps, Option } from './types';
import Wrapper from './Wrapper';

const IconComponent: FC = () => null;

const Select: FC<
  FieldCommonProps<{
    options: Option[];
    clearable?: boolean;
    multiple?: boolean;
    withoutHelperText?: boolean;
    width?: number;
    autoFocus?: boolean;
  }> &
    FieldProps<string | number>
> = ({
  label,
  help,
  options,
  clearable,
  onChange,
  error,
  required = false,
  withoutHelperText = false,
  multiple,
  width,
  autoFocus = false,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      label={label}
      help={help}
      error={error}
      required={required}
      withoutHelperText={withoutHelperText}
      render={(ids) => (
        <MuiSelect
          style={{ backgroundColor: 'white' }}
          sx={{
            ...(width && { width: `${width}px` }),
            '& .MuiSelect-select': {
              ...(!rest.value && {
                opacity: 0,
              }),
              ...(!!(clearable && rest.value) && {
                paddingRight: '0px !important',
              }),
            },
            '&.Mui-focused': {
              '& .MuiSelect-select': {
                opacity: 1,
              },
            },
          }}
          id={ids.current.input}
          labelId={ids.current.label}
          aria-describedby={ids.current.helper}
          label={label}
          onChange={({ target: { value } }) => onChange(value)}
          error={!!error}
          required={required}
          multiple={!!multiple}
          renderValue={(selected) =>
            multiple
              ? (selected as unknown as string[])
                  ?.map(
                    (value) =>
                      options.find((option) => option.value === value)?.label
                  )
                  .join(', ')
              : options.find((option) => option.value === selected)?.label
          }
          displayEmpty
          {...(!!(
            clearable &&
            rest.value
          ) && {
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('common.clear')}>
                  <IconButton
                    aria-label={t('common.clearSelect') ?? 'clear selection'}
                    onClick={() => onChange((multiple ? [] : '') as string)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            inputProps: { IconComponent },
          })}
          autoFocus={autoFocus}
          {...rest}
          value={rest.value || ''}
          MenuProps={{
            sx: {
              maxHeight: 300,
            },
          }}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value || 0} value={value}>
              <>
                {!multiple || (
                  <Checkbox
                    checked={
                      (rest.value as string).indexOf(value as string) > -1
                    }
                  />
                )}
                {label || value}
              </>
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    />
  );
};

export default Select;
