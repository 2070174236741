export function updateObjectInArray(
  array: Record<string, unknown>[],
  newValues: Record<string, unknown>
) {
  return array.map((item) => {
    if (item.id !== newValues.id) {
      return item;
    }

    return {
      ...item,
      ...newValues,
    };
  });
}

export const updateFilterData = <T>(
  filter: T,
  updatedData: { key: string; value: string | boolean | undefined }
) => {
  const obj = { ...filter };
  if (updatedData.value) {
    return { ...obj, [updatedData.key]: updatedData.value };
  } else {
    delete (obj as { [key: string]: string })[updatedData.key];
    return obj;
  }
};
