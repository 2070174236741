import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';

import Button from '../button/CButton';
import IconButton from '../button/CIconButton';
import Modal from '../modal/CModal';

const CConfirmDialog: FC<{
  callback: (setShowModal: Dispatch<SetStateAction<boolean>>) => void;
  title: string;
}> = ({ callback, title }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={showModal}
        width={600}
        onClose={() => setShowModal(false)}
        title={title}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setShowModal(false)} size="large">
            {t('common.cancel')}
          </Button>
          <Button
            variant="delete"
            onClick={() => callback(setShowModal)}
            size="large"
          />
        </Box>
      </Modal>

      <IconButton
        aria-label="delete"
        variant="destructive"
        onClick={() => setShowModal(true)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default CConfirmDialog;
