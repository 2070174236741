import { FC, useState } from 'react';
import { Fab, Typography } from '@mui/material';
import ElectricityTrendGraph from '@/components/graphs/ElectricityTrendGraph';
import { PeakGraphs } from '@/components/graphs/PeakGraphs';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { prettyFormatNumber } from "@/utils/format";
import { useTranslation } from 'react-i18next';
import { OdometerConsumptionMultipleDto } from '@/_generatedApi';
import { ElectricityDashboardsFilter } from '@/components/ui/filters/ElectricityDashboardFilters';
import { Medium } from "@/constants/mediums";
import { prettyUnit } from '@/utils/formatString';

type OdometerChartsProps = {
  odometerEntity: OdometerConsumptionMultipleDto;
  filter: ElectricityDashboardsFilter;
  forceShowMinuteData: boolean;
  forceShowTrendData: boolean;
  allowMinuteData: boolean;
  medium: Medium
};

const OdometerCharts: FC<OdometerChartsProps> = ({
  odometerEntity,
  filter,
  forceShowMinuteData,
  forceShowTrendData,
  allowMinuteData,
  medium
}) => {
  const [showMinuteData, setShowMinuteData] = useState(false);
  const [showTrendData, setShowTrendData] = useState(false);
  const [date, setDate] = useState(new Date());
  const { i18n } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <Typography variant="h2" sx={{ fontSize: '22px' }}>
        {odometerEntity.odometer.name}
      </Typography>

      <div
        style={{
          position: 'absolute',
          top: 0,
          right: '1%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>
          Σ &nbsp;
          {prettyFormatNumber(
            Math.round(
              odometerEntity.data.reduce((acc, value) => acc + value.value, 0) *
                100
            ) / 100,
            i18n.language
          )}
          &nbsp;
          {prettyUnit(odometerEntity.odometer.unit)}
        </span>
        {
          allowMinuteData &&
          <>
            <Fab
              size="small"
              color={
                showMinuteData || forceShowMinuteData ? 'secondary' : 'default'
              }
              onClick={() => setShowMinuteData(!showMinuteData)}
              sx={{ ml: 4, width: 24, height: 24, minHeight: 24 }}
            >
              <BarChartIcon sx={{ fontSize: 14 }} />
            </Fab>
            <Fab
              size="small"
              color={showTrendData || forceShowTrendData ? 'secondary' : 'default'}
              onClick={() => setShowTrendData(!showTrendData)}
              sx={{ ml: 1, width: 24, height: 24, minHeight: 24 }}
            >
              <ShowChartIcon sx={{ fontSize: 14 }} />
            </Fab>
          </>
        }

      </div>

      <ElectricityTrendGraph
        data={odometerEntity}
        unit={filter.consumptionUnit}
        setDate={(date) => {
          setDate(date);
          setShowMinuteData(true);
        }}
        medium={medium}
      />
      {allowMinuteData && (showMinuteData ||
        forceShowMinuteData ||
        showTrendData ||
        forceShowTrendData) && (
        <PeakGraphs
          data={odometerEntity}
          showMinuteData={showMinuteData || forceShowMinuteData}
          showTrendData={showTrendData || forceShowTrendData}
          date={date}
          setDate={setDate}
        />
      )}
    </div>
  );
};

export default OdometerCharts;
