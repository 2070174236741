import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBranchOffices } from '@/hooks/data/use-branch-offices';
import { useCenters } from '@/hooks/data/use-centers';
import { useMediums } from '@/hooks/data/use-mediums';
import { useOdometerTypes } from '@/hooks/data/use-odometer-types';
import { useWorkshops } from '@/hooks/data/use-workshops';

import FilterItemWrapper from '../fields/FilterItemWrapper';
import Input from '../fields/Input';
import Select from '../fields/Select';

export type OdometerFilter = {
  name?: string;
  externalId?: string;
  mediumId?: string;
  typeId?: string;
  branchOfficeId?: string;
  workshopId?: string;
  centerId?: string;
  sortBy?:
    | 'id'
    | 'name'
    | 'comment'
    | 'technicalName'
    | 'idByUser'
    | 'inputNumber'
    | 'workshop'
    | 'center'
    | 'type'
    | 'medium'
    | 'branchOffice'
    | 'installationDate';
  descending?: boolean;
};

export type OdometersFiltersProps = {
  updateFilter: (key: string, value: string) => void;
  filter: OdometerFilter;
};

const OdometersFilters: FC<OdometersFiltersProps> = ({
  updateFilter,
  filter,
}) => {
  const { t } = useTranslation();
  const { branchOfficesOptions } = useBranchOffices();
  const { workshopsOptions } = useWorkshops();
  const { centersOptions } = useCenters();
  const { mediumsOptions } = useMediums();
  const { odometerTypesOptions } = useOdometerTypes();

  const filters = () => {
    return [
      <Input
        key="name"
        name="name"
        onChange={(value) => updateFilter('name', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.name')}
        value={filter.name || ''}
      />,

      <Input
        key="externalId"
        name="externalId"
        onChange={(value) => updateFilter('externalId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.externalId')}
        value={filter.externalId || ''}
      />,

      <Select
        key="medium"
        name="medium"
        options={mediumsOptions}
        onChange={(value) => updateFilter('mediumId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.medium')}
        value={filter.mediumId || ''}
        clearable
      />,

      <Select
        key="type"
        name="type"
        options={odometerTypesOptions}
        onChange={(value) => updateFilter('typeId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.type')}
        value={filter.typeId || ''}
        clearable
      />,

      <Select
        key="branchOffice"
        name="branchOffice"
        options={branchOfficesOptions}
        onChange={(value) => updateFilter('branchOfficeId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.branchOffice')}
        value={filter.branchOfficeId || ''}
        clearable
      />,

      <Select
        key="workshop"
        name="workshop"
        options={workshopsOptions}
        onChange={(value) => updateFilter('workshopId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.workshop')}
        value={filter.workshopId || ''}
        clearable
      />,

      <Select
        key="center"
        name="center"
        options={centersOptions}
        onChange={(value) => updateFilter('centerId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('odometersSettingsPage.center')}
        value={filter.centerId || ''}
        clearable
      />,
    ];
  };

  return (
    <>
      {filters().map((filter) => (
        <FilterItemWrapper key={filter.key}>{filter}</FilterItemWrapper>
      ))}
    </>
  );
};

export default OdometersFilters;
