export const COLORS = {
  white: {
    main: '#ffffff',
  },
  gray: {
    light: '#f1f6fa',
    main: '#c4c4c4',
    dark: '#666666',
  },
  purple: {
    light: '#373862',
    main: '#23244f',
    dark: '#0c0c24',
  },
  turquoise: {
    light: '#67e3be',
    main: '#4bcfa7',
  },
  red: {
    light: '#FFAEB1',
    main: '#EE6666',
  },
  blue: {
    light: '#9AA9CF',
    main: '#5470C6',
  },
  yellow: {
    light: '#FFDAA4',
    main: '#FFD700',
  },
  error: {
    main: '#d42e30',
  },
  backdrop: {
    main: '#0c0c24b3',
  },
} as const;

export const DATA_REFRESH_INTERVAL = 30000;
