import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
  OdometerConsumptionBaseDataDto,
  OdometerConsumptionMinuteDto,
  OdometerConsumptionMultipleDto,
  OdometerService
} from "@/_generatedApi";
import ElectricityPeakGraph from "@/components/graphs/ElectricityPeakGraph";
import ElectricityQuaterPeakGraph from "@/components/graphs/ElectricityQuaterPeakGraph";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CDatePicker from '../ui/common/date-picker/CDatePicker';
import Button from '@mui/material/Button';
import {Typography} from "@mui/material";
import {add, addDays, format, startOfDay, subDays} from "date-fns";

export const PeakGraphs: FC<{
  data: OdometerConsumptionMultipleDto;
  showMinuteData: boolean;
  showTrendData: boolean;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>
}> = ({data, showMinuteData, showTrendData, date, setDate}) => {
  const [value, setValue] = useState<OdometerConsumptionMinuteDto>()
  const [zoom, setZoom] = useState({start: 0, end: 100});
  const todayMorning = startOfDay(new Date());

  useEffect(() => {
    const result = OdometerService.getOdometerMinuteConsumption({
      date: format(date, 'yyyy-MM-dd'),
      odometerId: data.odometer.id
    });
    result.then((item) => {
      if (item?.data) {
        // item.data.data = item.data.data.map(row => ({...row, value: Math.random()}));
        // item.data.prediction = item.data.prediction.map(row => ({...row, value: Math.random()}));
        setValue(item.data);
      }
    })
  }, [date, data]);

  const peakData = useMemo(() => {
    if (!value) {
      return [];
    }
    const chunks: [Date, number][][] = [];
    const chunkSize = 15;
    for (let i = 0; i < value.data.length; i += chunkSize) {
      let chunkData = value.data.slice(i, i + chunkSize);
      let date = new Date(chunkData[chunkData.length - 1].date)
      date = add(date, {minutes: 1});
      chunkData.push({
        date: format(date, 'yyyy-MM-dd') + 'T' + format(date, 'HH:mm:ssxxx'),
        value: 0
      })
      chunkData = chunkData.map((item, index) => ({
        ...item,
        value: chunkData.slice(0, index).map(item => item.value).reduce((a, b) => a + b, 0)
      }));

      const chunk = chunkData.map(item => [new Date(item.date), item.value]) as [Date, number][];
      chunks.push(chunk);
    }

    return chunks
  }, [value]);

  const predictionData = useMemo(() => {
    if (!value || value.prediction.length == 0) {
      return [];
    }

    const lastPeak = peakData[peakData.length - 1];
    const lastPeakValue = lastPeak[lastPeak.length - 1][1];
    const prediction = value.prediction.map((item, index) => ({
      date: add(new Date(item.date), {minutes: 1}),
      value: value.prediction.slice(0, index + 1).map(item => item.value).reduce((a, b) => a + b, lastPeakValue)
    }));

    prediction.unshift({
      date: lastPeak[lastPeak.length - 1][0],
      value: lastPeakValue
    });

    return prediction.map(item => [item.date, item.value]) as [Date, number][];
  }, [value])

  return <>
    {/*<Typography variant="h3" sx={{fontSize: '16px'}}>*/}
    {/*  1/4 hodinová maxima*/}
    {/*</Typography>*/}
    <div style={{display: 'flex', alignItems: 'center', marginBottom: '-20px'}}>
      <span style={{paddingBottom: '25px', paddingRight: '24px'}}>
        <Button variant="contained" startIcon={<ArrowBackIosNewIcon/>}
                onClick={() => setDate(oldDate => subDays(oldDate, 1))}>
          Předchozí
        </Button>
      </span>
      <CDatePicker name="datum" onChange={(date) => setDate(date as Date)} value={date} error="" onBlur={() => {
      }} />
      <span style={{paddingBottom: '25px', paddingLeft: '24px'}}>
        <Button variant="contained" endIcon={<ArrowForwardIosIcon/>}
                onClick={() => setDate(oldDate => addDays(oldDate, 1))}
                disabled={date >= todayMorning}>
          Další
        </Button>
      </span>
    </div>

    {
      showMinuteData && <ElectricityPeakGraph data={data} peakData={peakData.map(item => item[item.length - 1])} zoom={zoom} setZoom={setZoom}/>
    }
    {
      showTrendData && <ElectricityQuaterPeakGraph data={data} peakData={peakData} predictionData={predictionData} zoom={zoom} setZoom={setZoom}/>
    }
  </>;
}
