/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupDto } from '../models/GroupDto';
import type { GroupRequestDto } from '../models/GroupRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupService {
  /**
   * @returns any Groups list.
   * @throws ApiError
   */
  public static getGroupList({
    take,
    skip,
    deleted,
    sortBy,
    descending,
    name,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    sortBy?: 'id' | 'name' | 'comment';
    descending?: boolean;
    name?: string;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<GroupDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/group/',
      query: {
        deleted: deleted,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
        name: name,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postGroupCreate({
    requestBody,
  }: {
    /**
     * Create new group
     */
    requestBody: GroupRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: GroupDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/group/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getGroupFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: GroupDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/group/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Group not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putGroupUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update group
     */
    requestBody: GroupRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: GroupDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/group/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Group not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteGroupDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/group/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Group not found`,
      },
    });
  }
}
