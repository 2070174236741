import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarOrigin, useSnackbar, VariantType } from 'notistack';

import { useIsMobile } from '@/hooks/use-is-mobile';

export const useShowToast = (): {
  showToast: (variant: VariantType, message: string) => void;
  showGenericErrorToast: () => void;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const anchorOrigin: SnackbarOrigin = isMobile
    ? { vertical: 'top', horizontal: 'center' }
    : { vertical: 'bottom', horizontal: 'right' };

  const showToast = useCallback((variant: VariantType, message: string) => {
    enqueueSnackbar(message, { variant, anchorOrigin });
  }, []);

  const showGenericErrorToast = useCallback(() => {
    enqueueSnackbar(t('error.generic'), { variant: 'error', anchorOrigin });
  }, []);

  return { showToast, showGenericErrorToast };
};
