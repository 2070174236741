import { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Modal as MuiModal, Typography } from '@mui/material';
import { v4 } from 'uuid';

import Show from '@/components/ui/control-flow/Show';
import Tooltip from '@/components/ui/tooltip/Tooltip';
import { COLORS } from '@/utils/variables';

const CModal: FC<
  PropsWithChildren<{
    open: boolean;
    onClose?: () => void;
    width?: number;
    title?: ReactNode;
    description?: ReactNode;
    type?: 'edit' | 'create';
  }>
> = ({ children, open, onClose, width = 657, title, description, type }) => {
  const ids = useRef({
    title: v4(),
    description: v4(),
  });

  const { t } = useTranslation();

  return (
    <MuiModal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: COLORS.backdrop.main,
      }}
      open={open}
      onClose={onClose}
      aria-labelledby={ids.current.title}
      aria-describedby={ids.current.description}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: width,
          maxHeight: '100%',
          backgroundColor: COLORS.gray.light,
          boxShadow: '24px',
          borderRadius: '4px',
          overflow: 'auto',
        }}
      >
        <Show when={!!(title || description || onClose)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: '12px',
            }}
          >
            <Show when={!!(title || description)}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  padding: '24px 24px 0',
                }}
              >
                <Show when={!!title}>
                  <Typography
                    id={ids.current.title}
                    variant="h5"
                    component="h2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '7px',
                    }}
                  >
                    <Show when={type === 'create'}>
                      <AddIcon />
                    </Show>
                    <Show when={type === 'edit'}>
                      <EditIcon />
                    </Show>
                    <span>{title}</span>
                  </Typography>
                </Show>
                <Show when={!!description}>
                  <Typography
                    sx={{
                      fontSize: '18px',
                    }}
                    id={ids.current.description}
                  >
                    {description}
                  </Typography>
                </Show>
              </Box>
            </Show>
            <span />
            <Tooltip title={t('common.close')}>
              <IconButton
                sx={{
                  margin: '4px 4px 0 0',
                }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Show>
        <Box
          sx={{
            padding: '24px',
          }}
        >
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};

export default CModal;
