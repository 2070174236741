import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useLocation } from 'wouter';

import Button from '@/components/ui/common/button/CButton';

const NotFoundPage: FC = () => {
  const [, setLocation] = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1" mb={2}>
        {t('error.pageNotFound', {})}...
      </Typography>
      <Button onClick={() => setLocation('/')}>
        {t('navigation.backToHomepage')}
      </Button>
    </>
  );
};

export default NotFoundPage;
