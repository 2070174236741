import { FC, PropsWithChildren, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { DefaultTFuncReturn } from 'i18next';

import WithLink from '@/components/routing/WithLink';
import { TextVariant } from '@/types/core-types';

export type OverviewCardProps = PropsWithChildren & {
  title: {
    text: string;
    align?: 'left' | 'center' | 'right';
    variant?: TextVariant;
  };
  href?: string;
  description?: DefaultTFuncReturn;
  expandable?: boolean;
  titleMaxLines?: number
};

const OverviewCard: FC<OverviewCardProps> = ({
  children,
  title,
  description,
  href,
  expandable = false,
  titleMaxLines,
}) => {
  const [showContent, setShowContent] = useState(false);

  const titleExtraStyle = titleMaxLines ? {height: `${1.334 * titleMaxLines}em`, overflow: 'hidden'} : {};

  return (
    <WithLink href={href}>
      <Card
        sx={{
          padding: '16px',
          color: 'primary.main',
          flex: 1,
        }}
      >
        <CardActions disableSpacing sx={{ padding: 0 }}>
          <Typography
            variant={title.variant || 'h3'}
            align={title.align || 'left'}
            mr="auto"
            {...titleExtraStyle}
          >
            {title.text}
          </Typography>
          {expandable && (
            <IconButton
              onClick={() => setShowContent((showContent) => !showContent)}
              aria-expanded={showContent}
              aria-label="show more"
            >
              <GridExpandMoreIcon />
            </IconButton>
          )}
        </CardActions>
        {description && (
          <Typography variant="subtitle1" sx={{ fontSize: '11px' }}>
            {description}
          </Typography>
        )}
        {expandable ? (
          <Collapse in={showContent} timeout="auto" unmountOnExit>
            <Box>{children}</Box>
          </Collapse>
        ) : (
          <Box>{children}</Box>
        )}
      </Card>
    </WithLink>
  );
};

export default OverviewCard;
