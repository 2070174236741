import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useRoute } from 'wouter';

import WithLink from '@/components/routing/WithLink';
import { MenuContext } from '@/contexts/layout/MenuContext';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { Menu } from '@/types/core-types';

const Item: FC<
  {
    path: string;
  } & Menu[string]
> = ({ path, name, isMenuHidden }) => {
  const [isMatching] = useRoute(`${path}/:rest*`);
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  if (
    isMenuHidden === (isMobile ? 'mobile' : 'desktop') ||
    isMenuHidden === true
  ) {
    return null;
  }

  return (
    <WithLink href={path}>
      <Button
        sx={{
          color: isMatching ? 'secondary.main' : 'common.white',
        }}
        data-cy="topMenuItem"
      >
        {t(`navigation.${name}`)}
      </Button>
    </WithLink>
  );
};

const TopNav: FC = () => {
  const isMobile = useIsMobile();

  const { menu } = useContext(MenuContext);

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
      }}
    >
      {Object.entries(menu ?? []).map(([path, rest]) => (
        <Item key={path} path={path} {...rest} />
      ))}
    </Box>
  );
};

export default TopNav;
