import { ChangeEvent, FC } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

import { FieldProps } from '@/components/ui/form/types';

import { FieldCommonProps } from './types';
import Wrapper from './Wrapper';

const Checkbox: FC<
  FieldCommonProps<{
    multiline?: boolean;
  }> &
    FieldProps<boolean>
> = ({ label, help, onChange, error, required = false, value, ...rest }) => {
  return (
    <Wrapper
      help={help}
      error={error}
      required={required}
      render={(ids) => (
        <FormControlLabel
          control={
            <MuiCheckbox
              id={ids.current.input}
              checked={value || false}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.checked);
              }}
              sx={{
                color: '#4BCFA7',
                '&.Mui-checked': {
                  color: '#4BCFA7',
                },
              }}
              {...rest}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default Checkbox;
