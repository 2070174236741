/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: UserRequestDto.role;
  registrationPassword: string;
};

export namespace UserRequestDto {
  export enum role {
    ROLE_USER = 'ROLE_USER',
    ROLE_MANAGER = 'ROLE_MANAGER',
    ROLE_ADMIN = 'ROLE_ADMIN',
  }
}
