import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BranchOfficeContext } from '@/pages/settings-branch-office-page/SettingsBranchOfficePage';

import FilterItemWrapper from '../fields/FilterItemWrapper';
import Input from '../fields/Input';
import Select from '../fields/Select';

export type BranchOfficeFilter = {
  name?: string;
  superiorBranchOfficeId?: string;
  city?: string;
  sortBy?:
    | 'id'
    | 'name'
    | 'city'
    | 'comment'
    | 'superiorBranchOffice'
    | undefined;
  descending?: boolean;
};

export type BranchOfficesFiltersProps = {
  updateFilter: (key: string, value: string | undefined) => void;
  filter: BranchOfficeFilter;
};

const BranchOfficesFilters: FC<BranchOfficesFiltersProps> = ({
  updateFilter,
  filter,
}) => {
  const { t } = useTranslation();

  const branchOffices = useContext(BranchOfficeContext);

  const filters = () => {
    return [
      <Input
        key="name"
        name="name"
        onChange={(value) => updateFilter('name', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('branchOfficesSettingsPage.name')}
        value={filter.name || ''}
      />,

      <Input
        key="city"
        name="city"
        onChange={(value) => updateFilter('city', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('branchOfficesSettingsPage.city')}
        value={filter.city || ''}
      />,

      <Select
        key="superiorBranchOffice"
        name="superiorBranchOffice"
        options={branchOffices || []}
        onChange={(value) =>
          updateFilter(
            'superiorBranchOfficeId',
            value ? (value as string) : undefined
          )
        }
        onBlur={() => {}}
        error=""
        required={false}
        label={t('branchOfficesSettingsPage.superiorBranchOffice')}
        value={filter.superiorBranchOfficeId || ''}
        width={220}
        clearable
      />,
    ];
  };

  return (
    <>
      {filters().map((filter) => (
        <FilterItemWrapper key={filter.key}>{filter}</FilterItemWrapper>
      ))}
    </>
  );
};

export default BranchOfficesFilters;
