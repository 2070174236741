import { useEffect, useState } from 'react';

import { OdometerTypeDto, OdometerTypeService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useOdometerTypes = () => {
  const [odometerTypes, setOdometerTypes] = useState<OdometerTypeDto[]>([]);
  const [odometerTypesOptions, setOdometerTypesOptions] = useState<Option[]>(
    []
  );

  const fetchData = async () => {
    const data = (
      await OdometerTypeService.getOdometerTypeList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as OdometerTypeDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setOdometerTypes(data as OdometerTypeDto[]);
    setOdometerTypesOptions(options as Option[]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    odometerTypes,
    odometerTypesOptions,
  };
};
