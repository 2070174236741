/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadoutDto } from '../models/ReadoutDto';
import type { ReadoutRequestDto } from '../models/ReadoutRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReadoutService {
  /**
   * @returns any Readout list.
   * @throws ApiError
   */
  public static getReadoutList({
    odometerId,
  }: {
    odometerId?: string;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<ReadoutDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/readout/',
      query: {
        odometerId: odometerId,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postReadoutCreate({
    requestBody,
  }: {
    /**
     * Create new readout
     */
    requestBody: ReadoutRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: ReadoutDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/readout/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
