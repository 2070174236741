import {FC, useCallback, useEffect, useState} from "react";
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {OdometerConsumptionMultipleDto, OdometerService} from "@/_generatedApi";

interface OdometerProps {
  id: number;
  onClick: () => void
}

export const Odometer: FC<OdometerProps> = ({id, onClick}) => {
  const [data, setData] = useState<OdometerConsumptionMultipleDto>();

  const fetchData = useCallback(async () => {
    const response = await OdometerService.getOdometerConsumption({
      consumptionStartDate: '2022-01-01 00:00:00',
      consumptionEndDate: '2022-01-01 00:00:00',
      consumptionUnit: '1 minute',
      odometerId: id,
    });

    if (response.data && response.data.length > 0) {
      setData(response.data[0]);
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [id]);

  return (
    <Card sx={{width: 200}}>
      <CardContent>
        <Typography gutterBottom variant="h6">
          {data?.odometer.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data?.totalConsumption} {data?.odometer.unit}
        </Typography>
      </CardContent>
      {/*<CardActions>*/}
      {/*  <Button onClick={onClick} size="small" color="primary">Detail</Button>*/}
      {/*</CardActions>*/}
    </Card>
  )
}
