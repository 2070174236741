import { createContext, FC, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import useLocalStorage from 'use-local-storage';
import { useLocation } from 'wouter';

import { useIsFirstRender } from '@/hooks/use-is-first-render';
import { useIsMobile } from '@/hooks/use-is-mobile';

import { DashboardLayoutContextType } from './types';

export const DashboardLayoutContext = createContext<DashboardLayoutContextType>(
  {
    DRAWER_WIDTH: 300,
    open: [true, noop],
  }
);

export const DashboardLayoutProvider: FC<
  PropsWithChildren<{
    DRAWER_WIDTH?: DashboardLayoutContextType['DRAWER_WIDTH'];
  }>
> = ({ children, DRAWER_WIDTH = 240 }) => {
  const [location] = useLocation();
  const { i18n } = useTranslation();

  const isFirstRender = useIsFirstRender();
  const isMobile = useIsMobile();

  /**
   * Persist the drawer state in local storage. If drawer has been left open and
   * app is in mobile version, close the drawer on first render.
   */
  const [open, setOpen] = useLocalStorage<boolean>(
    'dashboardLayoutDrawer',
    !isMobile,
    {
      parser: (value) => {
        const parsedValue = JSON.parse(value) as boolean;

        if (isMobile && isFirstRender) {
          return false;
        }

        return parsedValue;
      },
    }
  );

  /**
   * Close the drawer when page transitions to mobile version or when page is in
   * mobile version and language or location changes
   */
  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [i18n.language, isMobile, location, setOpen]);

  return (
    <DashboardLayoutContext.Provider
      value={{
        DRAWER_WIDTH,
        open: [open, setOpen],
      }}
    >
      {children}
    </DashboardLayoutContext.Provider>
  );
};
