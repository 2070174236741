import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Alert, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridFooterContainer,
  GridSortModel,
} from '@mui/x-data-grid';
import { DefaultTFuncReturn } from 'i18next';

import Button from '@/components/ui/common/button/CButton';
import CIconButton from '@/components/ui/common/button/CIconButton';
import { ITEMS_PER_PAGE_OPTIONS } from '@/constants/pagination';
import { ReactComponent as EditIcon } from '@/img/edit.svg';
import { CTableValueSetterParams } from '@/types/core-types';
import formatDate, { dateFormatTypes } from '@/utils/format';
import { prettyFormatNumber } from '@/utils/format';
import { isNumericString } from '@/utils/validation';

import CConfirmDialog from '../confirm-dialog/CConfirmDialog';

import Pagination from './pagination/Pagination';
import CDataGridPageSizeSelect from './CDataGridPageSizeSelect';

export type ColDef<T> = {
  key: string;
  nested: boolean;
  isDate: boolean;
  sortable: boolean;
  renderCell?: ({ row }: { row: T }) => JSX.Element | DefaultTFuncReturn;
  valueGetter?: ({ row }: CTableValueSetterParams) => string;
};

const StyledDataGrid = styled(DataGrid)(() => {
  const theme = useTheme();
  return {
    [`& .${gridClasses.row} .${gridClasses.cell}:first-of-type, .${gridClasses.columnHeader}:first-of-type`]:
      {
        paddingLeft: 40,
      },
    [`& .${gridClasses.row} .${gridClasses.cell}:last-child, .${gridClasses.columnHeader}:last-child`]:
      {
        paddingRight: 40,
      },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.background.default,
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: 'white',
    },
    [`& .${gridClasses.cell}`]: {
      border: 'none',
    },
  };
});

const FooterSlot: FC<{
  pageSize: number;
  onChangeRowsPerPage: (rowPerPage: number) => void;
}> = ({ pageSize, onChangeRowsPerPage }) => {
  const { t } = useTranslation();

  return (
    <GridFooterContainer>
      <Box
        ml="auto"
        mr="20px"
        my="20px"
        display="flex"
        alignItems="center"
        gap="8px"
      >
        <span>{t('common.rowsPerPage')}</span>
        <CDataGridPageSizeSelect
          name="pageSize"
          options={ITEMS_PER_PAGE_OPTIONS}
          onChange={onChangeRowsPerPage}
          value={pageSize}
        />
      </Box>
    </GridFooterContainer>
  );
};

const checkDateValidity = (stringDate: string): boolean => {
  const date = new Date(stringDate);
  return date instanceof Date && !isNaN(date as unknown as number);
};

const CDataGrid: FC<{
  onAdd?: () => void;
  onDelete?: (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => void;
  onEdit?: (values: Record<string, unknown>) => void;
  onDetail?: (id: number) => void;
  data: Record<string, unknown>[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  colDef: ColDef<any>[];
  translationKey: string;
  translationTitleKey?: string;
  filters?: JSX.Element;
  changeRowsPerPage?: (rowsPerPage: number) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onSort?: (sortModel: { sortBy?: any; descending?: boolean }) => void;
  pagination?: {
    onPageChange: (page: number) => void;
    totalCount: number;
    currentPage: number;
    pageSize: number;
  };
}> = ({
  onAdd,
  onDelete,
  onEdit,
  onDetail,
  data,
  colDef,
  translationKey,
  translationTitleKey,
  pagination,
  filters,
  changeRowsPerPage,
  onSort,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const columns = colDef.map((column) => {
    return {
      field: column.key,
      headerName: t(`${translationKey}.${column.key}`) || '',
      sortable: column.sortable,

      valueGetter:
        column.valueGetter ||
        (({ row }: CTableValueSetterParams) => {
          if (column.nested) {
            return row[column.key]?.name;
          } else if (column.isDate && row[column.key]) {
            return checkDateValidity(row[column.key])
              ? formatDate(
                  new Date(row[column.key]),
                  dateFormatTypes.fullDate,
                  i18n.language
                )
              : t('validation.invalidDate');
          }
          return row[column.key];
        }),
      renderCell: column.renderCell,
    };
  });

  const detailColumn: GridColDef[] = onDetail
    ? [
        {
          field: 'actions',
          headerName: t('common.detail') || '',
          headerAlign: 'center',
          align: 'center',

          renderCell: (cellValues) => {
            return (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                {onDetail && (
                  <CIconButton onClick={() => onDetail(cellValues.row.id)}>
                    <ContentPasteSearchIcon />
                  </CIconButton>
                )}
              </Box>
            );
          },
        },
      ]
    : [];

  const showActionColumn = onEdit || onDelete;

  const actionColumn: GridColDef[] = showActionColumn
    ? [
        {
          field: 'actions',
          headerName: t('common.actions') || '',
          headerAlign: 'right',
          align: 'right',

          renderCell: (cellValues) => {
            return (
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {onEdit && (
                  <div>
                    <CIconButton onClick={() => onEdit(cellValues.row)}>
                      <EditIcon />
                    </CIconButton>
                  </div>
                )}
                {onDelete && (
                  <CConfirmDialog
                    callback={(setShowModal) =>
                      onDelete(cellValues.row.id, setShowModal)
                    }
                    title={
                      cellValues.row.name
                        ? t('common.areYouSureToDeleteItem', {
                            item: cellValues.row.name,
                          })
                        : t('common.areYouSureToDeleteItemCommon')
                    }
                  />
                )}
              </Box>
            );
          },
        },
      ]
    : [];

  const dataGridColumns = detailColumn.concat(columns).concat(actionColumn);

  const handleSortingModelChange = (model: GridSortModel) => {
    if (!onSort) return;
    if (model.length === 0) {
      onSort({});
    } else {
      const modelItem = model[0];
      onSort({
        sortBy: modelItem.field,
        descending: modelItem.sort === 'desc',
      });
    }
  };

  const prettyFormatDataGridRows = (gridData: Record<string, unknown>[]) => {
    return gridData.map((obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]:
            typeof value === 'number' || isNumericString(value)
              ? prettyFormatNumber(Number(value), i18n.language)
              : value,
        };
      }, {});
    });
  };

  return (
    <Box width="100%" pb={4}>
      <Box
        sx={{
          display: 'flex',
          justifyItems: 'middle',
          justifyContent: 'space-between',
        }}
        mb={2}
      >
        <Typography variant="h1">{t(`${translationKey}.${translationTitleKey || 'title'}`)}</Typography>
        {onAdd && (
          <Button type="button" onClick={() => onAdd()} variant="add" />
        )}
      </Box>

      {filters && (
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {filters}
        </Box>
      )}
      {data.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 260px)',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <StyledDataGrid
              disableColumnMenu
              autoHeight
              sortingMode="server"
              onSortModelChange={handleSortingModelChange}
              disableColumnFilter
              disableRowSelectionOnClick
              getRowClassName={(params: {
                indexRelativeToCurrentPage: number;
              }) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  fontSize: 14,
                  position: 'sticky',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '.MuiDataGrid-sortIcon': {
                  fill: 'white',
                },
              }}
              rows={prettyFormatDataGridRows(data)}
              slots={{
                footer: () => {
                  return pagination && pagination.currentPage === 1 ? (
                    <FooterSlot
                      pageSize={pagination.pageSize}
                      onChangeRowsPerPage={(rowsPerPage: number) => {
                        if (pagination && changeRowsPerPage) {
                          changeRowsPerPage(rowsPerPage);
                        }
                      }}
                    />
                  ) : null;
                },
              }}
              hideFooterPagination
              filterMode="server"
              columns={dataGridColumns.map((column) => ({
                flex: 1,
                minWidth: 200,
                sortable: false,
                headerAlign: 'left',
                align: 'left',
                hideable: false,
                ...column,
              }))}
            />
          </Box>

          {pagination && (
            <Box mt={2} sx={{ flexShrink: 0 }}>
              <Pagination {...pagination} />
            </Box>
          )}
        </Box>
      )}

      {data.length === 0 && (
        <Alert severity="warning">{t('common.noDataFound')}</Alert>
      )}
    </Box>
  );
};

export default CDataGrid;
