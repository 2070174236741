import { FC, PropsWithChildren, useContext } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DashboardLayoutContext } from '@/contexts/layout/DashboardLayoutContext';
import { useIsMobile } from '@/hooks/use-is-mobile';

const Main: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  const isMobile = useIsMobile();

  const {
    DRAWER_WIDTH,
    open: [open],
  } = useContext(DashboardLayoutContext);

  return (
    <Box
      component="main"
      sx={{
        height: '100vh',
        padding: theme.spacing(3),
        ...(!isMobile && {
          marginLeft: `calc(${theme.spacing(isMobile ? 7 : 8)} + 1px)`,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            marginLeft: `${DRAWER_WIDTH}px`,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }),
      }}
    >
      <Box
        sx={{
          ...theme.mixins.toolbar,
        }}
      />
      {children}
    </Box>
  );
};

export default Main;
