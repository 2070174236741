import { useEffect, useState } from 'react';

import { MediumDto, MediumService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useMediums = () => {
  const [mediums, setMediums] = useState<MediumDto[]>([]);
  const [mediumsOptions, setMediumsOptions] = useState<Option[]>([]);

  const fetchData = async () => {
    const data = (
      await MediumService.getMediumList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as MediumDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setMediums(data as MediumDto[]);
    setMediumsOptions(options as Option[]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    mediums,
    mediumsOptions,
  };
};
