import { FC, useMemo, useRef } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { v4 } from 'uuid';

import { DateView } from '@/types/core-types';

import { FieldCommonProps } from '../../fields/types';
import { FieldProps } from '../../form/types';

const useIds = () => {
  return useRef({
    input: v4(),
    label: v4(),
    helper: v4(),
  });
};

const CDatePicker: FC<
  FieldCommonProps<{
    views?: DateView;
    minDate?: Date;
    withoutHelperText?: boolean;
    withTime?: boolean;
  }> &
    FieldProps<Date | null>
> = ({
  label,
  help,
  onChange,
  error,
  required = false,
  value,
  views,
  onBlur,
  minDate,
  withoutHelperText = false,
  withTime = false,
}) => {
  const ids = useIds();

  const dateValue = useMemo(() => {
    if (minDate && value && minDate > value) {
      return minDate;
    }

    return value;
  }, [minDate, value]);

  const Component = withTime ? DateTimePicker : DatePicker;

  return (
    <FormControl
      sx={{
        '& .MuiFormLabel-root': {
          maxWidth: 'calc(100% - 40px)',
        },
        ...(!!error && {
          '&, & svg, & .MuiFormLabel-root, & .MuiFormHelperText-root': {
            color: 'error.main',
          },
        }),
      }}
    >
      <Component
        sx={{ backgroundColor: 'white' }}
        label={label}
        views={views}
        value={dateValue || null}
        onChange={(value) => {
          onChange(value);
        }}
        minDate={minDate}
        slotProps={{
          textField: {
            error: !!error,
            id: ids.current.input,
            required,
            label,
            onBlur,
            placeholder: '',
          },
          actionBar: {
            actions: ['clear'],
          },
          popper: {
            placement: 'bottom-end',
          },
        }}
      />

      {!withoutHelperText && (
        <Box
          sx={{
            height: '25px',
          }}
        >
          <FormHelperText
            sx={{ marginTop: '0px', fontSize: '12px' }}
            id={ids.current.helper}
          >
            {error || help}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default CDatePicker;
