import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlarmDto,
  AlarmRequestDto,
  AlarmService,
  OdometerDto,
  OdometerService,
} from '@/_generatedApi';
import ElectricityAlarmForm, {
  ElectricityAlarmFormOutputValues,
} from '@/components/electricity-alarms/ElectricityAlarmForm';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import CModal from '@/components/ui/common/modal/CModal';
import { Option } from '@/components/ui/fields/types';
import ElectricityAlarmsFilters, {
  ElectricityAlarmFilter,
} from '@/components/ui/filters/ElectricityAlarmsFilters';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { CTableValueSetterParams } from '@/types/core-types';
import { updateFilterData, updateObjectInArray } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';
import { nullFieldsToUndefined } from '@/utils/validation';

export const ElectricityAlarmsContext = createContext<Option[] | null>(null);

const ElectricityAlarmsPage: FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [alarms, setAlarms] = useState<AlarmDto[]>([]);
  const [odometers, setOdometers] = useState<Option[]>([]);
  const [countOfAlarms, setCountOfAlarms] = useState(0);
  const { showGenericErrorToast, showToast } = useShowToast();

  const [chosenAlarms, setChosenAlarms] = useState<AlarmDto | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [filter, setFilter] = useState<ElectricityAlarmFilter>({});

  const getExternalNotificationValue = (row: AlarmDto) => {
    const notificationValue = [row.externalEmail, row.externalPhone];

    return notificationValue.filter((value) => value).join(', ');
  };

  const getStringifyUsersName = (users: { id: number; username: string }[]) => {
    return users?.map((user) => user.username).join(', ');
  };

  const USERS = 'users';

  const alarmsColumnsDef: ColDef<AlarmDto>[] = [
    { key: 'odometer', nested: true, isDate: false, sortable: true },
    { key: 'value', nested: false, isDate: false, sortable: true },
    {
      key: USERS,
      nested: false,
      isDate: false,
      sortable: false,
      valueGetter: ({ row }: CTableValueSetterParams): string => {
        return row[USERS] && getStringifyUsersName(row[USERS]);
      },
    },
    {
      key: 'externalNotification',
      nested: false,
      isDate: false,
      sortable: false,
      valueGetter: ({ row }: CTableValueSetterParams): string => {
        return getExternalNotificationValue(row);
      },
    },
  ];

  const fetchAlarms = useCallback(async () => {
    try {
      const skip = getSkipCount(currentPage, rowsPerPage);

      const alarms = await AlarmService.getAlarmList({
        sortBy: 'odometer',
        deleted: false,
        descending: false,
        skip,
        take: rowsPerPage,
        ...filter,
      });
      setAlarms(alarms.data as AlarmDto[]);
      setCountOfAlarms(alarms.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, rowsPerPage, showGenericErrorToast, filter]);

  const fetchOdometers = useCallback(async () => {
    try {
      const odometers = await OdometerService.getOdometerList({
        sortBy: 'name',
        deleted: false,
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      const mappedOdometersToSelect = odometers.data?.map((item): Option => {
        const odometer = item as OdometerDto;
        return {
          value: odometer?.id || 0,
          label: odometer?.name || '',
        };
      });

      if (mappedOdometersToSelect?.length) {
        setOdometers(mappedOdometersToSelect);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [showGenericErrorToast]);

  useEffect(() => {
    fetchAlarms();
    fetchOdometers();
  }, [currentPage, fetchAlarms, fetchOdometers, filter, rowsPerPage]);

  const handleCancel = () => {
    setChosenAlarms(null);
    setShowModal(false);
  };

  const handleAddAlarm = () => {
    setShowModal(true);
  };

  const handleEditAlarm = (alarm: AlarmDto) => {
    if (!alarm) return;
    const foundAlarm = alarms.find((item) => item.id === parseInt(alarm.id as any as string));
    if (!foundAlarm) return;
    setChosenAlarms(foundAlarm);
    setShowModal(true);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (
    values: ElectricityAlarmFormOutputValues,
    id?: number | null
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    try {
      if (id) {
        const data = (
          await AlarmService.putAlarmUpdate({
            id,
            requestBody: valuesWithoutNullFields as AlarmRequestDto,
          })
        ).data;

        if (data) {
          setAlarms(
            (alarms) => updateObjectInArray(alarms, data) as AlarmDto[]
          );
        }

        setChosenAlarms(null);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      } else {
        await AlarmService.postAlarmCreate({
          requestBody: valuesWithoutNullFields as AlarmRequestDto,
        });
        await fetchAlarms();

        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      }

      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleDeleteAlarm = async (id: number) => {
    if (!id) return;

    try {
      await AlarmService.deleteAlarmDelete({ id });
      await fetchAlarms();

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  console.log(alarms);
  console.log(chosenAlarms);

  return (
    <ElectricityAlarmsContext.Provider value={odometers}>
      <CDataGrid
        colDef={alarmsColumnsDef}
        translationKey="electricityPage.alarmPage"
        data={alarms}
        onAdd={handleAddAlarm}
        onDelete={handleDeleteAlarm}
        onEdit={(values) => handleEditAlarm(values as AlarmDto)}
        filters={
          <ElectricityAlarmsFilters
            updateFilter={updateFilter}
            filter={filter}
          />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfAlarms,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <CModal
        open={showModal}
        title={t('electricityPage.alarmPage.title')}
        type={chosenAlarms ? 'edit' : 'create'}
        onClose={handleCancel}
      >
        <ElectricityAlarmForm
          alarm={chosenAlarms}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </CModal>
    </ElectricityAlarmsContext.Provider>
  );
};
export default ElectricityAlarmsPage;
