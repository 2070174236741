import { FC, useEffect, useMemo, useRef, useState } from "react";
import {useTranslation} from 'react-i18next';
import {
  Autocomplete, Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell, tableCellClasses,
  TableContainer,
  TableHead, TableRow,
  Typography,
  useTheme
} from '@mui/material';
import classNames from 'classnames';
import {useRoute} from 'wouter';

import {
  EnergyReadingsService,
  ReadingDto,
  ReadingsMultipleDto,
  ReadoutDto,
  ReadoutService
} from '@/_generatedApi';
import CDatePicker from '@/components/ui/common/date-picker/CDatePicker';
import FilterItemWrapper from '@/components/ui/fields/FilterItemWrapper';
import Select from '@/components/ui/fields/Select';
import {useShowToast} from '@/hooks/use-show-toast';

import ElectricityReadingsTable from '../../components/electricity-readings/ElectricityReadingsTable';

import styles from './detail.module.css';
import CButton from "@/components/ui/common/button/CButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ElectricityReadoutForm from "@/components/electricity-readings/ElectricityReadoutForm";
import {format} from "date-fns";
import LinearProgress from "@mui/material/LinearProgress";

type consumptionUnitFilterTypes = '1 week' | '1 month' | '1 year';

const StyledTableCell = styled(TableCell)(() => {
  const theme = useTheme();
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  };
});

const StyledTableRow = styled(TableRow)(() => {
  const theme = useTheme();
  return {
    ['&:nth-of-type(even)']: {
      backgroundColor: theme.palette.background.default,
    },
    // hide last border
    ['&:last-child td, &:last-child th']: {
      border: 0,
    },
  };
});

export interface YearData {
  year: number,
  data: ReadingDto[]
}

const OdometerDetail: FC<{
  route: string;
}> = ({route}) => {
  const {t, i18n} = useTranslation();
  const [, params] = useRoute(route);
  const {showGenericErrorToast} = useShowToast();

  const scrollSyncRef = useRef(new Map<number, HTMLElement | null>([]));

  const [odometer, setOdometer] = useState<ReadingsMultipleDto>();
  const [filter, setFilter] = useState<{
    consumptionUnit: consumptionUnitFilterTypes;
    from: Date;
    to: Date;
  }>({
    consumptionUnit: '1 month',
    from: new Date(new Date().getFullYear(), 0, 1),
    to: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59),
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params?.id) {
      return;
    }

    if (filter.from > filter.to) {
      setFilter({
        ...filter,
        from: filter.to,
      });
    }

    const fetchOdometerConsumption = async () => {
      setLoading(true);
      try {
        const data = (
          await EnergyReadingsService.getEnergyReadingsConsumption({
            odometerId: parseInt(params.id || '0'),
            consumptionUnit: filter.consumptionUnit,
            consumptionStartDate: `${filter.from.getFullYear()}-01-01`,
            consumptionEndDate: `${filter.to.getFullYear()}-12-31`,
          })
        ).data;

        if (data && data.length > 0) {
          setOdometer(data[0]);
          setLoading(false);
        }
      } catch (e) {
        showGenericErrorToast();
      }
    };

    fetchOdometerConsumption();
  }, [params?.id, filter, showGenericErrorToast]);

  const dataByYear = useMemo(() => {
    const data: YearData[] = [];

    if (odometer) {
      odometer.data.forEach(item => {
        const year = (new Date(item.fromDate)).getFullYear();
        const existingData = data.find(item => item.year === year);

        if (existingData) {
          existingData.data.push(item)
        } else {
          data.push({
            year,
            data: [item]
          })
        }
      })
    }

    return data;
  }, [odometer])

  const [dialogOpen, setDialogOpen] = useState(false);
  const {showToast} = useShowToast();

  const [readouts, setReadouts] = useState<ReadoutDto[]>([])


  const reloadData = () => {
    if (!params) {
      return;
    }
    ReadoutService.getReadoutList({odometerId: params.id}).then((data) => {
      if (data?.data) {
        setReadouts(data.data);
      }
    })
  }
  useEffect(reloadData, [params?.id])

  return (
    <>
      <div
        className={classNames(
          styles.wrapper,
          styles.aligned,
          styles.titleSection
        )}
      >
        <Typography variant="h1">{odometer?.odometer.name}</Typography>
        <div className={classNames(styles.wrapper)}>
          <FilterItemWrapper width={180}>
            <Select
              name="unit"
              onBlur={() => {
              }}
              onChange={(value) => {
                setFilter((filter) => ({
                  ...filter,
                  consumptionUnit: value as consumptionUnitFilterTypes,
                }));
              }}
              options={[
                {
                  value: '1 day',
                  label: t('electricityReadingsPage.gauge.detail.perDay'),
                },
                {
                  value: '1 week',
                  label: t('electricityReadingsPage.gauge.detail.perWeek'),
                },
                {
                  value: '1 month',
                  label: t('electricityReadingsPage.gauge.detail.perMonth'),
                },
                {
                  value: '1 year',
                  label: t('electricityReadingsPage.gauge.detail.perYear'),
                },
              ]}
              error=""
              required={false}
              label={t('electricityReadingsPage.gauge.detail.consumptionPer')}
              value={filter.consumptionUnit || ''}
              withoutHelperText
            />
          </FilterItemWrapper>
          <FilterItemWrapper width={150}>
            <CDatePicker
              name="from"
              onChange={(value) => {
                setFilter((filter) => ({ ...filter, from: value as Date }));
              }}
              onBlur={() => {
              }}
              error=""
              required={false}
              label={t('electricityReadingsPage.gauge.detail.from')}
              value={filter.from}
              views={['year']}
              withoutHelperText
            />
          </FilterItemWrapper>
          <FilterItemWrapper width={150}>
            <CDatePicker
              name="to"
              onChange={(value) => {
                setFilter((filter) => ({ ...filter, to: value as Date }));
              }}
              onBlur={() => {
              }}
              error=""
              required={false}
              minDate={filter.from}
              label={t('electricityReadingsPage.gauge.detail.to')}
              value={filter.to}
              views={['year']}
              withoutHelperText
            />
          </FilterItemWrapper>
        </div>
      </div>

      <div style={{ height: 4 }}>
        {
          loading && <LinearProgress />
        }
      </div>

      <div className={classNames(styles.wrapper)}>
        {/*{odometer?.data.map((odometerConsumption) => (*/}
        {/*  <div key={odometerConsumption.fromDate}>*/}
        {
          dataByYear.map(data => (
            <ElectricityReadingsTable unit={odometer?.odometer.unit || ''} key={data.year} scrollSyncRef={scrollSyncRef} data={data} />))
        }
        {/*  </div>*/}
        {/*))}*/}
      </div>

      <div className={classNames(styles.wrapper)}>
        <Typography variant="h6" component="span">
          Odečty
        </Typography>
        <TableContainer sx={{ maxHeight: 450 }} component={Paper}>
          <Table
            stickyHeader
            sx={{ minWidth: 450 }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  Datum odečtu
                </StyledTableCell>
                <StyledTableCell align="right">
                  Hodnota
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                readouts.map(readout => (<StyledTableRow
                  key={readout.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell>{format(new Date(readout.date), 'd.m.yyyy HH:mm')}</StyledTableCell>
                  <StyledTableCell align="right">{readout.value}</StyledTableCell>
                </StyledTableRow>))
              }
            </TableBody>
          </Table>
        </TableContainer>

        <CButton type="button" onClick={() => setDialogOpen(true)} variant="add">Elektroměr</CButton>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Odečet</DialogTitle>
          <DialogContent>
            <ElectricityReadoutForm odometerId={params?.id ? parseInt(params.id) : undefined} onSubmit={(data) => {
              ReadoutService.postReadoutCreate({
                requestBody: {
                  ...data,
                  date: format(data.date, 'yyyy-MM-dd') + 'T' + format(data.date, 'HH:mm:ssxxx')
                }
              }).then(() => {
                setDialogOpen(false);
                reloadData();
                showToast('success', t('notifications.itemWasSuccessfullyCreated'));
              })
            }} onCancel={() => setDialogOpen(false)} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default OdometerDetail;
