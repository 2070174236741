import { useEffect, useState } from 'react';

import {OdometerDto, OdometerService} from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useOdometers = () => {
  const [odometers, setOdometers] = useState<OdometerDto[]>([]);
  const [odometersOptions, setOdometersOptions] = useState<Option[]>([]);
  const [odometersOptionsByMedium, setOdometersOptionsByMedium] = useState<Map<string, Option[]>>(new Map([]));

  const fetchData = async () => {
    const data = (
      await OdometerService.getOdometerList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
        deleted: false
      })
    ).data as OdometerDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    const optionsByMedium = (data || []).reduce((buffer, odometer) => {
      if (odometer.medium?.type) {
        if (buffer.has(odometer.medium.type)) {
          buffer.get(odometer.medium.type)?.push({ label: odometer.name, value: odometer.id });
        } else {
          buffer.set(odometer.medium.type, [{ label: odometer.name, value: odometer.id }])
        }
      }
      return buffer;
    }, new Map<string, Option[]>([]))

    setOdometers(data as OdometerDto[]);
    setOdometersOptions(options);
    setOdometersOptionsByMedium(optionsByMedium);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    odometers,
    odometersOptions,
    odometersOptionsByMedium,
  };
};
