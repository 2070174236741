export const Months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'OCT',
  'SEP',
  'NOV',
  'DEC',
];
