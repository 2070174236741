import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { BranchOfficeDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { BranchOfficeContext } from '@/pages/settings-branch-office-page/SettingsBranchOfficePage';

import Select from '../ui/fields/Select';
import Textarea from '../ui/fields/Textarea';
import {useFabricJSEditor} from "fabricjs-react";
import {SettingsWorkshopPlan} from "@/components/settings-workshops/SettingsWorkshopPlan";

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', { count: 4 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    city: yup
      .string()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    superiorBranchOfficeId: yup.number().nullable(),
    comment: yup
      .string()
      .nullable()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(255, t('validation.maxChars', { count: 255 }) as string),
    plan: yup.string(),
  });

export type BranchOfficeFormInputValues = {
  name: string | null;
  city: string | null;
  superiorBranchOfficeId: number | null;
  comment: string | null;
  plan: string | null;
};

export type BranchOfficeFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type BranchOfficeFormProps = {
  branchOffice: BranchOfficeDto | null;
  onSubmit: (values: BranchOfficeFormOutputValues, id?: number) => void;
  onCancel: () => void;
};

const BranchOfficeForm: FC<BranchOfficeFormProps> = ({
  branchOffice,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const branchOffices = useContext(BranchOfficeContext);
  const dialogType = branchOffice ? 'update' : 'add';

  const initialValues: BranchOfficeFormInputValues = {
    name: branchOffice?.name || null,
    city: branchOffice?.city || null,
    superiorBranchOfficeId: branchOffice?.superiorBranchOffice?.id || null,
    comment: branchOffice?.comment || null,
    plan: branchOffice?.plan || '[]'
  };

  const handleSubmit = async (values: BranchOfficeFormInputValues) => {
    values.plan = JSON.stringify(editor?.canvas?.toJSON(['data']));
    onSubmit(
      values as BranchOfficeFormOutputValues,
      branchOffice ? branchOffice.id : undefined
    );
  };

  const {editor, selectedObjects, onReady} = useFabricJSEditor();

  const superiorBranchOfficesOptions = useMemo(() => {
    if (branchOffice) {
      return branchOffices?.filter(
        (option) => option.value !== branchOffice.id
      );
    }
    return branchOffices;
  }, [branchOffice, branchOffices]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ isSubmitting, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('branchOfficesSettingsPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />

            <Field<string>
              name="city"
              render={(props) => (
                <Input
                  label={t('branchOfficesSettingsPage.city')}
                  {...props}
                  required
                  data-cy-field="city"
                />
              )}
            />
            <Field<string>
              name="comment"
              render={(props) => (
                <Textarea
                  label={t('branchOfficesSettingsPage.comment')}
                  {...props}
                  data-cy-field="comment"
                />
              )}
            />
            <Field<number>
              name="superiorBranchOfficeId"
              render={(props) => (
                <Select
                  options={superiorBranchOfficesOptions || []}
                  label={t('branchOfficesSettingsPage.superiorBranchOffice')}
                  {...props}
                  data-cy-field="superiorBranchOffice"
                  clearable
                />
              )}
            />

            <Field<string>
              name="plan"
              render={(props) => (
                <SettingsWorkshopPlan
                  editor={editor}
                  selectedObjects={selectedObjects || []}
                  onReady={onReady}
                  initialValue={initialValues.plan || '[]'}
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default BranchOfficeForm;
