import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  BranchOfficeDto,
  BranchOfficeService,
  WorkshopDto,
  WorkshopRequestDto,
  WorkshopService,
} from '@/_generatedApi';
import SettingsWorkshopForm, {
  SettingsWorkshopFormOutputValues,
} from '@/components/settings-workshops/SettingsWorkshopForm';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import Modal from '@/components/ui/common/modal/CModal';
import { Option } from '@/components/ui/fields/types';
import WorkshopsFilters, {
  WorkshopFilter,
} from '@/components/ui/filters/WorkshopsFilters';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateFilterData, updateObjectInArray } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';
import { nullFieldsToUndefined } from '@/utils/validation';

const fetchAllBranchOffices = async () => {
  return (
    await BranchOfficeService.getBranchOfficeList({
      sortBy: 'name',
      descending: false,
      skip: 0,
      take: MAX_TAKE_ITEMS,
    })
  ).data as BranchOfficeDto[];
};

const fetchWorkshopsForPage = async (
  page: number,
  itemsPerPage: number,
  filter: WorkshopFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return await WorkshopService.getWorkshopList({
    sortBy: 'name',
    descending: false,
    skip,
    take: itemsPerPage,
    ...filter,
  });
};

export const WorkshopContext = createContext<{
  branchOffices: Option[];
}>({ branchOffices: [] });

const SettingsWorkshopPage: FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [countOfWorkshops, setCountOfWorkshops] = useState(0);
  const [workshops, setWorkshops] = useState<WorkshopDto[]>([]);
  const { showToast, showGenericErrorToast } = useShowToast();
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);

  const [chosenWorkshop, setChosenWorkshop] = useState<WorkshopDto | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<WorkshopFilter>({});
  const [branchOffices, setBranchOffices] = useState<Option[]>([]);

  const workshopColumnsDef: ColDef<WorkshopDto>[] = [
    { key: 'name', nested: false, isDate: false, sortable: true },
    { key: 'comment', nested: false, isDate: false, sortable: false },
    { key: 'branchOffice', nested: true, isDate: false, sortable: true },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchWorkshopsForPage(
        currentPage,
        rowsPerPage,
        filter
      );
      setWorkshops(filteredData.data as WorkshopDto[]);
      setCountOfWorkshops(filteredData.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, filter, rowsPerPage, showGenericErrorToast]);

  useEffect(() => {
    fetchAllBranchOffices()
      .then((branchOffices) => {
        const options = branchOffices.map((branchOffice) => ({
          value: branchOffice.id || 0,
          label: branchOffice.name || '',
        }));

        setBranchOffices(options);
      })
      .catch(() => {
        showGenericErrorToast();
      });
  }, [showGenericErrorToast, t]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  const handleAddWorkshop = () => {
    setChosenWorkshop(null);
    setShowModal(true);
  };

  const handleEditWorkshop = (workshop: WorkshopDto) => {
    if (!workshop) return;
    setChosenWorkshop(workshop);
    setShowModal(true);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (
    values: SettingsWorkshopFormOutputValues,
    id?: number
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    if (id) {
      try {
        const data = (
          await WorkshopService.putWorkshopUpdate({
            id,
            requestBody: valuesWithoutNullFields as WorkshopRequestDto,
          })
        ).data;

        if (data) {
          setWorkshops(
            (workshops) => updateObjectInArray(workshops, data) as WorkshopDto[]
          );
        }

        setChosenWorkshop(null);
        setShowModal(false);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      } catch (e) {
        showGenericErrorToast();
      }
    } else {
      try {
        await WorkshopService.postWorkshopCreate({
          requestBody: valuesWithoutNullFields as WorkshopRequestDto,
        });

        fetchData();

        setShowModal(false);

        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      } catch (e) {
        showGenericErrorToast();
      }
    }
  };

  const handleDeleteWorkshop = async (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!id) return;

    try {
      await WorkshopService.deleteWorkshopDelete({ id });

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));

      if (workshops.length === 1 && currentPage !== 1) {
        setCurrentPage((page) => page - 1);
      }

      fetchData();
      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleCancel = () => {
    setChosenWorkshop(null);
    setShowModal(false);
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  return (
    <WorkshopContext.Provider value={{ branchOffices }}>
      <CDataGrid
        colDef={workshopColumnsDef}
        data={workshops}
        translationKey="workshopsSettingsPage"
        onAdd={handleAddWorkshop}
        onDelete={handleDeleteWorkshop}
        onEdit={(values) => handleEditWorkshop(values as WorkshopDto)}
        filters={
          <WorkshopsFilters updateFilter={updateFilter} filter={filter} />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfWorkshops,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <Modal
        open={showModal}
        title={t('navigation.workshops')}
        onClose={() => setShowModal(false)}
        type={chosenWorkshop ? 'edit' : 'create'}
        width={1000}
      >
        <SettingsWorkshopForm
          workshop={chosenWorkshop}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Modal>
    </WorkshopContext.Provider>
  );
};
export default SettingsWorkshopPage;
