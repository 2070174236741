import { useEffect, useState } from 'react';

import {
  PowerFactorDto, PowerFactorService
} from "@/_generatedApi";
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const usePowerFactors = () => {
  const [powerFactors, setPowerFactors] = useState<PowerFactorDto[]>([]);
  const [powerFactorsOptions, setPowerFactorsOptions] = useState<Option[]>(
    []
  );

  const fetchData = async () => {
    const data = (
      await PowerFactorService.getPowerFactorList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as PowerFactorDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setPowerFactors(data as PowerFactorDto[]);
    setPowerFactorsOptions(options as Option[]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    powerFactors,
    powerFactorsOptions,
  };
};
