/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediumDto } from '../models/MediumDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MediumService {
  /**
   * @returns any Medium list.
   * @throws ApiError
   */
  public static getMediumList({
    take,
    skip,
    deleted,
    name,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    name?: string;
    sortBy?: 'id' | 'name';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<MediumDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medium/',
      query: {
        deleted: deleted,
        name: name,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postMediumCreate({
    requestBody,
  }: {
    /**
     * Create new medium
     */
    requestBody: MediumDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: MediumDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/medium/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getMediumFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: MediumDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medium/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `medium not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putMediumUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update medium
     */
    requestBody: MediumDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: MediumDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/medium/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Center not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteMediumDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/medium/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Medium not found`,
      },
    });
  }
}
