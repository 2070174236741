import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
} from '@mui/material';
import { v4 } from 'uuid';
import { useLocation, useRoute } from 'wouter';

import WithLink from '@/components/routing/WithLink';
import { MenuContext } from '@/contexts/layout/MenuContext';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { ROUTE_PATHS } from '@/Routes';
import { Menu } from '@/types/core-types';

const Item: FC<
  {
    path: string;
  } & Menu[string]
> = ({ path, name, isMenuHidden, icon }) => {
  const [isMatching] = useRoute(`${path}/:rest*`);
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  if (
    isMenuHidden === (isMobile ? 'mobile' : 'desktop') ||
    isMenuHidden === true
  ) {
    return null;
  }

  return (
    <WithLink href={path}>
      <MenuItem
        sx={{
          ...(isMatching && {
            color: 'secondary.main',
          }),
        }}
        data-cy="settingsMenuItem"
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={t(`navigation.${name}`)} />
      </MenuItem>
    </WithLink>
  );
};

const Settings: FC = () => {
  const ids = useRef({
    button: v4(),
    menu: v4(),
  });

  const [location] = useLocation();
  const [isMatching] = useRoute('/settings/:rest*');
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = !!anchorEl;

  const { menu } = useContext(MenuContext);
  const settingsMenu = menu?.[ROUTE_PATHS.SETTINGS];

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  if (settingsMenu === undefined) {
    return null;
  }

  return (
    <div>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          '&, & svg': {
            color: isMatching ? 'secondary.main' : 'common.white',
          },
        }}
        data-cy="settingsButton"
        id={ids.current.button}
        aria-haspopup="true"
        {...(isOpen && {
          'aria-controls': ids.current.menu,
          'aria-expanded': true,
        })}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        {settingsMenu.icon}
        {t(`navigation.${settingsMenu.name}`)}
      </Button>
      <MuiMenu
        id={ids.current.menu}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': ids.current.button,
        }}
      >
        {Object.entries(settingsMenu.menu ?? []).map(([path, rest]) => (
          <Item key={path} path={path} {...rest} />
        ))}
      </MuiMenu>
    </div>
  );
};

export default Settings;
