import { ReactElement } from 'react';
import { Form as FormikForm, Formik, FormikConfig, FormikValues } from 'formik';

import { FormContextType } from '@/contexts/form/types';

const Form = <Values extends FormikValues = FormikValues>(
  props: Omit<FormikConfig<Values>, 'validationSchema'> & FormContextType
): ReactElement | null => {
  return (
    <Formik {...props} validationSchema={props.validationSchema}>
      {(formikProps) => (
        <FormikForm
          noValidate
          style={{
            width: '100%',
          }}
        >
          {props.children instanceof Function
            ? props.children(formikProps)
            : props.children}
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
