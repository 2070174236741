import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DrawerHead: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      }}
    >
      {children}
    </Box>
  );
};

export default DrawerHead;
