import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const UnderConstructionPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h1" mb={2}>
      {t('underConstructionPage.title')}
    </Typography>
  );
};

export default UnderConstructionPage;
