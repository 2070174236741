import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Link, useLocation } from 'wouter';
import * as yup from 'yup';

import { AuthService } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Password from '@/components/ui/fields/Password';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { UserContext } from '@/contexts/user-context/UserContext';
import { useQuery } from '@/hooks/use-query';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';
import { passwordSchema } from '@/utils/validation';

const validationSchema = yup.object({
  password: passwordSchema.nullable().required('validation.required'),
  confirm: yup
    .string()
    .oneOf([yup.ref('password')], 'loginPage.validation.passwordsDontMatch')
    .nullable()
    .required('validation.required'),
});
export type ChangePasswordValues = yup.InferType<typeof validationSchema>;

const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();
  const { user, updateToken } = useContext(UserContext);
  const query = useQuery();
  const token = query.get('resetPasswordToken');
  const { showGenericErrorToast, showToast } = useShowToast();
  const [, setLocation] = useLocation();

  const initialValues = {
    confirm: '',
    password: '',
  } satisfies ChangePasswordValues;

  const handleSubmit = async (values: ChangePasswordValues) => {
    if (user) {
      try {
        const response = await AuthService.postChangePassword({
          requestBody: {
            newPassword: values.password,
          },
        });

        if (response.accessToken) {
          updateToken(response.accessToken);
        }
        showToast('success', t('notifications.successChangePassword'));
      } catch (e) {
        showGenericErrorToast();
      }
    } else {
      if (token) {
        try {
          AuthService.postResetPassword({
            requestBody: {
              newPassword: values.password,
              resetPasswordToken: token,
            },
          });
          showToast('success', t('notifications.successChangePassword'));
          setLocation(ROUTE_PATHS.LOGIN);
        } catch (e) {
          showGenericErrorToast();
        }
      }
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="password"
              render={(props) => (
                <Password
                  label={t('loginPage.newPassword')}
                  {...props}
                  data-cy-field="password"
                />
              )}
            />
            <Field<string>
              name="confirm"
              render={(props) => (
                <Password
                  label={t('loginPage.repeatNewPassword')}
                  {...props}
                  data-cy-field="confirm"
                />
              )}
            />
            <Button
              type="submit"
              size="large"
              variant="add"
              startIcon={null}
              disabled={isSubmitting}
              data-cy-button="save"
              onMouseDown={(e) => e.preventDefault()}
            >
              {t('common.save')}
            </Button>
            <Link
              href={ROUTE_PATHS.LOGIN}
              style={{ color: 'black', fontSize: '14px', marginTop: '24px' }}
            >
              {t('changePasswordPage.backToLogin')}
            </Link>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ChangePasswordPage;
