import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';

import {
  UserDto,
  UserRequestDto,
  UserService,
  UserUpdateRequestDto,
} from '@/_generatedApi';
import SettingsUsersForm from '@/components/settings-users/SettingsUsersForm';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import Modal from '@/components/ui/common/modal/CModal';
import { ITEMS_PER_PAGE } from '@/constants/pagination';
import { UserContext } from '@/contexts/user-context/UserContext';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateObjectInArray } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';
import { nullFieldsToUndefined } from '@/utils/validation';

type OdometersFilter = {
  sortBy?: 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | undefined;
  descending?: boolean;
};

const fetchUsersForPage = async (
  page: number,
  itemsPerPage: number,
  filter: OdometersFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return await UserService.getUserList({
    sortBy: 'firstName',
    descending: false,
    skip,
    take: itemsPerPage,
    ...filter,
  });
};

const SettingsUsersPage: FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const [countOfUsers, setCountOfUsers] = useState(0);
  const [users, setUsers] = useState<UserDto[]>([]);
  const { showToast, showGenericErrorToast } = useShowToast();

  const [chosenUser, setChosenUser] = useState<UserDto | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [filter, setFilter] = useState<OdometersFilter>({});

  const usersColumnsKeys: ColDef<UserDto>[] = [
    { key: 'firstName', nested: false, isDate: false, sortable: true },
    { key: 'lastName', nested: false, isDate: false, sortable: true },
    { key: 'email', nested: false, isDate: false, sortable: true },
    { key: 'phone', nested: false, isDate: false, sortable: true },
    {
      key: 'role',
      nested: false,
      isDate: false,
      sortable: true,
      renderCell: ({ row }: { row: UserDto }) => {
        return t(`enums.userRoles.${row.role}`);
      },
    },
    {
      key: 'active',
      nested: false,
      isDate: false,
      sortable: true,
      renderCell: ({ row }: { row: UserDto }) => {
        return <Checkbox disabled checked={row.confirmed} />;
      },
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchUsersForPage(
        currentPage,
        rowsPerPage,
        filter
      );

      if (user) {
        const dataWithoutCurrentUser = filteredData.data?.filter(
          (data) => data.id !== user?.id
        );
        setUsers(dataWithoutCurrentUser as UserDto[]);
        setCountOfUsers(
          filteredData.metadata?.total ? filteredData.metadata?.total - 1 : 0
        );
      } else {
        setUsers(filteredData as UserDto[]);
        setCountOfUsers(filteredData.metadata?.total || 0);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, rowsPerPage, filter, user, showGenericErrorToast]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  const handleAddUser = () => {
    setChosenUser(null);
    setShowModal(true);
  };

  const handleEditUser = (user: UserDto) => {
    if (!user) return;
    setChosenUser(user);
    setShowModal(true);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (values: UserRequestDto, id?: number) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    if (id) {
      try {
        const data = (
          await UserService.putUserUpdate({
            id,
            requestBody: valuesWithoutNullFields as UserUpdateRequestDto,
          })
        ).data;

        if (data) {
          setUsers((users) => updateObjectInArray(users, data) as UserDto[]);
        }

        setChosenUser(null);
        setShowModal(false);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      } catch (e) {
        showGenericErrorToast();
      }
    } else {
      try {
        await UserService.postUserCreate({
          requestBody: valuesWithoutNullFields as UserRequestDto,
        });

        fetchData();

        setShowModal(false);
        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      } catch (e) {
        showGenericErrorToast();
      }
    }
  };

  const handleDeleteUser = async (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!id) return;

    try {
      await UserService.deleteUserDelete({ id });

      if (users.length === 1 && currentPage !== 1) {
        setCurrentPage((page) => page - 1);
      }

      fetchData();

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));
      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleCancel = () => {
    setChosenUser(null);
    setShowModal(false);
  };

  return (
    <div>
      <CDataGrid
        colDef={usersColumnsKeys}
        translationKey="usersSettingsPage"
        data={users}
        onAdd={handleAddUser}
        onDelete={handleDeleteUser}
        onEdit={(values) => handleEditUser(values as UserDto)}
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter(() => ({
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfUsers,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <Modal
        open={showModal}
        title={t('navigation.users')}
        onClose={() => setShowModal(false)}
        type={chosenUser ? 'edit' : 'create'}
      >
        <SettingsUsersForm
          user={chosenUser}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};
export default SettingsUsersPage;
