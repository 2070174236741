import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';

import { AuthService } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { UserContext } from '@/contexts/user-context/UserContext';
import { useShowToast } from '@/hooks/use-show-toast';
import { passwordSchema } from '@/utils/validation';

import Password from '../ui/fields/Password';

const validationSchema = yup.object({
  newPassword: passwordSchema.nullable().required('validation.required'),
  oldPassword: yup.string().nullable().required('validation.required'),
  confirm: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'loginPage.validation.passwordsDontMatch')
    .nullable()
    .required('validation.required'),
});

export type ProfileChangePasswordFormValues = yup.InferType<
  typeof validationSchema
>;

const ProfileChangePasswordForm: FC = () => {
  const { t } = useTranslation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const { updateToken } = useContext(UserContext);

  const initialValues = {
    newPassword: '',
    oldPassword: '',
    confirm: '',
  } satisfies ProfileChangePasswordFormValues;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (
    values: ProfileChangePasswordFormValues,
    helpers: FormikHelpers<ProfileChangePasswordFormValues>
  ) => {
    try {
      const response = await AuthService.postChangePassword({
        requestBody: values,
      });

      if (response.accessToken) {
        updateToken(response.accessToken);
      }

      helpers.resetForm();
      showToast('success', t('notifications.successChangePassword'));
    } catch (e) {
      showGenericErrorToast();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '374px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="oldPassword"
              render={(props) => (
                <Password
                  label={t('loginPage.oldPassword')}
                  {...props}
                  data-cy-field="oldPassword"
                />
              )}
            />
            <Field<string>
              name="newPassword"
              render={(props) => (
                <Password
                  label={t('loginPage.password')}
                  {...props}
                  data-cy-field="newPassword"
                />
              )}
            />
            <Field<string>
              name="confirm"
              render={(props) => (
                <Password
                  label={t('loginPage.repeatNewPassword')}
                  {...props}
                  data-cy-field="confirm"
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="button"
                size="large"
                disabled={!dirty}
                data-cy-button="cancel"
                onClick={resetForm}
                onMouseDown={(e) => e.preventDefault()}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                size="large"
                variant="update"
                disabled={isSubmitting}
                data-cy-button="save"
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ProfileChangePasswordForm;
