import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import cs from './cs';
import en from './en';
import { Language } from './enums';

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      cs,
    },
    lng: Language.CS,
    fallbackLng: Language.CS,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['cookie', 'localStorage'],
    },
  });
