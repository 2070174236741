import { FC, PropsWithChildren, ReactElement, useContext } from 'react';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import HistoryIcon from '@mui/icons-material/History';
import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Redirect, Route } from 'wouter';

import ElectricityAlarmHistory from '@/pages/electricity-alarms-history-page/ElectricityAlarmsHistoryPage';
import ElectricityAlarmPage from '@/pages/electricity-alarms-page/ElectricityAlarmsPage';
import ElectricityOdometersDetailPage from '@/pages/electricity-odometers-history-page/ElectricityOdometersDetailPage';
import ElectricityOdometersHistoryPage from '@/pages/electricity-odometers-history-page/ElectricityOdometersHistoryPage';
import ElectricityReadingsPage from '@/pages/electricity-readings-page/ElectricityReadingsPage';
import { Menu, Routes, RouteType } from '@/types/core-types';
import { COLORS } from '@/utils/variables';

import { AuthRoute, UnAuthRoute } from './components/auth/Route';
import RoutesGroup from './components/routing/RoutesGroup';
import { MenuProvider } from './contexts/layout/MenuContext';
import { UserContext } from './contexts/user-context/UserContext';
import AuthLayout from './layouts/AuthLayout';
import DashboardLayout from './layouts/dashboard-layout/DashboardLayout';
import DefaultLayout from './layouts/default-layout/DefaultLayout';
import ChangePasswordPage from './pages/change-password-page/ChangePasswordPage';
import ElectricityDashboardPage from './pages/electricity-dashboard-page/ElectricityDashboardPage';
import ElectricityReadingsDetailPage from './pages/electricity-readings-page/ElectricityReadingsDetailPage';
import EnergyDashboardPage from './pages/energy-dashboard-page/EnergyDashboardPage';
import ForgotPasswordPage from './pages/forgot-password-page/ForgotPasswordPage';
import LoginPage from './pages/login-page/LoginPage';
import NotFoundPage from './pages/not-found-page/NotFoundPage';
import ProfilePage from './pages/profile-page/ProfilePage';
import SettingsBranchOfficePage from './pages/settings-branch-office-page/SettingsBranchOfficePage';
import SettingsCentersPage from './pages/settings-centers-page/SettingsCentersPage';
import SettingsOdometerEditPage from './pages/settings-odometers-pages/SettingsOdometerEditPage';
import SettingsOdometerCreatePage from './pages/settings-odometers-pages/SettingsOdometersCreatePage';
import SettingsOdometersPage from './pages/settings-odometers-pages/SettingsOdometersPage';
import SettingsUsersPage from './pages/settings-users-page/SettingsUsersPage';
import SettingsWorkshopPage from './pages/settings-workshops-page/SettingsWorkshopPage';
import UnderConstructionPage from './pages/under-construction-page/UnderContructionPage';
import { UserDto, UserRequestDto } from './_generatedApi';
import ElectricityOnlinePage from "@/pages/electricity-online-page/ElectricityOnlinePage";
import ElectricityWorkshopPage from "@/pages/electricity-workshop-page/ElectricityWorkshopPage";
import SettingsGroupPage from "@/pages/settings-group-page/SettingsGroupPage";
import {SankeyPage} from "@/pages/sankey-page/SankeyPage";
import SettingsPowerFactorPage from './pages/settings-powerFactor-page/SettingsPowerFactorPage';
import { PowerFactorPage } from "@/pages/power-factor-page/PowerFactorPage";
import { Typography } from "@mui/material";
import GasOnlinePage from "@/pages/gas-online-page/GasOnlinePage";
import WaterOnlinePage from "@/pages/water-online-page/WaterOnlinePage";
import WaterReadingsPage from "@/pages/water-readings-page/WaterReadingsPage";
import GasReadingsPage from "@/pages/gas-readings-page/GasReadingsPage";
import GasReadingsDetailPage from './pages/gas-readings-page/GasReadingsDetailPage';
import WaterReadingsDetailPage from './pages/water-readings-page/WaterReadingsDetailPage';

export const ROUTE_PATHS = {
  ONLINE: '/online',
  WORKSHOP_OVERVIEW: '/workshop-overview',
  DASHBOARD: '/dashboard',
  READINGS: '/readings',
  WATER_READINGS: '/water-readings',
  GAS_READINGS: '/gas-readings',
  SANKEY: '/sankey',
  ALARMS: '/alarms',
  HISTORICAL_ODOMETERS: '/historical-odometers',
  ALARMS_HISTORY: '/alarms-history',
  LOGIN: '/login',
  REGISTER: '/register',
  HOME: '/',
  PROFILE: '/profile',
  ENERGY: '/energy',
  ENERGY_DASHBOARD: '/energy-dashboard',
  ELECTRICITY: '/electricity',
  WATER: '/water',
  GAS: '/gas',
  ENVIRONMENT: '/environment',
  SETTINGS: '/settings',
  BRANCH_OFFICES: '/branch-offices',
  WORKSHOPS: '/workshops',
  CENTERS: '/centers',
  GROUPS: '/groups',
  ODOMETERS: '/odometers',
  POWER_FACTOR: '/power-factor',
  POWER_FACTORS: '/power-factors',
  READING_DETAIL: '/energy/electricity/readings/:id',
  WATER_READING_DETAIL: '/energy/water/readings/:id',
  GAS_READING_DETAIL: '/energy/gas/readings/:id',
  ODOMETER_DETAIL: '/energy/electricity/historical-odometers/:id',
  ODOMETER_EDIT: '/odometers/:id/edit',
  ODOMETER_CREATE: '/odometers/create',
  USERS: '/users',
  FILTERS: '/filters',
  SANITIZERS: '/sanitizers',
  PROCESSOR_CONFIGS: '/processor-configs',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
};

/* ------------------------------------------------------ */

const ROUTES = {
  LOGIN: {
    path: ROUTE_PATHS.LOGIN,
    type: RouteType.UNAUTH,
    Layout: AuthLayout,
    component: <LoginPage />,
  },
  REGISTER: {
    path: ROUTE_PATHS.REGISTER,
    type: RouteType.UNAUTH,
  },
  FORGOT_PASSWORD: {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    type: RouteType.UNAUTH,
    Layout: AuthLayout,
    component: <ForgotPasswordPage />,
  },
  CHANGE_PASSWORD: {
    path: ROUTE_PATHS.CHANGE_PASSWORD,
    type: RouteType.UNAUTH,
    Layout: AuthLayout,
    component: <ChangePasswordPage />,
  },
  PROFILE: {
    path: ROUTE_PATHS.PROFILE,
    type: RouteType.AUTH,
    Layout: DashboardLayout,
    component: <ProfilePage />,
    isMenuHidden: true,
  },
  HOME: {
    path: ROUTE_PATHS.HOME,
    type: RouteType.AUTH,
    Layout: DashboardLayout,
    component: (
      <Redirect to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ENERGY_DASHBOARD}`} />
    ),
    isMenuHidden: true,
  },
  ELECTRICITY_READINGS_DETAIL: {
    path: ROUTE_PATHS.READING_DETAIL,
    type: RouteType.AUTH,
    component: <ElectricityReadingsDetailPage />,
    Layout: DashboardLayout,
    isMenuHidden: true,
  },
  WATER_READINGS_DETAIL: {
    path: ROUTE_PATHS.WATER_READING_DETAIL,
    type: RouteType.AUTH,
    component: <WaterReadingsDetailPage />,
    Layout: DashboardLayout,
    isMenuHidden: true,
  },
  GAS_READINGS_DETAIL: {
    path: ROUTE_PATHS.GAS_READING_DETAIL,
    type: RouteType.AUTH,
    component: <GasReadingsDetailPage />,
    Layout: DashboardLayout,
    isMenuHidden: true,
  },
  ELECTRICITY_ODOMETER_HISTORY_DETAIL: {
    path: ROUTE_PATHS.ODOMETER_DETAIL,
    type: RouteType.AUTH,
    component: <ElectricityOdometersDetailPage />,
    Layout: DashboardLayout,
    isMenuHidden: true,
  },
  ENERGY: {
    path: ROUTE_PATHS.ENERGY,
    type: RouteType.AUTH,
    Layout: DashboardLayout,
    component: (
      <Redirect to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ENERGY_DASHBOARD}`} />
    ),
    isRoot: true,
    routes: {
      ENERGY_DASHBOARD: {
        path: ROUTE_PATHS.ENERGY_DASHBOARD,
        type: RouteType.AUTH,
        icon: <BatteryCharging80Icon sx={{ color: COLORS.purple.main }} />,
        Layout: DashboardLayout,
        component: <EnergyDashboardPage />,
      },
      ELECTRICITY: {
        path: ROUTE_PATHS.ELECTRICITY,
        type: RouteType.AUTH,
        icon: <ElectricBoltIcon sx={{ color: COLORS.purple.main }} />,
        component: (
          <Redirect
            to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.ONLINE}`}
          />
        ),
        Layout: DashboardLayout,
        routes: {
          ONLINE: {
            path: ROUTE_PATHS.ONLINE,
            type: RouteType.AUTH,
            icon: <LanguageOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            component: <ElectricityOnlinePage />,
            Layout: DashboardLayout,
          },
          DASHBOARD: {
            path: ROUTE_PATHS.DASHBOARD,
            type: RouteType.AUTH,
            icon: <DashboardOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            component: <ElectricityDashboardPage />,
            Layout: DashboardLayout,
          },
          WORKSHOP_OVERVIEW: {
            path: ROUTE_PATHS.WORKSHOP_OVERVIEW,
            type: RouteType.AUTH,
            icon: <DashboardOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            component: <ElectricityWorkshopPage />,
            Layout: DashboardLayout,
          },
          READINGS: {
            path: ROUTE_PATHS.READINGS,
            type: RouteType.AUTH,
            icon: <SpeedOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            component: <ElectricityReadingsPage />,
            Layout: DashboardLayout,
          },
          SANKEY: {
            path: ROUTE_PATHS.SANKEY,
            type: RouteType.AUTH,
            icon: <FilterAltIcon sx={{ color: COLORS.purple.main }} />,
            component: <SankeyPage />,
            Layout: DashboardLayout,
          },
          POWER_FACTOR: {
            path: ROUTE_PATHS.POWER_FACTOR,
            type: RouteType.AUTH,
            icon: <Typography sx={{ color: COLORS.purple.main, fontSize: '24px', lineHeight: '24px', mt: '-8px', ml: '2px' }} >φ</Typography>,
            component: <PowerFactorPage />,
            Layout: DashboardLayout,
          },
          HISTORICAL_ODOMETERS: {
            path: ROUTE_PATHS.HISTORICAL_ODOMETERS,
            type: RouteType.AUTH,
            icon: <HistoryIcon sx={{ color: COLORS.purple.main }} />,
            component: <ElectricityOdometersHistoryPage />,
            Layout: DashboardLayout,
          },
          ALARMS_HISTORY: {
            path: ROUTE_PATHS.ALARMS_HISTORY,
            type: RouteType.AUTH,
            component: <ElectricityAlarmHistory />,
            Layout: DashboardLayout,
            icon: <WarningAmberIcon sx={{ color: COLORS.purple.main }} />,
          },
        },
      },
      WATER: {
        path: ROUTE_PATHS.WATER,
        type: RouteType.AUTH,
        icon: <InvertColorsOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: (
          <Redirect
            to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.WATER}${ROUTE_PATHS.ONLINE}`}
          />
        ),
        Layout: DashboardLayout,
        routes: {
          ONLINE: {
            path: ROUTE_PATHS.ONLINE,
            type: RouteType.AUTH,
            icon: <LanguageOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            Layout: DashboardLayout,
            component: <WaterOnlinePage />,
          },
          WATER_READINGS: {
            path: ROUTE_PATHS.WATER_READINGS,
            type: RouteType.AUTH,
            icon: <SpeedOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            Layout: DashboardLayout,
            component: <WaterReadingsPage />,
          },
        },
      },
      GAS: {
        path: ROUTE_PATHS.GAS,
        type: RouteType.AUTH,
        icon: (
          <LocalFireDepartmentOutlinedIcon sx={{ color: COLORS.purple.main }} />
        ),
        component: (
          <Redirect
            to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.GAS}${ROUTE_PATHS.ONLINE}`}
          />
        ),
        Layout: DashboardLayout,
        routes: {
          ONLINE: {
            path: ROUTE_PATHS.ONLINE,
            type: RouteType.AUTH,
            icon: <LanguageOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            Layout: DashboardLayout,
            component: <GasOnlinePage />,
          },
          GAS_READINGS: {
            path: ROUTE_PATHS.GAS_READINGS,
            type: RouteType.AUTH,
            icon: <SpeedOutlinedIcon sx={{ color: COLORS.purple.main }} />,
            Layout: DashboardLayout,
            component: <GasReadingsPage />,
          }
        },
      },
    },
  },
  // ENVIRONMENT: {
  //   path: ROUTE_PATHS.ENVIRONMENT,
  //   type: RouteType.AUTH,
  //   Layout: DashboardLayout,
  //   isRoot: true,
  // },
  SETTINGS: {
    path: ROUTE_PATHS.SETTINGS,
    type: RouteType.AUTH,
    icon: <SettingsOutlinedIcon sx={{ color: COLORS.purple.main }} />,
    Layout: DashboardLayout,
    component: (
      <Redirect to={`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.BRANCH_OFFICES}`} />
    ),
    isMenuHidden: 'desktop',
    isRoot: true,
    routes: {
      BRANCH_OFFICES: {
        path: ROUTE_PATHS.BRANCH_OFFICES,
        type: RouteType.AUTH,
        icon: <FactoryOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsBranchOfficePage />,
        Layout: DashboardLayout,
      },
      WORKSHOPS: {
        path: ROUTE_PATHS.WORKSHOPS,
        type: RouteType.AUTH,
        icon: <WarehouseOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsWorkshopPage />,
        Layout: DashboardLayout,
      },
      CENTERS: {
        path: ROUTE_PATHS.CENTERS,
        type: RouteType.AUTH,
        icon: <WorkspacesOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsCentersPage />,
        Layout: DashboardLayout,
      },
      GROUPS: {
        path: ROUTE_PATHS.GROUPS,
        type: RouteType.AUTH,
        icon: <GroupWorkOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsGroupPage />,
        Layout: DashboardLayout,
      },
      ODOMETERS: {
        path: ROUTE_PATHS.ODOMETERS,
        type: RouteType.AUTH,
        icon: <SpeedOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsOdometersPage />,
        Layout: DashboardLayout,
      },
      POWER_FACTORS: {
        path: ROUTE_PATHS.POWER_FACTORS,
        type: RouteType.AUTH,
        icon: <Typography sx={{ color: COLORS.purple.main, fontSize: '24px', lineHeight: '24px', mt: '-8px', ml: '2px' }} >φ</Typography>,
        component: <SettingsPowerFactorPage />,
        Layout: DashboardLayout,
      },
      ALARMS: {
        path: ROUTE_PATHS.ALARMS,
        type: RouteType.AUTH,
        icon: (
          <AccessAlarmOutlinedIcon sx={{ color: COLORS.purple.main }} />
        ),
        component: <ElectricityAlarmPage />,
        Layout: DashboardLayout,
      },
      USERS: {
        path: ROUTE_PATHS.USERS,
        type: RouteType.AUTH,
        icon: <PersonOutlineOutlinedIcon sx={{ color: COLORS.purple.main }} />,
        component: <SettingsUsersPage />,
        Layout: DashboardLayout,
      },
      ODOMETER_CREATE: {
        path: ROUTE_PATHS.ODOMETER_CREATE,
        type: RouteType.AUTH,
        component: <SettingsOdometerCreatePage />,
        Layout: DashboardLayout,
        isMenuHidden: true,
      },
      ODOMETER_EDIT: {
        path: ROUTE_PATHS.ODOMETER_EDIT,
        type: RouteType.AUTH,
        component: <SettingsOdometerEditPage />,
        Layout: DashboardLayout,
        isMenuHidden: true,
      },
      // FILTERS: {
      //   path: ROUTE_PATHS.FILTERS,
      //   type: RouteType.AUTH,
      //   icon: <FilterAltOutlinedIcon sx={{ color: COLORS.purple.main }} />,
      //   component: <SettingsFiltersPage />,
      //   Layout: DashboardLayout,
      // },
      // SANITIZERS: {
      //   path: ROUTE_PATHS.SANITIZERS,
      //   type: RouteType.AUTH,
      //   icon: <FilterListOutlinedIcon sx={{ color: COLORS.purple.main }} />,
      //   component: <SettingsSanitizerPage />,
      //   Layout: DashboardLayout,
      // },
      // PROCESSOR_CONFIGS: {
      //   path: ROUTE_PATHS.PROCESSOR_CONFIGS,
      //   type: RouteType.AUTH,
      //   icon: <ManageHistoryOutlinedIcon sx={{ color: COLORS.purple.main }} />,
      //   component: <SettingsProcessorConfigPage />,
      //   Layout: DashboardLayout,
      // },
    },
  },
} as const satisfies Routes;

/* ------------------------------------------------------ */

const menus = (user: UserDto | null) => {
  const constructMenus = (routes: Routes, rootPath?: string) => {
    return Object.values(routes).reduce<Record<RouteType, Menu>>(
      (acc, { path, type, icon, routes, isDisabled, isMenuHidden, isRoot }) => {
        if (isDisabled) {
          return acc;
        }

        const fullPath = `${rootPath ?? ''}${path}`;

        if (
          (
            fullPath === `${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.USERS}` ||
              fullPath.includes(ROUTE_PATHS.SETTINGS) ||
            fullPath === `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.DASHBOARD}` ||
            fullPath === `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.HISTORICAL_ODOMETERS}` ||
            fullPath === `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.SANKEY}` ||
            fullPath === `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.ALARMS_HISTORY}` ||
            fullPath === `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ALARMS}`
          ) &&
          user?.role !== UserRequestDto.role.ROLE_ADMIN
        ) {
          return acc;
        }

        /**
         * If the route type is DEFAULT, add the route to all menus
         */
        [
          ...(type === RouteType.DEFAULT ? Object.values(RouteType) : [type]),
        ].map((type) => {
          acc[type][fullPath] = {
            name: fullPath.split('/').at(-1) ?? '',
            icon,
            isMenuHidden,
            isRoot,
          };

          if (routes !== undefined) {
            acc[type][fullPath].menu = constructMenus(routes, fullPath)[type];
          }
        });

        return acc;
      },
      {
        [RouteType.AUTH]: {},
        [RouteType.UNAUTH]: {},
        [RouteType.DEFAULT]: {},
      }
    );
  };

  return constructMenus(ROUTES);
};

/* ------------------------------------------------------ */

const tree = (user: UserDto | null) => {
  const constructTree = (routes: Routes, rootPath?: string) => {
    return Object.values(routes).reduce(
      (acc, { path, type, Layout, component, routes, isDisabled }) => {
        if (isDisabled) {
          return acc;
        }

        const fullPath = `${rootPath ?? ''}${path}`;

        const ResolvedLayout = Layout ?? DefaultLayout;
        const resolvedComponent = component ?? <UnderConstructionPage />;

        switch (type) {
          case RouteType.AUTH:
            acc[type].set(ResolvedLayout, [
              ...(acc[type].get(ResolvedLayout) ?? []),
              <AuthRoute key={fullPath} path={fullPath}>
                {resolvedComponent}
              </AuthRoute>,
            ]);
            break;
          case RouteType.UNAUTH:
            acc[type].set(ResolvedLayout, [
              ...(acc[type].get(ResolvedLayout) ?? []),
              <UnAuthRoute key={fullPath} path={fullPath}>
                {resolvedComponent}
              </UnAuthRoute>,
            ]);
            break;
          case RouteType.DEFAULT:
            acc[type].set(ResolvedLayout, [
              ...(acc[type].get(ResolvedLayout) ?? []),
              <Route key={fullPath} path={fullPath}>
                {resolvedComponent}
              </Route>,
            ]);
            break;
        }

        if (routes !== undefined) {
          Object.entries(constructTree(routes, fullPath)).map(([type, map]) => {
            for (const [Layout, tree] of map) {
              acc[type].set(Layout, [
                ...(acc[type].get(Layout) ?? []),
                ...tree,
              ]);
            }
          });
        }

        return acc;
      },
      {
        [RouteType.AUTH]: new Map<FC<PropsWithChildren>, ReactElement[]>(),
        [RouteType.UNAUTH]: new Map<FC<PropsWithChildren>, ReactElement[]>(),
        [RouteType.DEFAULT]: new Map<FC<PropsWithChildren>, ReactElement[]>(),
      }
    );
  };

  return Object.entries(constructTree(ROUTES)).reduce<ReactElement[]>(
    (acc, [type, map]) => {
      for (const [Layout, tree] of map) {
        acc.push(
          <RoutesGroup key={type}>
            <MenuProvider menu={menus(user)[type]}>
              <Layout>{tree}</Layout>
            </MenuProvider>
          </RoutesGroup>
        );
      }

      return acc;
    },
    []
  );
};

/* ------------------------------------------------------ */

const Routes: FC = () => {
  const { user } = useContext(UserContext);

  return (
    <RoutesGroup
      page404={
        <MenuProvider menu={menus(user).default}>
          <DefaultLayout>
            <NotFoundPage />
          </DefaultLayout>
        </MenuProvider>
      }
    >
      {tree(user)}
    </RoutesGroup>
  );
};

export default Routes;
