import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
import ReactECharts from 'echarts-for-react';
import {OdometerConsumptionMultipleDto} from "@/_generatedApi";
import { add, format, subSeconds } from "date-fns";

const ElectricityQuaterPeakGraph: FC<{
  data: OdometerConsumptionMultipleDto;
  peakData: [Date,number][][];
  predictionData: [Date,number][];
  zoom: {start: number, end: number}
  setZoom: Dispatch<SetStateAction<{start: number, end: number}>>
}> = ({data, peakData, predictionData, zoom, setZoom}) => {

// prettier-ignore
  const option = useMemo(() => ({
    title: {
      text: '1/4 průběh',
      subtext: '',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const tooltipChunk = peakData?.[params[0].seriesIndex]?.[peakData[params[0].seriesIndex]?.length - 1]
        return tooltipChunk ? `${format(subSeconds(tooltipChunk[0], 900), 'HH:mm')}: ${Math.round(tooltipChunk[1] * 100) / 100} ${data.odometer.unit}`  : 'Predikce';
      }
    },
    legend: {
      top: 70,
    },
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true
    },
    dataZoom: [
      {
        type: 'slider',
        labelFormatter: '',
        start: zoom.start,
        end: zoom.end,
      },
      {
        type: 'inside',
        zoomLock: true,
      }
    ],
    xAxis: {
      type: 'time',
      boundaryGap: false,
    },
    yAxis: {
      boundaryGap: [0, '20%']
    },
    series: [
      ...peakData.map(chunk => {
        const hourSum = chunk[chunk.length - 1][1] * 4;
        return { // data
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: hourSum > data.odometer.maxHourlyValue ? 'red' : (hourSum > data.odometer.optimalConsumption ? 'orange' : '#4bcfa7'),
          width: 2,
          type: 'solid',
        },
        areaStyle: {
          color: hourSum > data.odometer.maxHourlyValue ? 'red' : (hourSum > data.odometer.optimalConsumption ? 'orange' : '#4bcfa7')
        },
        data: chunk.map(value => [value[0], Math.round(value[1] * 100) / 100]),
      }}),
      { // predikce
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: '#4bcfa7',
          width: 2,
          type: 'dashed'
        },
        areaStyle: {
          color: 'rgba(75, 207, 167, 0.1)'
        },
        data: predictionData.map(value => [value[0], Math.round(value[1] * 100) / 100]),
      },
      ...peakData.slice(0, peakData.length - 1).map(chunk => ({ // sikme cary
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'rgba(0, 0, 0, 0.87)',
          width: 2,
          type: 'solid',
        },
        areaStyle: {
          color: 'transparent'
        },
        data: [
          [chunk[0][0], 0],
          [chunk[chunk.length - 1][0], data.odometer.maxHourlyValue / 4]
        ]
      })),
      { // posledni sikmina
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'rgba(0, 0, 0, 0.87)',
          width: 2,
          type: 'solid',
        },
        areaStyle: {
          color: 'transparent'
        },
        data: [
          peakData.length > 0 ? [peakData[peakData.length - 1][0][0], 0] : [],
          predictionData.length > 0 ?
            [predictionData[predictionData.length - 1][0], data.odometer.maxHourlyValue / 4] :
            peakData.length > 0 ?
              [peakData[peakData.length - 1][peakData[peakData.length - 1].length - 1][0], data.odometer.maxHourlyValue / 4] :
              []
        ]
      },
      {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'orange',
          width: 2,
          type: 'dashed'
        },
        areaStyle: {
          color: 'transparent'
        },
        data: [...peakData, predictionData].flatMap(item => item.map(row => [row[0], data.odometer.optimalConsumption / 4]))
      },
      {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'red',
          width: 2,
          type: 'solid',
        },
        areaStyle: {
          color: 'transparent'
        },
        data: [...peakData, predictionData].flatMap(item => item.map(row => [row[0], data.odometer.maxHourlyValue / 4]))
      },
    ]
  }), [data, peakData, predictionData, zoom]);

  return (
    <ReactECharts style={{height: 350, width: '100%'}} option={option} notMerge={true} onChartReady={(chart) => {
      chart.on('dataZoom', (params: any) => setZoom({start: params.start, end: params.end}))
    }}/>
  );
};

export default ElectricityQuaterPeakGraph;
