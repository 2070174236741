import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';

import { OdometerConsumptionSingleDto, OdometerService } from '@/_generatedApi';
import { ElectricityDashboardsFilter } from '@/components/ui/filters/ElectricityDashboardFilters';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import formatDate, { dateToLocalISOString } from '@/utils/format';
import { chunkArrayBy } from '@/utils/functions';

type FormattedConsumption = {
  time: string;
  value: number;
};

const ElectricityIncrementalConsumptionGraph: FC<{
  odometerId: number;
  odometerOptimalConsumption: number;
  odometerMaxHourlyValue: number;
  filter: ElectricityDashboardsFilter;
}> = ({
  odometerId,
  odometerOptimalConsumption,
  odometerMaxHourlyValue,
  filter,
}) => {
  const { t, i18n } = useTranslation();
  const { showGenericErrorToast } = useShowToast();
  const [odometerConsumption, setOdometerConsumption] =
    useState<OdometerConsumptionSingleDto>();

  const consumptionEndDate = new Date();
  const consumptionStartDate = new Date(
    consumptionEndDate.getTime() - 60 * 60 * 1000
  );

  const fetchOdometerConsumption = useCallback(async () => {
    try {
      const data = (
        await OdometerService.getOdometerConsumptionSingle({
          id: String(odometerId),
          consumptionStartDate: dateToLocalISOString(consumptionStartDate),
          consumptionEndDate: dateToLocalISOString(consumptionEndDate),
          consumptionUnit: '1 minute',
          take: MAX_TAKE_ITEMS,
          skip: 0,
        })
      ).data;

      if (data) {
        setOdometerConsumption(data);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [filter, showGenericErrorToast]);

  useEffect(() => {
    fetchOdometerConsumption();
  }, [fetchOdometerConsumption, filter, showGenericErrorToast]);

  const batchedHourlyConsumptions = useMemo(() => {
    const series = chunkArrayBy(
      odometerConsumption?.consumptionData || [],
      15
    ).slice(0, 4);

    let lastObj: FormattedConsumption | null = null;

    return series.map((batch, index) => {
      const filteredBatch: FormattedConsumption[] = batch
        .filter((_consumption, index) => index % 5 === 0)
        .map((consumption) => {
          return {
            time: formatDate(
              new Date(consumption.toDate),
              'hh:mm',
              i18n.language
            ),
            value: consumption.value,
          };
        })
        .slice(0, 4);

      if (index === 0) {
        const firstObjDate = new Date(batch[0].toDate);

        firstObjDate.setMinutes(firstObjDate.getMinutes() - 5);
        filteredBatch.unshift({
          time: formatDate(firstObjDate, 'hh:mm', i18n.language),
          value: 0,
        });
      } else if (lastObj && filteredBatch.length) {
        filteredBatch.unshift({ ...lastObj, value: 0 });
      }

      if (filteredBatch.length) {
        lastObj = { ...filteredBatch[filteredBatch.length - 1] };
      }

      return filteredBatch;
    });
  }, [i18n.language, odometerConsumption]);

  const graphConsumptionSeries = useMemo(() => {
    return batchedHourlyConsumptions.map((batchedHourlyConsumption) => {
      return [
        {
          data: batchedHourlyConsumption.map((consumption) => [
            consumption.time,
            consumption.value,
          ]),
          areaStyle: {
            color: '#4ADEDE',
            opacity: 0.25,
            origin: 'start',
          },
          type: 'line',
          smooth: true,
        },
        {
          data: [
            [batchedHourlyConsumption[0].time, 0],
            [
              [...batchedHourlyConsumption].pop()?.time,
              odometerOptimalConsumption,
            ],
          ],
          lineStyle: { color: 'black' },
          type: 'line',
          smooth: true,
        },
      ];
    });
  }, [batchedHourlyConsumptions]);

  const options = {
    grid: { top: 40, right: 20, bottom: 80, left: 60 },
    xAxis: [
      {
        boundaryGap: false,
        data: Array.from(
          new Set(
            batchedHourlyConsumptions
              .flat()
              .map((formattedConsumption) => formattedConsumption.time)
          )
        ),
      },
      {
        position: 'bottom',
        offset: 30,
        data: batchedHourlyConsumptions.map((_) =>
          t('common.minutesShort', { value: 15 })
        ),
      },
    ],
    yAxis: {
      type: 'value',
      name: 'W',
      position: 'left',
    },
    visualMap: [
      {
        pieces: [
          {
            gte: odometerMaxHourlyValue,
            label: `>= ${odometerMaxHourlyValue}`,
            color: 'red',
          },
          {
            lt: odometerMaxHourlyValue,
            gt: 0,
            label: `< ${odometerMaxHourlyValue}`,
            color: '#4ADEDE',
          },
        ],
      },
    ],
    series: [
      ...graphConsumptionSeries.flat(),
      {
        data: [
          [batchedHourlyConsumptions[0]?.[0]?.time, odometerMaxHourlyValue],
          [
            batchedHourlyConsumptions[batchedHourlyConsumptions.length - 1]?.[
              batchedHourlyConsumptions[batchedHourlyConsumptions.length - 1]
                ?.length - 1
            ]?.time,
            odometerMaxHourlyValue,
          ],
        ],
        lineStyle: { color: 'red' },
        showSymbol: false,
        type: 'line',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <ReactECharts
      style={{ height: 250, width: '100%' }}
      option={options}
      onEvents={{
        ready: () => {},
      }}
    />
  );
};

export default ElectricityIncrementalConsumptionGraph;
