import {FC, useEffect, useState} from "react";
import {fabric} from "fabric";
import {FabricJSCanvas, FabricJSEditor, useFabricJSEditor} from "fabricjs-react";
import classNames from "classnames";
import styles from './SettingsWorkshopPlan.module.css';
import Button from "@mui/material/Button";
import {Polygon} from "fabric/fabric-impl";
import {useOdometers} from "@/hooks/data/use-odometers";
import Select from "@/components/ui/fields/Select";

function polygonPositionHandler(this: any, dim: any, finalMatrix: any, fabricObject: any) {
  var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
  return fabric.util.transformPoint(
    new fabric.Point(x, y),
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
}

function getObjectSizeWithStroke(object: fabric.Object) {
  var stroke = new fabric.Point(
    object.strokeUniform ? 1 / (object.scaleX || 1) : 1,
    object.strokeUniform ? 1 / (object.scaleY || 1) : 1
  ).multiply(object.strokeWidth || 1);
  return new fabric.Point((object.width || 1) + stroke.x, (object.height || 1) + stroke.y);
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
function actionHandler(eventData: any, transform: any, x: number, y: number) {
  var polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
    polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
      y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
  if (polygon.points.length === 2) {
    const otherPointIndex = 1 - currentControl.pointIndex;
    const otherPoint = polygon.points[otherPointIndex];
    if (Math.abs(finalPointPosition.x - otherPoint.x) < 5) {
      finalPointPosition.x = otherPoint.x;
    } else if (Math.abs(finalPointPosition.y - otherPoint.y) < 5) {
      finalPointPosition.y = otherPoint.y;
    }
  }
  finalPointPosition.x = Math.round(finalPointPosition.x / 5) * 5;
  finalPointPosition.y = Math.round(finalPointPosition.y / 5) * 5;
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex: any, fn: any) {
  return function (eventData: any, transform: any, x: number, y: number) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint(new fabric.Point(
        (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
        (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y)
      ), fabricObject.calcTransformMatrix()),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = getObjectSizeWithStroke(fabricObject),
      newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
      newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  }
}

export type SettingsWorkshopPlanProps = {
  editor: FabricJSEditor | undefined;
  selectedObjects: fabric.Object[];
  onReady: (canvas: fabric.Canvas) => void;
  initialValue: string;
}

export const SettingsWorkshopPlan: FC<SettingsWorkshopPlanProps> = ({
                                                                      editor,
                                                                      selectedObjects,
                                                                      onReady,
                                                                      initialValue
                                                                    }) => {

  const onAddLine = () => {
    var points = [{
      x: 5, y: 5
    }, {
      x: 30, y: 5
    }]

    var polygon = new fabric.Polygon(points, {
      left: 100,
      top: 50,
      fill: 'transparent',
      strokeWidth: 1,
      stroke: 'black',
      scaleX: 4,
      scaleY: 4,
      objectCaching: false,
      transparentCorners: false,
      cornerColor: 'black',
    });

    var lastControl = (polygon.points?.length || 1) - 1;
    polygon.cornerStyle = 'circle';
    polygon.cornerColor = 'rgba(0,0,255,0.5)';
    polygon.controls = polygon.points ? polygon.points.reduce(function (acc: any, point, index) {
      acc['p' + index] = new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
        actionName: 'modifyPolygon',
        pointIndex: index
      } as any);
      return acc;
    }, {}) : {};

    editor?.canvas?.add(polygon);
    editor?.canvas?.setActiveObject(polygon);
  }

  const onAddOdometer = () => {
    var rect = new fabric.Rect({
      left: 100,
      top: 50,
      fill: 'yellow',
      width: 200,
      height: 100,
      objectCaching: false,
      stroke: 'lightgreen',
      strokeWidth: 4,
      hasControls: false,
      data: {
        type: 'odometer',
        value: ''
      }
    });

    editor?.canvas?.add(rect);
    editor?.canvas?.setActiveObject(rect);
  }

  const deleteSelected = () => {
    if (selectedObjects && selectedObjects?.length > 0) {
      editor?.canvas?.remove(selectedObjects[0]);
    }
  }

  useEffect(() => {
    editor?.canvas?.loadFromJSON(JSON.parse(initialValue), () => {
      editor?.canvas?.renderAll();
      editor?.canvas?.forEachObject((object) => {
        if (object.type === 'polygon') {
          const polygon = object as any as Polygon;
          var lastControl = (polygon.points?.length || 1) - 1;
          polygon.cornerStyle = 'circle';
          polygon.cornerColor = 'rgba(0,0,255,0.5)';
          polygon.controls = polygon.points ? polygon.points.reduce(function (acc: any, point, index) {
            acc['p' + index] = new fabric.Control({
              positionHandler: polygonPositionHandler,
              actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
              actionName: 'modifyPolygon',
              pointIndex: index
            } as any);
            return acc;
          }, {}) : {};
        }
      })
    });
  }, [initialValue, editor?.canvas]);

  const {odometersOptions} = useOdometers();

  let [selectedOdometer, setSelectedOdometer] = useState(selectedObjects?.[0]?.data?.value);

  useEffect(() => {
    setSelectedOdometer(selectedObjects?.[0]?.data?.value);
  }, [selectedObjects]);

  return (
    <div>
      <div style={{height: 48}}>
        <Button sx={{mr: 1}} variant="contained" onClick={() => onAddLine()}>Přidat linii</Button>
        <Button sx={{mr: 1}} variant="contained" onClick={() => onAddOdometer()}>Přidat měřidlo</Button>
        {
          selectedObjects && selectedObjects?.length > 0 &&
            <>
                <Button sx={{mr: 1}} variant="contained" onClick={() => selectedObjects?.[0].bringToFront()}>+</Button>
                <Button sx={{mr: 1}} variant="contained" onClick={() => selectedObjects?.[0].sendToBack()}>-</Button>
                <Button sx={{mr: 1}} variant="contained" onClick={() => deleteSelected()}>Smazat</Button>
            </>
        }
        {
          selectedObjects?.[0]?.data?.type == 'odometer' &&
            <Select
                options={odometersOptions}
                value={selectedOdometer}
                onChange={(value) => {
                  if (selectedObjects && selectedObjects.length > 0) {
                    selectedObjects[0].data.value = value;
                    setSelectedOdometer(value);
                  }
                }}
                label="Měřidlo"
                clearable
                error=""
                name=""
                onBlur={() => {}}
                width={300}
            />
        }
      </div>

      <FabricJSCanvas className={classNames(styles.canvas)} onReady={onReady}/>
    </div>
  );
};
