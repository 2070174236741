import { createRoot } from 'react-dom/client';

import './i18n/i18n';

import App from './App';

import '@/scss/main.scss';

const rootEl = document.getElementById('root');

if (rootEl) {
  const root = createRoot(rootEl);
  root.render(<App />);
}
