import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

import { OdometerDto, OdometerService } from '@/_generatedApi';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import OdometersFilters, {
  OdometerFilter,
} from '@/components/ui/filters/OdometersFilters';
import { ITEMS_PER_PAGE } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';
import { updateFilterData } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';

const fetchOdometersForPage = async (
  page: number,
  itemsPerPage: number,
  filter: OdometerFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return await OdometerService.getOdometerList({
    sortBy: 'name',
    descending: false,
    deleted: false,
    skip,
    take: itemsPerPage,
    ...filter,
  });
};

const SettingsOdometersPage: FC = () => {
  const { t } = useTranslation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const [countOfOdometers, setCountOfOdometers] = useState(0);
  const [odometers, setOdometers] = useState<OdometerDto[]>([]);
  const [_, setLocation] = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<OdometerFilter>({});
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);

  const odometersColumnsDef: ColDef<OdometerDto>[] = [
    { key: 'name', nested: false, isDate: false, sortable: true },
    { key: 'medium', nested: true, isDate: false, sortable: true },
    { key: 'type', nested: true, isDate: false, sortable: true },
    { key: 'installationDate', nested: false, isDate: true, sortable: true },
    { key: 'branchOffice', nested: true, isDate: false, sortable: true },
    { key: 'workshop', nested: true, isDate: false, sortable: true },
    { key: 'center', nested: true, isDate: false, sortable: true },
    { key: 'group', nested: true, isDate: false, sortable: true },
    { key: 'externalId', nested: false, isDate: false, sortable: false },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchOdometersForPage(
        currentPage,
        rowsPerPage,
        filter
      );
      setOdometers(filteredData.data as OdometerDto[]);
      setCountOfOdometers(filteredData.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, filter, rowsPerPage, showGenericErrorToast]);

  const handleAddOdometer = () => {
    setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETER_CREATE}`);
  };

  const handleEditOdometer = (odometer: OdometerDto) => {
    if (!odometer) return;
    setLocation(
      `${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}/${odometer.id}/edit`
    );
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleDeleteOdometer = async (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!id) return;

    try {
      await OdometerService.deleteOdometerDelete({ id });

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));

      if (odometers.length === 1 && currentPage !== 1) {
        setCurrentPage((page) => page - 1);
      }

      fetchData();
      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  return (
    <CDataGrid
      colDef={odometersColumnsDef}
      translationKey="odometersSettingsPage"
      data={odometers}
      onAdd={handleAddOdometer}
      onDelete={handleDeleteOdometer}
      onEdit={(values) => handleEditOdometer(values as OdometerDto)}
      filters={<OdometersFilters updateFilter={updateFilter} filter={filter} />}
      changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
      onSort={(sortModel) => {
        setFilter((filter) => ({
          ...filter,
          sortBy: sortModel.sortBy,
          descending: sortModel.descending,
        }));
      }}
      pagination={{
        onPageChange: handleChangePage,
        totalCount: countOfOdometers,
        currentPage,
        pageSize: rowsPerPage,
      }}
    />
  );
};
export default SettingsOdometersPage;
