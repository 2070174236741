import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material';
import classNames from 'classnames';

import {ReactComponent as EnergyIcon} from '@/img/icons/energy.svg';
import {ReactComponent as GasIcon} from '@/img/icons/gas.svg';
import {ReactComponent as WastesIcon} from '@/img/icons/wastes.svg';
import {ReactComponent as WaterIcon} from '@/img/icons/water.svg';
import {prettyFormatNumber} from '@/utils/format';

import Budge from '../ui/budge/Budge';

import styles from './consumptionListing.module.css';
import {ConsumptionDto, MediumHeaderDto} from "@/_generatedApi";
import { Medium } from "@/constants/mediums";
import { prettyUnit } from "@/utils/formatString";

type ConsumptionListingItemProps = {
  value: ConsumptionByMedium;
};

const ConsumptionListingItem: FC<ConsumptionListingItemProps> = ({
                                                                   value,
                                                                 }) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation();

  const content = useMemo(() => {
    switch (value.medium.type) {
      case Medium.electricity:
        return {
          icon: <EnergyIcon/>,
          budge: (
            <Budge
              text={t('dashboardPage.electricity')}
              color={theme.palette.turquoise.main}
            />
          ),
        };
      case Medium.gas:
        return {
          icon: <GasIcon/>,
          budge: (
            <Budge
              text={t('dashboardPage.gas')}
              color={theme.palette.yellow.main}
            />
          ),
        };
      case Medium.water:
        return {
          icon: <WaterIcon/>,
          budge: (
            <Budge
              text={t('dashboardPage.water')}
              color={theme.palette.blue.main}
            />
          ),
        };
      case 'wastes':
        return {
          icon: <WastesIcon/>,
          budge: (
            <Budge
              text={t('dashboardPage.wastes')}
              color={theme.palette.secondary.main}
            />
          ),
        };
    }
  }, [theme, value, t]);

  return (
    <div className={classNames(styles.item)}>
      {content?.icon}
      <div>
        {content?.budge}
        {
          value.data.map(item => (
            <div className={classNames(styles.container)} key={item.unit}>
              <span className={classNames(styles.consumptionAmountItem)}>
                {prettyFormatNumber(item.consumption, i18n.language)}
              </span>
              <span className={classNames(styles.consumptionAmountUnit)}>
                {prettyUnit(item.unit)}
              </span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

interface ConsumptionByMedium {
  data: ConsumptionDto[],
  medium: MediumHeaderDto;
}

const ConsumptionListing: FC<{
  consumptions: ConsumptionDto[];
}> = ({consumptions}) => {
  const consumptionByMedium = consumptions.reduce((acc: ConsumptionByMedium[], consumption) => {
    const item = acc.find(item => item.medium.id === consumption.medium.id);
    if (item) {
      item.data.push(consumption)
    } else {
      acc.push({medium: consumption.medium, data: [consumption]})
    }
    return acc;
  }, []);

  return (
    <div className={classNames(styles.budge)}>
      {(consumptionByMedium || []).map((consumption) => {
        return (
          <ConsumptionListingItem
            key={consumption.medium.id}
            value={consumption}
          />
        )
      })}
    </div>
  );
};

export default ConsumptionListing;
