/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OdometerTypeDto } from '../models/OdometerTypeDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OdometerTypeService {
  /**
   * @returns any Odometer type list.
   * @throws ApiError
   */
  public static getOdometerTypeList({
    take,
    skip,
    deleted,
    name,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    name?: string;
    sortBy?: 'id' | 'name';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<OdometerTypeDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer-type/',
      query: {
        deleted: deleted,
        name: name,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postOdometerTypeCreate({
    requestBody,
  }: {
    /**
     * Create new odometer type
     */
    requestBody: OdometerTypeDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerTypeDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/odometer-type/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerTypeFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerTypeDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer-type/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `medium not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putOdometerTypeUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update odometer type
     */
    requestBody: OdometerTypeDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerTypeDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/odometer-type/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Odometer type not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteOdometerTypeDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/odometer-type/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Odometer type not found`,
      },
    });
  }
}
