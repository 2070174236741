import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import { OdometerDto, OdometerService } from '@/_generatedApi';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import OdometersFilters, {
  OdometerFilter,
} from '@/components/ui/filters/OdometersFilters';
import { ITEMS_PER_PAGE } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';
import { updateFilterData } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';

const fetchOdometersForPage = async (
  page: number,
  itemsPerPage: number,
  filter: OdometerFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return OdometerService.getOdometerList({
    deleted: true,
    skip,
    take: itemsPerPage,
    ...filter,
  });
};

const ElectricityOdometersHistoryPage: FC = () => {
  const [, setLocation] = useLocation();
  const [countOfOdometers, setCountOfOdometers] = useState(0);
  const [odometers, setOdometers] = useState<OdometerDto[]>([]);
  const { showGenericErrorToast } = useShowToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [filter, setFilter] = useState<OdometerFilter>({});

  const odometersColumnsDef: ColDef<OdometerDto>[] = [
    { key: 'name', nested: false, isDate: false, sortable: true },
    { key: 'medium', nested: true, isDate: false, sortable: true },
    { key: 'type', nested: true, isDate: false, sortable: true },
    { key: 'installationDate', nested: false, isDate: true, sortable: true },
    { key: 'deletedAt', nested: false, isDate: true, sortable: true },
    { key: 'branchOffice', nested: true, isDate: false, sortable: true },
    { key: 'workshop', nested: true, isDate: false, sortable: true },
    { key: 'center', nested: true, isDate: false, sortable: true },
    { key: 'note', nested: false, isDate: false, sortable: false },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchOdometersForPage(
        currentPage,
        rowsPerPage,
        filter
      );
      setOdometers(filteredData.data as OdometerDto[]);
      setCountOfOdometers(filteredData.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, filter, rowsPerPage, showGenericErrorToast]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  const onDetail = (id: number) => {
    setLocation(
      `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.HISTORICAL_ODOMETERS}/${id}`
    );
  };

  return (
    <CDataGrid
      colDef={odometersColumnsDef}
      translationKey="electricityPage.electricityOdometersHistoryPage"
      data={odometers}
      filters={<OdometersFilters updateFilter={updateFilter} filter={filter} />}
      onDetail={onDetail}
      changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
      onSort={(sortModel) => {
        setFilter((filter) => ({
          ...filter,
          sortBy: sortModel.sortBy,
          descending: sortModel.descending,
        }));
      }}
      pagination={{
        onPageChange: handleChangePage,
        totalCount: countOfOdometers,
        currentPage,
        pageSize: rowsPerPage,
      }}
    />
  );
};
export default ElectricityOdometersHistoryPage;
