/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumptionDto } from '../models/ConsumptionDto';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnergyConsumptionService {
  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getEnergyConsumptionThisMonth(): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<ConsumptionDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/energy/consumption/this-month',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getEnergyConsumptionDataHistogramByUnit(): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<ConsumptionDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/energy/consumption/data-histogram-by-unit',
    });
  }
}
