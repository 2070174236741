import {
  Children,
  cloneElement,
  CSSProperties,
  FC,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { useTheme } from '@mui/system';

import { AnyFunction } from '@/types/core-types';

import WithLink from '../../../routing/WithLink';

const getColor = (variant?: 'outlined' | 'contained' | 'destructive') => {
  const colorMap = {
    outlined: 'primary',
    contained: 'secondary',
    destructive: 'error',
  } as const;

  return variant ? colorMap[variant] : 'primary';
};

const CIconButton: FC<
  PropsWithChildren<{
    type?: 'button' | 'submit' | 'reset';
    size?: 'small' | 'medium' | 'large';
    variant?: 'outlined' | 'contained' | 'destructive';
    href?: string;
    onClick?: AnyFunction;
    target?: '_blank';
    disabled?: boolean;
    style?: CSSProperties;
  }>
> = ({
  children,
  type = 'button',
  size = 'medium',
  variant = 'outlined',
  href,
  ...rest
}) => {
  const theme = useTheme();

  const childrenWithProps = Children.map(children, (child) =>
    cloneElement(child as ReactElement, {
      sx: { color: theme.palette[getColor(variant)]?.main },
    })
  );

  return (
    <WithLink href={href}>
      <MuiIconButton
        type={type}
        size={size}
        color={getColor(variant)}
        {...rest}
      >
        {childrenWithProps}
      </MuiIconButton>
    </WithLink>
  );
};

export default CIconButton;
