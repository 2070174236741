import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Link, useLocation } from 'wouter';
import * as yup from 'yup';

import { AuthService } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import InfoBox from '@/components/ui/info-box/InfoBox';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';

const validationSchema = yup.object({
  email: yup.string().email().required('validation.required'),
});

export type ForgotPasswordValues = yup.InferType<typeof validationSchema>;

const initialValues = {
  email: '',
} satisfies ForgotPasswordValues;

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { showGenericErrorToast, showToast } = useShowToast();

  const handleSubmit = async (values: ForgotPasswordValues) => {
    try {
      await AuthService.postResetPasswordEmail({
        requestBody: values,
      });

      showToast('success', t('notifications.requestForResetPasswordWasSent'));
      setLocation(ROUTE_PATHS.LOGIN);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        gap: '24px',
        margin: 'auto',
        flexDirection: 'column',
      }}
    >
      <InfoBox text={t('loginPage.forgotPasswordInfo')} />

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="email"
              render={(props) => (
                <Input
                  label={t('loginPage.emailAddress')}
                  {...props}
                  data-cy-field="email"
                  autoFocus
                />
              )}
            />
            <Button
              type="submit"
              size="large"
              variant="add"
              startIcon={null}
              disabled={isSubmitting}
              data-cy-button="sendLink"
              onMouseDown={(e) => e.preventDefault()}
            >
              {t('loginPage.sendLink')}
            </Button>
            <Link
              href={ROUTE_PATHS.LOGIN}
              style={{ color: 'black', fontSize: '14px', marginTop: '24px' }}
            >
              {t('forgotPasswordPage.backToLogin')}
            </Link>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ForgotPasswordPage;
