/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { ResetPasswordEmailRequest } from '../models/ResetPasswordEmailRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * @returns AuthResponse Login success
   * @throws ApiError
   */
  public static postLogin({
    requestBody,
  }: {
    requestBody: LoginRequest;
  }): CancelablePromise<AuthResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns boolean Reset email success
   * @throws ApiError
   */
  public static postResetPasswordEmail({
    requestBody,
  }: {
    requestBody: ResetPasswordEmailRequest;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reset-password-email',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Reset email failure`,
      },
    });
  }

  /**
   * @returns boolean Reset success
   * @throws ApiError
   */
  public static postResetPassword({
    requestBody,
  }: {
    requestBody: ResetPasswordRequest;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reset-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Reset failure`,
      },
    });
  }

  /**
   * @returns AuthResponse Login success
   * @throws ApiError
   */
  public static postChangePassword({
    requestBody,
  }: {
    /**
     * Old password is not required for non-confirmed users only.
     */
    requestBody: ChangePasswordRequest;
  }): CancelablePromise<AuthResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/change-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Change password failure`,
      },
    });
  }
}
