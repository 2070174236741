import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'grey.700',
    color: 'common.white',
    maxWidth: 255,
    padding: 6,
    fontSize: 12,
    '& *': {
      color: 'common.white',
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'grey.700',
  },
}));

const Tooltip: FC<{
  children: TooltipProps['children'];
  title: TooltipProps['title'];
  placement?: TooltipProps['placement'];
  style?: CSSProperties;
  interactive?: boolean;
}> = ({ children, title, placement, style, interactive }) => {
  return (
    <>
      {children?.props?.disabled ? (
        <div>{children}</div>
      ) : (
        <StyledTooltip
          title={title}
          placement={placement ?? 'bottom'}
          style={style}
          disableInteractive={!interactive}
          arrow
        >
          <div>{children}</div>
        </StyledTooltip>
      )}
    </>
  );
};

export default Tooltip;
