import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import classNames from 'classnames';
import {TFunction} from 'i18next';
import * as yup from 'yup';

import {OdometerMappingDto} from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CDatePicker from '../ui/common/date-picker/CDatePicker';

import styles from './SettingsOdometerForm.module.css';

const validationSchema = (t: TFunction) =>
  yup.object({
    deviceTechnicalName: yup.string().required('validation.required'),
    busTechnicalName: yup.string().required('validation.required'),
    validFrom: yup.date().required('validation.required'),
    externalId: yup.string().required('validation.required'),
    inputNumber: yup.number().required('validation.required'),
    coefficient: yup.number().required('validation.required'),
    maxMinuteIncrease: yup.number().required('validation.required'),
    initialValue: yup.number().required('validation.required'),
  });

export const odometerMappingValidationSchema = validationSchema;
export type SettingsOdometerMappingFormOutputValues = yup.InferType<ReturnType<typeof validationSchema>>;

export type SettingsOdometerMappingFormProps = {
  odometerMapping?: OdometerMappingDto;
  onSubmit: (values: SettingsOdometerMappingFormOutputValues) => void;
  onBack?: () => void;
  onCancel: () => void;
  type: number
};

const SettingsOdometerMappingForm: FC<SettingsOdometerMappingFormProps> = ({
                                                                             odometerMapping,
                                                                             onSubmit,
                                                                             onBack,
                                                                             onCancel,
  type
                                                                           }) => {
  const {t} = useTranslation();
  const dialogType = odometerMapping ? 'update' : 'add';

  const initialValues = {
    deviceTechnicalName: odometerMapping?.deviceTechnicalName,
    busTechnicalName: odometerMapping?.busTechnicalName,
    validFrom: odometerMapping?.validFrom
      ? new Date(odometerMapping?.validFrom as string)
      : null,
    externalId: odometerMapping?.externalId,
    inputNumber: odometerMapping?.inputNumber,
    coefficient: odometerMapping?.coefficient,
    maxMinuteIncrease: odometerMapping?.maxMinuteIncrease,
    initialValue: odometerMapping?.initialValue,
    previousValue: 0,
  };

  const handleSubmit = async (values: SettingsOdometerMappingFormOutputValues) => {
    onSubmit(values);
  };

  const [checked, setChecked] = useState(true);

  return (
    <Form
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({isSubmitting, resetForm}) => (
        <div style={{marginTop: 8, width: 375}} className={classNames(styles.column)}>
          <Field<string>
            name="deviceTechnicalName"
            render={(props) => (
              <Input
                label={t('odometersSettingsPage.technicalName')}
                {...props}
                disabled={type === -2}
                data-cy-field="deviceTechnicalName"
              />
            )}
          />
          <Field<string>
            name="busTechnicalName"
            render={(props) => (
              <Input
                label="Technický název sběrnice"
                {...props}
                disabled={type === -1}
                data-cy-field="busTechnicalName"
              />
            )}
          />
          <Field<Date>
            name="validFrom"
            render={(props) => {
              return (
                <CDatePicker
                  {...props}
                  required
                  label="Platnost od"
                  withTime={true}
                />
              );
            }}
          />
          <Field<string>
            name="externalId"
            render={(props) => (
              <Input
                label={t('odometersSettingsPage.externalId')}
                {...props}
                required
                disabled={type === -1}
                data-cy-field="externalId"
              />
            )}
          />
          <Field<number>
            name="inputNumber"
            render={(props) => (
              <Input
                type="number"
                label={t('odometersSettingsPage.inputNumber')}
                {...props}
                required
                disabled={type === -1}
                data-cy-field="inputNumber"
              />
            )}
          />
          <Field<number>
            name="coefficient"
            render={(props) => (
              <Input
                type="number"
                label={t('odometersSettingsPage.coefficient')}
                {...props}
                required
                disabled={type === -2}
                data-cy-field="coefficient"
              />
            )}
          />
          <Field<number>
            name="maxMinuteIncrease"
            render={(props) => (
              <Input
                type="number"
                label="Maximální minutový přírůstek"
                {...props}
                required
                disabled={type === -2}
                data-cy-field="maxMinuteIncrease"
              />
            )}
          />
          <Field<number>
            name="initialValue"
            render={(props) => (
              <Input
                type="number"
                label="Výchozí hodnota měřidla"
                {...props}
                required
                disabled={type === -2}
                data-cy-field="initialValue"
              />
            )}
          />

          <FormControlLabel control={<Checkbox defaultChecked disabled={type === -2} value={checked} onChange={() => setChecked(!checked)} />} label="Použít systémovou hodnotu předchozího měření" />

          {
            !checked &&
              <Field<number>
                  name="previousValue"
                  render={(props) => (
                    <Input
                      type="number"
                      label="Konečná hodnota předchozího měřidla"
                      {...props}
                      required
                      disabled={type === -2}
                      data-cy-field="previousValue"
                    />
                  )}
              />
          }


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              type="button"
              size="large"
              data-cy-button="cancel"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
            <Button
              disableElevation
              type="submit"
              size="large"
              disabled={isSubmitting}
              data-cy-button="submit"
              variant={dialogType}
              onMouseDown={(e) => e.preventDefault()}
            />
          </Box>
        </div>
      )}
    </Form>
  );
};

export default SettingsOdometerMappingForm;
