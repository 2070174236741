import { FC } from 'react';
import { styled, useTheme } from '@mui/system';

export type BadgeProps = {
  text: string;
};

const Badge: FC<BadgeProps> = ({ text }) => {
  const theme = useTheme();

  const Container = styled('div')(() => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.default,
    width: '100%',
    height: 'auto',
    fontSize: 14,
    borderRadius: '7px',
    padding: '12px 24px',
  }));

  return (
    <Container>
      <p>{text}</p>
    </Container>
  );
};

export default Badge;
