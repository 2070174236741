import { FC, MutableRefObject, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';

import { prettyFormatNumber } from '@/utils/format';

import style from './table.module.css';
import {ReadingsMultipleDto} from "@/_generatedApi";
import format from 'date-fns/format';
import {YearData} from "@/components/odometer-detail/OdometerDetail";
import { prettyUnit } from "@/utils/formatString";

const StyledTableCell = styled(TableCell)(() => {
  const theme = useTheme();
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  };
});

const StyledTableRow = styled(TableRow)(() => {
  const theme = useTheme();
  return {
    ['&:nth-of-type(even)']: {
      backgroundColor: theme.palette.background.default,
    },
    // hide last border
    ['&:last-child td, &:last-child th']: {
      border: 0,
    },
  };
});

const ElectricityReadingsTable: FC<{
  data: YearData;
  unit: string;
  scrollSyncRef: MutableRefObject<Map<number, HTMLElement | null>>
}> = ({ data, scrollSyncRef, unit }) => {
  const { t, i18n } = useTranslation();

  const consumptionSum = useMemo(() => {
    return data.data.reduce((prev, { consumption }) => prev + consumption, 0);
  }, [data]);

  return (
    <Box>
      <Typography variant="h6" component="span">
        {data.year}
      </Typography>
      <TableContainer
        sx={{ maxHeight: 450, maxWidth: 500 }}
        ref={ref => scrollSyncRef.current?.set(data.year, ref)}
        onScroll={scroll => scrollSyncRef.current?.forEach((element, key) => {
          if (key !== data.year && element && scroll.target instanceof HTMLElement) {
            element.scrollTop = scroll.target.scrollTop;
          }
        })}
        component={Paper}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 450 }}
          aria-label="Electricity readings table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                {t('electricityReadingsPage.gauge.detail.period')}
              </StyledTableCell>
              <StyledTableCell align="right">
                {t('electricityReadingsPage.gauge.detail.value')} [{prettyUnit(unit)}]
              </StyledTableCell>
              <StyledTableCell align="right">
                {t('electricityReadingsPage.gauge.detail.consumption')} [{prettyUnit(unit)}]
              </StyledTableCell>
              <StyledTableCell align="right" colSpan={2}>
                {t('electricityReadingsPage.gauge.detail.maxValueDate')}
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody sx={{whiteSpace: 'nowrap'}}>
            {(data.data || []).map((row) => (
              <StyledTableRow
                key={row.fromDate}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell>{format(new Date(row.fromDate), 'dd.MM.')} - {format(new Date(row.toDate), 'dd.MM.')}</StyledTableCell>
                <StyledTableCell align="right">
                  {prettyFormatNumber(row.finalValue, i18n.language)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {prettyFormatNumber(row.consumption, i18n.language)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.maxValueDate && format(new Date(row.maxValueDate), 'dd.MM. HH:mm')}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.maxValue && prettyFormatNumber(row.maxValue * 4, i18n.language)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <span className={classNames(style.tableFooter)}>
        {t('electricityReadingsPage.gauge.detail.consumptionPerYear', {
          value: prettyFormatNumber(consumptionSum, i18n.language),
          unit: unit
        })}
      </span>
    </Box>
  );
};

export default ElectricityReadingsTable;
