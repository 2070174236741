import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TranslateIcon from '@mui/icons-material/Translate';
import { Box, IconButton, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LangSwitcher from '@/components/lang-switcher/LangSwitcher';
import Show from '@/components/ui/control-flow/Show';
import Tooltip from '@/components/ui/tooltip/Tooltip';
import { DashboardLayoutContext } from '@/contexts/layout/DashboardLayoutContext';
import { useIsMobile } from '@/hooks/use-is-mobile';

import Avatar from './Avatar';
import DrawerButton from './DrawerButton';
import DrawerHead from './DrawerHead';
import SideNav from './SideNav';

const DrawerBody: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useIsMobile();

  const {
    open: [open, setOpen],
  } = useContext(DashboardLayoutContext);

  return (
    <>
      <DrawerHead>
        <Show when={isMobile}>
          <Avatar ableMenu={false} />
          <span />
          <Tooltip title={t('common.close')}>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Show>
      </DrawerHead>
      <Box
        data-cy="sidebarBody"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: theme.spacing(1),
          ...(!isMobile && {
            padding: theme.spacing(1, 0, 1),
            transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            ...(open && {
              padding: theme.spacing(1),
              transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }),
          }),
        }}
      >
        <SideNav />
        <List
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
          disablePadding
        >
          <Show when={isMobile || open}>
            <ListItem>
              <LangSwitcher />
            </ListItem>
          </Show>
          <Show when={!isMobile && !open}>
            <DrawerButton
              name="localization.changeLanguageAction"
              icon={<TranslateIcon />}
              onClick={() => setOpen(true)}
            />
          </Show>
        </List>
      </Box>
    </>
  );
};

export default DrawerBody;
