import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

import { OdometerConsumptionMultipleDto } from '@/_generatedApi';
import formatDate, { consumptionUnitsFormat } from '@/utils/format';

type SeriesData = {
  firstSeries: number[];
  secondSeries: number[];
};

const LargestConsumptionGraph: FC<{
  odometerEntity: OdometerConsumptionMultipleDto;
}> = ({ odometerEntity }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const calculateSeriesData = (data: number[], average: number): SeriesData => {
    return data.reduce<SeriesData>(
      (acc, value) => {
        if (value <= average) {
          return {
            firstSeries: [...acc.firstSeries, value],
            secondSeries: [...acc.secondSeries, 0],
          };
        }
        return {
          firstSeries: [...acc.firstSeries, average],
          secondSeries: [...acc.secondSeries, value - average],
        };
      },
      { firstSeries: [], secondSeries: [] }
    );
  };

  const { firstSeries, secondSeries } = calculateSeriesData(
    odometerEntity.lastTwoDayData.map((item) => item.value),
    odometerEntity.lastMonthAverage
  );

  const axisX = odometerEntity.lastTwoDayData.map((item) =>
    formatDate(
      new Date(item.fromDate),
      consumptionUnitsFormat['1 hour'],
      i18n.language
    )
  );

  const lastYearLabel = t('dashboardPage.lastYearValue');

  const option = {
    grid: { top: 40, right: 10, bottom: 80, left: 60 },

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      // This is a direct consequence of the variability of data that ECharts in TypeScript cannot transform into the correct data types
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter(params: any[]) {
        const belowAverageValue = params[0].data as number;
        const aboveAverageValue = params[1].data as number;

        let text = `<b>${t(
          'dashboardPage.valueBelowAverage'
        )}</b>: ${belowAverageValue}`;

        if (aboveAverageValue !== 0) {
          text += `<br/><b>${t(
            'dashboardPage.valueAboveAverage'
          )}</b>: ${aboveAverageValue}<br/><b>${t(
            'dashboardPage.totalValue'
          )}</b>: ${belowAverageValue + aboveAverageValue}`;
        }

        return text;
      },
    },
    xAxis: [
      {
        type: 'category',
        data: axisX,
        name: 'h',
      },
    ],
    yAxis: [
      {
        name: 'kWh',
        type: 'value',
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        height: 20,
        bottom: 10,
      },
    ],
    series: [
      {
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        stack: 'Ad',
        name: t('dashboardPage.currentYearValue'),
        itemStyle: { color: theme.palette.blue.main },
        data: firstSeries,
        markLine: {
          symbol: 'none',
          lineStyle: {
            type: 'solid',
            width: 2,
          },
          label: {
            show: false,
          },
          data: [
            {
              yAxis: odometerEntity.lastMonthAverage,
            },
          ],
        },
      },
      {
        name: lastYearLabel,
        type: 'bar',
        stack: 'Ad',
        itemStyle: { color: theme.palette.red.main },
        emphasis: {
          focus: 'series',
        },
        data: secondSeries,
      },
    ],
  };

  return (
    <ReactECharts style={{ height: 250, width: '100%' }} option={option} />
  );
};

export default LargestConsumptionGraph;
