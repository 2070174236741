import { useEffect, useState } from 'react';

import { WorkshopDto, WorkshopService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useWorkshops = () => {
  const [workshops, setWorkshops] = useState<WorkshopDto[]>([]);
  const [workshopsOptions, setWorkshopsOptions] = useState<Option[]>([]);

  const fetchData = async () => {
    const data = (
      await WorkshopService.getWorkshopList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as WorkshopDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setWorkshops(data as WorkshopDto[]);
    setWorkshopsOptions(options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    workshops,
    workshopsOptions,
  };
};
