import { FC, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from '@mui/material';
import { v4 } from 'uuid';

import { useIsTouchDevice } from '@/hooks/use-is-touch-device';
import { Language } from '@/i18n/enums';

const useIds = () => {
  return useRef({
    input: v4(),
    label: v4(),
  });
};

const Wrapper: FC<{
  native?: boolean;
  render: (props: ReturnType<typeof useIds>) => ReactElement | null;
}> = ({ native, render }) => {
  const ids = useIds();

  const { t } = useTranslation();

  return (
    <FormControl
      sx={{
        width: '100%',
        maxWidth: '245px',
        backgroundColor: 'white',
      }}
      data-cy="langSwitch"
    >
      <InputLabel
        id={ids.current.label}
        htmlFor={ids.current.input}
        variant={native ? 'standard' : undefined}
      >
        {t('localization.language')}
      </InputLabel>
      {render(ids)}
    </FormControl>
  );
};

const LangSwitcher: FC = () => {
  const { t, i18n } = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  if (isTouchDevice) {
    return (
      <Wrapper
        native
        render={(ids) => (
          <NativeSelect
            inputProps={{
              id: ids.current.input,
            }}
            value={i18n.language}
            onChange={({ target: { value } }) => i18n.changeLanguage(value)}
          >
            {Object.values(Language).map((lang) => (
              <option key={lang} value={lang}>
                {t(lang)}
              </option>
            ))}
          </NativeSelect>
        )}
      />
    );
  }

  return (
    <Wrapper
      render={(ids) => (
        <Select
          id={ids.current.input}
          labelId={ids.current.label}
          label={t('localization.language')}
          value={i18n.language}
          onChange={({ target: { value } }) => i18n.changeLanguage(value)}
          displayEmpty
          renderValue={(value) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <TranslateIcon />
              {t(`localization.${value}`)}
              <span />
            </Box>
          )}
        >
          {Object.values(Language).map((lang) => (
            <MenuItem key={lang} value={lang} data-cy="langMenuItem">
              {t(`localization.${lang}`)}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default LangSwitcher;
