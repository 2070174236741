/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OdometerConsumptionMinuteDto } from '../models/OdometerConsumptionMinuteDto';
import type { OdometerConsumptionMultipleDto } from '../models/OdometerConsumptionMultipleDto';
import type { OdometerConsumptionSingleDto } from '../models/OdometerConsumptionSingleDto';
import type { OdometerConsumptionSummaryDto } from '../models/OdometerConsumptionSummaryDto';
import type { OdometerDto } from '../models/OdometerDto';
import type { OdometerRequestDto } from '../models/OdometerRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OdometerService {
  /**
   * @returns any Odometers list.
   * @throws ApiError
   */
  public static getOdometerList({
    take,
    skip,
    mediumId,
    typeId,
    workshopId,
    centerId,
    branchOfficeId,
    name,
    externalId,
    deleted,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    mediumId?: string;
    typeId?: string;
    workshopId?: string;
    centerId?: string;
    branchOfficeId?: string;
    name?: string;
    externalId?: string;
    deleted?: boolean;
    sortBy?:
      | 'id'
      | 'name'
      | 'comment'
      | 'technicalName'
      | 'idByUser'
      | 'inputNumber'
      | 'workshop'
      | 'center'
      | 'type'
      | 'medium'
      | 'branchOffice'
      | 'installationDate';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<OdometerDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/',
      query: {
        mediumId: mediumId,
        typeId: typeId,
        workshopId: workshopId,
        centerId: centerId,
        branchOfficeId: branchOfficeId,
        name: name,
        externalId: externalId,
        deleted: deleted,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postOdometerCreate({
    requestBody,
  }: {
    /**
     * Create new odometer
     */
    requestBody: OdometerRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/odometer/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putOdometerUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update odometer
     */
    requestBody: OdometerRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/odometer/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Odometer not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteOdometerDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/odometer/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Odometer not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerConsumptionSingle({
    consumptionStartDate,
    consumptionEndDate,
    consumptionUnit,
    take,
    skip,
    id,
  }: {
    consumptionStartDate: string;
    consumptionEndDate: string;
    consumptionUnit:
      | '1 minute'
      | '15 minutes'
      | '1 hour'
      | '1 day'
      | '1 week'
      | '1 month'
      | '3 months'
      | '6 months'
      | '1 year';
    take: number;
    skip: number;
    id: string;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerConsumptionSingleDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/{id}/consumption',
      path: {
        id: id,
      },
      query: {
        consumptionStartDate: consumptionStartDate,
        consumptionEndDate: consumptionEndDate,
        consumptionUnit: consumptionUnit,
        take: take,
        skip: skip,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerConsumption({
    consumptionStartDate,
    consumptionEndDate,
    consumptionUnit,
    take,
    skip,
    mediumId,
    typeId,
    branchOfficeId,
    workshopId,
    centerId,
    odometerId,
    groupId,
    medium,
  }: {
    consumptionStartDate: string;
    consumptionEndDate: string;
    consumptionUnit:
      | '1 minute'
      | '15 minutes'
      | '1 hour'
      | '1 day'
      | '1 week'
      | '1 month'
      | '3 months'
      | '6 months'
      | '1 year';
    take?: number;
    skip?: number;
    mediumId?: number;
    typeId?: number | string;
    branchOfficeId?: number | string;
    workshopId?: number | string;
    centerId?: number | string;
    odometerId?: number | string;
    groupId?: number | string;
    medium?: string;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<OdometerConsumptionMultipleDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/consumption',
      query: {
        consumptionStartDate: consumptionStartDate,
        consumptionEndDate: consumptionEndDate,
        consumptionUnit: consumptionUnit,
        take: take,
        skip: skip,
        mediumId: mediumId,
        typeId: typeId,
        branchOfficeId: branchOfficeId,
        workshopId: workshopId,
        centerId: centerId,
        odometerId: odometerId,
        groupId: groupId,
        medium: medium,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerConsumptionSummary({
    consumptionStartDate,
    consumptionEndDate,
    consumptionUnit,
    take,
    skip,
  }: {
    consumptionStartDate: string;
    consumptionEndDate: string;
    consumptionUnit:
      | '1 minute'
      | '15 minutes'
      | '1 hour'
      | '1 day'
      | '1 week'
      | '1 month'
      | '3 months'
      | '6 months'
      | '1 year';
    take: number;
    skip: number;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerConsumptionSummaryDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/consumption-summary',
      query: {
        consumptionStartDate: consumptionStartDate,
        consumptionEndDate: consumptionEndDate,
        consumptionUnit: consumptionUnit,
        take: take,
        skip: skip,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getOdometerMinuteConsumption({
    date,
    odometerId,
  }: {
    date: string;
    odometerId: number;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: OdometerConsumptionMinuteDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/odometer/minute-consumption',
      query: {
        date: date,
        odometerId: odometerId,
      },
    });
  }
}
