import { useEffect, useRef } from 'react';

export const useRepeatFunctionPeriodically = (
  callback: () => void,
  interval: number
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const repeatedCallback = () => {
      callbackRef.current();
    };

    const intervalId = setInterval(repeatedCallback, interval);

    return () => clearInterval(intervalId);
  }, [interval]);
};
