import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@/components/ui/fields/Select';
import { ElectricityAlarmsContext } from '@/pages/electricity-alarms-page/ElectricityAlarmsPage';

import FilterItemWrapper from '../fields/FilterItemWrapper';

export type ElectricityAlarmFilter = {
  odometerId?: string;
  sortBy?: 'id' | 'odometer' | undefined;
  descending?: boolean;
};

export type AlarmsFiltersProps = {
  filter: ElectricityAlarmFilter;
  updateFilter: (key: string, value: string) => void;
};

const ElectricityAlarmsFilters: FC<AlarmsFiltersProps> = ({
  updateFilter,
  filter,
}) => {
  const { t } = useTranslation();
  const odometers = useContext(ElectricityAlarmsContext);

  const filters = () => {
    return [
      <Select
        key="odometerName"
        name="odometerName"
        options={odometers || []}
        onChange={(value) => updateFilter('odometerId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('electricityPage.alarmPage.odometer')}
        value={filter.odometerId || ''}
        clearable
      />,
    ];
  };

  return (
    <>
      {filters().map((filter) => (
        <FilterItemWrapper key={filter.key}>{filter}</FilterItemWrapper>
      ))}
    </>
  );
};

export default ElectricityAlarmsFilters;
