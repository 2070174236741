import { createContext, FC, PropsWithChildren } from 'react';

import { MenuContextType } from './types';

export const MenuContext = createContext<MenuContextType>({
  menu: undefined,
});

export const MenuProvider: FC<PropsWithChildren<MenuContextType>> = ({
  children,
  menu,
}) => {
  return (
    <MenuContext.Provider value={{ menu }}>{children}</MenuContext.Provider>
  );
};
