/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';
import type { UserDto } from '../models/UserDto';
import type { UserRequestDto } from '../models/UserRequestDto';
import type { UserUpdateRequestDto } from '../models/UserUpdateRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {
  /**
   * @returns any Users list.
   * @throws ApiError
   */
  public static getUserList({
    take,
    skip,
    deleted,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    sortBy?: 'id' | 'firstName' | 'lastName' | 'email' | 'phone';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<UserDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/',
      query: {
        deleted: deleted,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postUserCreate({
    requestBody,
  }: {
    /**
     * Create new user
     */
    requestBody: UserRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: UserDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getUserFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: UserDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putUserUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update user
     */
    requestBody: UserUpdateRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: UserDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/user/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `User not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteUserDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/user/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `User not found`,
      },
    });
  }
}
