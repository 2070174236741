import {
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Avatar as MuiAvatar,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
} from '@mui/material';

import WithLink from '@/components/routing/WithLink';
import { UserContext } from '@/contexts/user-context/UserContext';
import { ROUTE_PATHS } from '@/Routes';
import { getUserInitials } from '@/utils/format';
import { COLORS } from '@/utils/variables';

const Item: FC<{
  name: string;
  path?: string;
  icon?: ReactElement;
  onClick?: () => void;
}> = ({ path, icon, name, onClick }) => {
  const { t } = useTranslation();

  return (
    <WithLink href={path}>
      <MenuItem data-cy={`settingsMenuItem-${name}`} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={t(`profilePage.${name}`)} />
      </MenuItem>
    </WithLink>
  );
};

const Avatar: FC<
  PropsWithChildren<{
    color?: string;
    fontSize?: string;
    size?: number;
    dataCy?: string;
    ableMenu: boolean;
  }>
> = ({ color, fontSize, size, dataCy, ableMenu = false }) => {
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = !!anchorEl;
  const { logout } = useContext(UserContext);

  if (user === null) {
    return null;
  }

  return (
    <>
      <MuiAvatar
        sx={{
          bgcolor: color || '#373862',
          fontSize: fontSize || '16px',
          fontWeight: 'bold',
          width: size || 50,
          height: size || 50,
          cursor: ableMenu ? 'pointer' : '',
        }}
        data-cy={dataCy}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        {getUserInitials(user)}
      </MuiAvatar>
      <MuiMenu
        id="account-menu"
        anchorEl={anchorEl}
        open={isOpen && ableMenu}
        onClose={() => setAnchorEl(null)}
      >
        <Item
          key={ROUTE_PATHS.PROFILE}
          path={ROUTE_PATHS.PROFILE}
          name="title"
          icon={<AccountCircleIcon sx={{ color: COLORS.purple.main }} />}
        />
        <Item
          key="logout"
          name="logout"
          onClick={logout}
          icon={<ExitToAppIcon sx={{ color: COLORS.purple.main }} />}
        />
      </MuiMenu>
    </>
  );
};

export default Avatar;
