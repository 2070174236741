import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

import { OdometerConsumptionBaseDataDto } from '@/_generatedApi';
import { ElectricityDashboardsFilter } from '@/components/ui/filters/ElectricityDashboardFilters';
import formatDate, {
  consumptionUnitsFormat,
  prettyFormatNumber,
} from '@/utils/format';

const ElectricityConsumptionForIntervalGraph: FC<{
  filter: ElectricityDashboardsFilter;
  data: OdometerConsumptionBaseDataDto[];
}> = ({ filter, data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const columns = data.map((item) =>
    formatDate(
      new Date(item.fromDate),
      consumptionUnitsFormat[filter.consumptionUnit || '1 month'],
      i18n.language
    )
  );

  const option = {
    grid: { top: 40, right: 10, bottom: 60, left: 60 },

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: columns,
        name: 'h',
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'kWh',
        position: 'left',
        axisLabel: {
          formatter: (value: number) =>
            prettyFormatNumber(value, i18n.language),
        },
      },
    ],

    dataZoom: [
      {
        type: 'slider',
        height: 20,
        bottom: 10,
      },
    ],
    series: [
      {
        type: 'bar',
        zlevel: 2,
        emphasis: {
          focus: 'series',
        },
        name: t('dashboardPage.currentYearValue'),
        itemStyle: { color: theme.palette.blue.main },
        data: data.map((item) => item.value),
      },
      {
        type: 'line',
        zlevel: 3,
        emphasis: {
          focus: 'series',
        },
        name: t('dashboardPage.currentYearValue'),
        itemStyle: { color: theme.palette.yellow.main },
        data: data.map((item) => item.value),
      },
    ],
  };

  return (
    <ReactECharts style={{ height: 250, width: '100%' }} option={option} />
  );
};

export default ElectricityConsumptionForIntervalGraph;
