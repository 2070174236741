import { useEffect, useState } from 'react';

import {GroupDto, GroupService} from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useGroups = () => {
  const [groups, setGroups] = useState<GroupDto[]>([]);
  const [groupsOptions, setGroupsOptions] = useState<Option[]>([]);

  const fetchData = async () => {
    const data = (
      await GroupService.getGroupList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
        deleted: false
      })
    ).data as GroupDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setGroups(data as GroupDto[]);
    setGroupsOptions(options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    groups,
    groupsOptions,
  };
};
