import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import classNames from 'classnames';
import useLocalStorage from 'use-local-storage';

import {
  OdometerConsumptionMultipleDto,
  OdometerService,
} from '@/_generatedApi';
import DashBoardItem from '@/components/electricity-dashboard/DashboardItem';
import ElectricityDashboardsFilters, {
  ElectricityDashboardsFilter,
} from '@/components/ui/filters/ElectricityDashboardFilters';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useRepeatFunctionPeriodically } from '@/hooks/use-interval';
import { useShowToast } from '@/hooks/use-show-toast';
import { DATA_REFRESH_INTERVAL } from '@/utils/variables';

import styles from './dashboard.module.css';

const ElectricityDashboardPage: FC = () => {
  const { showGenericErrorToast } = useShowToast();
  const [odometersConsumption, setOdometersConsumption] = useState<
    OdometerConsumptionMultipleDto[]
  >([]);
  const { t } = useTranslation();

  const [filter, setFilter] = useLocalStorage<ElectricityDashboardsFilter>(
    'electricity-dashboard-filter',
    {}
  );

  const filterOrder: (keyof ElectricityDashboardsFilter)[] = [
    'typeId',
    'branchOfficeId',
    'workshopId',
    'centerId',
    'timeInterval',
    'consumptionStartDate',
    'consumptionEndDate',
    'consumptionUnit',
  ];

  const readyToFetchConsumptionData = useCallback((): boolean => {
    return !!filter.branchOfficeId && !!filter.consumptionUnit;
  }, [filter]);

  const fetchOdometersConsumption = useCallback(async () => {
    try {
      const data = (
        await OdometerService.getOdometerConsumption({
          // Both interfaces are generated automatically based on the api definition, unfortunately incompatible
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(filter as any),
          take: MAX_TAKE_ITEMS,
          skip: 0,
        })
      ).data;

      if (data) {
        setOdometersConsumption(data);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [filter, showGenericErrorToast]);

  const updateFilter = async (params: ElectricityDashboardsFilter) => {
    // Both interfaces are generated automatically based on the api definition, unfortunately incompatible
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedFilter: any = {};
    let hitKey = false;

    for (const filterKey of filterOrder) {
      if (hitKey) {
        break;
      }

      if (params[filterKey] !== undefined) {
        updatedFilter[filterKey] = params[filterKey];
        if (params[filterKey] === '') {
          hitKey = true;
        }
      } else {
        updatedFilter[filterKey] =
          filter[filterKey as keyof ElectricityDashboardsFilter];
      }
    }

    setFilter(updatedFilter);
  };

  useEffect(() => {
    if (readyToFetchConsumptionData()) {
      fetchOdometersConsumption();
    }
  }, [
    fetchOdometersConsumption,
    filter,
    showGenericErrorToast,
    readyToFetchConsumptionData,
  ]);

  useRepeatFunctionPeriodically(() => {
    if (readyToFetchConsumptionData()) {
      fetchOdometersConsumption();
    }
  }, DATA_REFRESH_INTERVAL);

  const renderOdometers = () => {
    return odometersConsumption.map((odometerEntity) => (
      <DashBoardItem
        key={odometerEntity.odometer.id}
        odometerEntity={odometerEntity}
        filter={filter}
      />
    ));
  };

  const remainingFiltersToFetch = useMemo(() => {
    const remainingFilters = [];

    for (const filterName of filterOrder.filter(
      (filterName) =>
        !['consumptionStartDate', 'consumptionEndDate'].includes(filterName)
    )) {
      if (filterName === 'centerId') {
        continue;
      }

      const filterValue = filter[filterName];

      if (!filterValue || filterValue === '') {
        remainingFilters.push(filterName.replace('Id', ''));
      }

      if (
        filterName === 'consumptionUnit' &&
        filterValue &&
        filterValue !== ''
      ) {
        break;
      }
    }

    return remainingFilters;
  }, [filter]);

  return (
    <div className={classNames(styles.page)}>
      <div className={classNames(styles.filtersWrapper)}>
        <ElectricityDashboardsFilters
          filter={filter}
          updateFilter={updateFilter}
        />
      </div>

      {remainingFiltersToFetch.length > 0 && (
        <Alert severity="info">
          {t('odometersSettingsPage.remainingFilters')}:
          <ul>
            {remainingFiltersToFetch.map((filterName) => (
              <li key={filterName}>
                {t(`electricityPage.dashboardPage.${filterName}`)}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {remainingFiltersToFetch.length === 0 &&
        odometersConsumption.length === 0 && (
          <Alert severity="warning">{t('common.noDataFound')}</Alert>
        )}

      {renderOdometers()}
    </div>
  );
};

export default ElectricityDashboardPage;
