import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'wouter';

import OdometerDetail from '@/components/odometer-detail/OdometerDetail';
import { ROUTE_PATHS } from '@/Routes';

import styles from './detail.module.css';

const ElectricityOdometersDetailPage: FC = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  return (
    <div className={classNames(styles.page)}>
      <div>
        <Button
          variant="outlined"
          type="button"
          size="large"
          data-cy-button="back"
          startIcon={<BackIcon />}
          onClick={() =>
            setLocation(
              `${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ELECTRICITY}${ROUTE_PATHS.HISTORICAL_ODOMETERS}`
            )
          }
        >
          {t('common.back')}
        </Button>
      </div>
      <OdometerDetail route={ROUTE_PATHS.ODOMETER_DETAIL} />
    </div>
  );
};

export default ElectricityOdometersDetailPage;
