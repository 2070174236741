/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BreakdownRequest = {
  interval: BreakdownRequest.interval;
  startDate?: string;
  endDate?: string;
};

export namespace BreakdownRequest {
  export enum interval {
    _1_DAY = '1 day',
    _1_WEEK = '1 week',
    _1_MONTH = '1 month',
    _3_MONTHS = '3 months',
    _6_MONTHS = '6 months',
    _1_YEAR = '1 year',
    SELECT_INTERVAL = 'selectInterval',
  }
}
