import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { OdometerConsumptionMultipleDto } from '@/_generatedApi';
import ElectricityQuarterHourPeakGrowthGraph from '@/components/graphs/ElectricityQuarterHourPeakGrowthGraph';
import LargestConsumptionGraph from '@/components/graphs/LargestConsumptionGraph';
import { ElectricityDashboardsFilter } from '@/components/ui/filters/ElectricityDashboardFilters';

import ElectricityConsumptionForIntervalGraph from '../graphs/ElectricityConsumtionForIntervalGraph';
import ElectricityIncrementalConsumptionGraph from '../graphs/ElectricityIncrementalConsumptionGraph';
import OverviewCard from '../ui/overview-card/OverviewCard';

export type DashBoardItemProps = {
  odometerEntity: OdometerConsumptionMultipleDto;
  filter: ElectricityDashboardsFilter;
};
const DashBoardItem: FC<DashBoardItemProps> = ({ odometerEntity, filter }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'electricityPage.dashboardPage',
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography variant="h2" sx={{ fontSize: '18px' }}>
        {odometerEntity.odometer.name}
      </Typography>
      <OverviewCard
        title={{
          text: t('electricityConsumptionForInterval'),
          variant: 'h6',
        }}
        expandable
      >
        <ElectricityConsumptionForIntervalGraph
          filter={filter}
          data={odometerEntity.data}
        />
      </OverviewCard>
      <OverviewCard
        title={{
          text: t('electricityIncrementalConsumptionGraph'),
          variant: 'h6',
        }}
        expandable
      >
        <ElectricityIncrementalConsumptionGraph
          filter={filter}
          data={odometerEntity.data}
        />
      </OverviewCard>

      {/* TODO: For now, the ID is fixed because the API does not return billing/subsidiary */}
      {filter.typeId === '2' && (
        <OverviewCard
          title={{
            text: t('electricityTrendOfHighestElectricityConsumptionGraph'),
            variant: 'h6',
          }}
          expandable
        >
          <LargestConsumptionGraph odometerEntity={odometerEntity} />
        </OverviewCard>
      )}

      <OverviewCard
        title={{
          text: t('electricityQuarterHourPeakGrowthGraph'),
          variant: 'h6',
        }}
        expandable
      >
        <ElectricityQuarterHourPeakGrowthGraph
          odometerId={odometerEntity.odometer.id}
          odometerOptimalConsumption={
            odometerEntity.odometer.optimalConsumption
          }
          odometerMaxHourlyValue={odometerEntity.odometer.maxHourlyValue}
          filter={filter}
        />
      </OverviewCard>
    </Box>
  );
};

export default DashBoardItem;
