import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select as MuiSelect } from '@mui/material';

import { FieldCommonProps, Option } from '../../fields/types';

const CDataGridPageSizeSelect: FC<
  FieldCommonProps<{
    options: Option[];
    clearable?: boolean;
    name: string;
    onChange: (value: number) => void;
    value: number;
  }>
> = ({ placeholder, options, clearable, onChange, ...rest }) => {
  const { t } = useTranslation();

  return (
    <MuiSelect
      variant="outlined"
      size="small"
      sx={{
        '& .MuiSelect-select': {
          ...(!rest.value && {
            opacity: 0,
          }),
          ...(!!(clearable && rest.value) && {
            paddingRight: '0px !important',
          }),
        },
        '&.Mui-focused': {
          '& .MuiSelect-select': {
            opacity: 1,
          },
        },
        '& .MuiSelect-select .notranslate::after': {
          content: `"${placeholder || t('common.select')} "`,
          opacity: 0.42,
        },
      }}
      onChange={({ target: { value } }) => onChange(Number(value))}
      value={rest.value || ''}
      MenuProps={{
        sx: {
          maxHeight: 300,
        },
      }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label || value}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default CDataGridPageSizeSelect;
