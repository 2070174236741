import { useEffect, useState } from 'react';

import { BranchOfficeDto, BranchOfficeService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useBranchOffices = () => {
  const [branchOffices, setBranchOffices] = useState<BranchOfficeDto[]>([]);
  const [branchOfficesOptions, setBranchOfficesOptions] = useState<Option[]>(
    []
  );

  const fetchData = async () => {
    const data = (
      await BranchOfficeService.getBranchOfficeList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      })
    ).data as BranchOfficeDto[];

    const options = (data || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setBranchOffices(data as BranchOfficeDto[]);
    setBranchOfficesOptions(options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    branchOffices,
    branchOfficesOptions,
  };
};
