import { FC, ReactElement, useRef } from 'react';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { v4 } from 'uuid';

import Show from '@/components/ui/control-flow/Show';
import { FieldProps } from '@/components/ui/form/types';

import { FieldCommonProps } from './types';

const useIds = () => {
  return useRef({
    input: v4(),
    label: v4(),
    helper: v4(),
  });
};

const Wrapper: FC<
  Pick<FieldCommonProps, 'label' | 'help'> &
    Pick<FieldProps<unknown>, 'error' | 'required'> & {
      render: (props: ReturnType<typeof useIds>) => ReactElement | null;
    } & { withoutHelperText?: boolean }
> = ({
  label,
  help,
  error,
  required = false,
  render,
  withoutHelperText = false,
}) => {
  const ids = useIds();

  return (
    <FormControl
      sx={{
        '& .MuiFormLabel-root': {
          maxWidth: 'calc(100% - 40px)',
        },
        ...(!!error && {
          '&, & svg, & .MuiFormLabel-root, & .MuiFormHelperText-root': {
            color: 'error.main',
          },
        }),
      }}
    >
      <InputLabel id={ids.current.label} htmlFor={ids.current.input}>
        {label}
        <Show when={required}>*</Show>
      </InputLabel>
      {render(ids)}
      {!withoutHelperText && (
        <Box
          sx={{
            height: '25px',
          }}
        >
          <FormHelperText
            sx={{ marginTop: '0px', fontSize: '12px' }}
            id={ids.current.helper}
          >
            {error || help}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default Wrapper;
