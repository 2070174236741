import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Option } from "@/components/ui/fields/types";
import { Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface AutocompleteFieldProps {
  label: string
  options: Option[];
  value: number[]
  onChange: (values: number[]) => void
}

export default function AutocompleteField({label, options, value, onChange}: AutocompleteFieldProps) {
  const nonSelectedValues = value.filter(id => !options.some(tag => tag.value === id));
  const allSelected = options.length === value.length - nonSelectedValues.length;

  const filter = createFilterOptions<Option>();

  const toggleSelectAll = () => {
    if (allSelected) {
      onChange([...nonSelectedValues]);
    } else {
      onChange([...nonSelectedValues, ...options.map(tag => tag.value).filter(id => id !== undefined) as number[]]);
    }
  }

  return (
    <Autocomplete
      style={{ backgroundColor: 'white' }}
      value={options.filter(option => option.value && value.includes(option.value as number))}
      onChange={(_, newValues, reason) => {
        if (newValues.some(option => (option as any).value === 'select-all')) {
          toggleSelectAll();
        }  else {
          onChange([...nonSelectedValues, ...newValues.map(tag => tag.value).filter(id => id !== undefined) as number[]])
        }
      }}
      multiple
      limitTags={0}
      getLimitTagsText={(more) => <Chip label={more} color="primary" />}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label?.toString() || 'Vybrat vše'}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected || ((option as any).value === 'select-all' && allSelected)}
          />
          {option.label || 'Vybrat vše'}
        </li>
      )}
      renderInput={(params) => {
        return (
        <TextField {...params} label={label} placeholder="Hledat"/>
      )}}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{value: "select-all"} as unknown as Option, ...filtered];
      }}
    />
  );
}
