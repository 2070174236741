import {format, nextSunday, nextThursday, previousMonday, subDays} from 'date-fns';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from 'date-fns';

import { BreakdownRequest, UserDto } from '@/_generatedApi';
import { locales } from '@/constants/locales';

const formatDate = (date: Date, formatStr: string, language: string) => {
  return format(date, formatStr, {
    locale: locales[language],
  });
};

export const getUserInitials = (user: UserDto) => {
  return `${user.firstName ? user.firstName[0] : ''}${
    user.lastName ? user.lastName[0] : ''
  }`;
};

export const dateToLocalISOString = (date: Date) => {
  const tzOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - tzOffset).toISOString().slice(0, -1);
};

export const getWeekInfo = (date: Date) => {
  const tempDate = new Date(date.getTime());
  tempDate.setHours(0, 0, 0, 0);

  // Thursday in current week decides the year.
  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));

  // January 4 is always in week 1.
  const week1 = new Date(tempDate.getFullYear(), 0, 4);

  // Calculate the week number
  const weekNumber =
    1 +
    Math.round(
      ((tempDate.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    );

  // Calculate the start and end dates of the week
  const startOfWeek = new Date(tempDate.getTime());
  startOfWeek.setDate(tempDate.getDate() - 3);

  const endOfWeek = new Date(tempDate.getTime());
  endOfWeek.setDate(tempDate.getDate() + 3);

  return {
    weekNumber,
    startOfWeek,
    endOfWeek,
  };
};

export const dateFormatTypes = {
  fullDate: 'dd.MM yyyy',
  fullDateTime: 'dd.MM yyyy hh:mm:ss',
};

export const getTranslatedDatePart = (
  date: Date,
  type: 'week' | 'month' | 'year' | string,
  langCode: string
): string => {
  if (type === 'week') {
    const weekInfo = getWeekInfo(date);

    return `${formatDate(
      weekInfo.startOfWeek,
      dateFormatTypes.fullDate,
      langCode
    )} ➟ ${formatDate(weekInfo.endOfWeek, dateFormatTypes.fullDate, langCode)}`;
  }

  const options: Intl.DateTimeFormatOptions = {};

  switch (type) {
    case 'month':
      options.month = 'long';
      break;
    case 'year':
      options.year = 'numeric';
      break;
  }

  return new Intl.DateTimeFormat(
    locales[langCode]?.code || 'en-GB',
    options
  ).format(date);
};

export const prettyFormatNumber = (value: number, langCode: string) => {
  return new Intl.NumberFormat(locales[langCode]?.code || 'en-GB').format(
    value
  );
};

export const consumptionUnitsFormat = {
  '1 minute': 'mm',
  '15 minutes': 'mm',
  '1 hour': 'hh',
  '1 day': 'dd. MM',
  '1 week': 'dd. MM',
  '1 month': 'dd. MM',
  '3 months': 'dd. MM',
  '6 months': 'dd. MM',
  '1 year': 'dd. MM',
};

export const formatDateForBE = (date: Date) => {
  return format(date, 'yyyy-MM-dd') + 'T' + format(date, 'HH:mm:ssxxx')
}

export const getStartAndEndDateByTimeInterval = (
  timeInterval: BreakdownRequest.interval
): { consumptionStartDate: string; consumptionEndDate: string } => {
  switch (timeInterval) {
    case BreakdownRequest.interval._1_DAY:
      return {
        consumptionStartDate: formatDateForBE(startOfDay(subDays(new Date(), 1))),
        consumptionEndDate: formatDateForBE(endOfDay(subDays(new Date(), 1))),
      };
    case BreakdownRequest.interval._1_WEEK:
      return {
        consumptionStartDate: formatDateForBE(startOfDay(startOfWeek(new Date(), {weekStartsOn: 1}))),
        consumptionEndDate: formatDateForBE(endOfDay(endOfWeek(new Date(), {weekStartsOn: 1}))),
      };
    case BreakdownRequest.interval._1_MONTH:
      return {
        consumptionStartDate: formatDateForBE(startOfMonth(new Date())),
        consumptionEndDate: formatDateForBE(endOfMonth(new Date())),
      };
    case BreakdownRequest.interval._3_MONTHS:
      return {
        consumptionStartDate: formatDateForBE(subMonths(new Date(), 3)),
        consumptionEndDate: formatDateForBE(endOfMonth(new Date())),
      };
    case BreakdownRequest.interval._6_MONTHS:
      return {
        consumptionStartDate: formatDateForBE(subMonths(new Date(), 6)),
        consumptionEndDate: formatDateForBE(endOfMonth(new Date())),
      };
    case BreakdownRequest.interval._1_YEAR:
      return {
        consumptionStartDate: formatDateForBE(startOfYear(new Date())),
        consumptionEndDate: formatDateForBE(endOfYear(new Date())),
      };
    default:
      return {
        consumptionStartDate: '',
        consumptionEndDate: '',
      };
  }
};

export default formatDate;
