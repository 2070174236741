import { useEffect, useState } from 'react';

import { WorkshopDto, WorkshopService } from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';

export const useWorkshop = (id: number) => {
  const [workshop, setWorkshop] = useState<WorkshopDto>();

  const fetchData = async () => {
    const data = (
      await WorkshopService.getWorkshopFind({
        id: id,
      })
    ).data;

    setWorkshop(data as WorkshopDto);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return workshop;
};
