/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchOfficeDto } from '../models/BranchOfficeDto';
import type { BranchOfficeRequestDto } from '../models/BranchOfficeRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BranchOfficeService {
  /**
   * @returns any Branch offices list.
   * @throws ApiError
   */
  public static getBranchOfficeList({
    take,
    skip,
    deleted,
    sortBy,
    descending,
    name,
    city,
    superiorBranchOfficeId,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    sortBy?: 'id' | 'name' | 'city' | 'comment' | 'superiorBranchOffice';
    descending?: boolean;
    name?: string;
    city?: string;
    superiorBranchOfficeId?: string;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<BranchOfficeDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/branch-office/',
      query: {
        deleted: deleted,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
        name: name,
        city: city,
        superiorBranchOfficeId: superiorBranchOfficeId,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postBranchOfficeCreate({
    requestBody,
  }: {
    /**
     * Create new branch office
     */
    requestBody: BranchOfficeRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: BranchOfficeDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/branch-office/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getBranchOfficeFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: BranchOfficeDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/branch-office/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putBranchOfficeUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update branch office
     */
    requestBody: BranchOfficeRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: BranchOfficeDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/branch-office/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteBranchOfficeDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/branch-office/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }
}
