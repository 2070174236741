import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel } from '@mui/material';

import {
  BreakdownRequest,
  OdometerConsumptionSingleDto,
  TimeService,
} from '@/_generatedApi';
import { Option } from '@/components/ui/fields/types';
import { useBranchOffices } from '@/hooks/data/use-branch-offices';
import { useCenters } from '@/hooks/data/use-centers';
import { useOdometerTypes } from '@/hooks/data/use-odometer-types';
import { useWorkshops } from '@/hooks/data/use-workshops';
import { useShowToast } from '@/hooks/use-show-toast';
import { getStartAndEndDateByTimeInterval } from '@/utils/format';

import CDatePicker from '../common/date-picker/CDatePicker';
import FilterItemWrapper from '../fields/FilterItemWrapper';
import Select from '../fields/Select';
import { useOdometers } from '@/hooks/data/use-odometers';
import format from 'date-fns/format';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  addDays,
  addMonths,
  endOfMonth,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Checkbox from '@mui/material/Checkbox';
import { useGroups } from '@/hooks/data/use-groups';
import { Medium } from '@/constants/mediums';
import AutocompleteField from "@/components/ui/fields/AutocompleteField";

export type ElectricityDashboardsFilter = {
  typeId?: string;
  branchOfficeId?: string;
  workshopId?: string;
  centerId?: string;
  groupId?: string;
  odometerId?: string;
  consumptionStartDate?: string;
  consumptionEndDate?: string;
  timeInterval?: BreakdownRequest.interval;
  consumptionUnit?: OdometerConsumptionSingleDto.unit;
};

export type ElectricityDashboardFiltersProps = {
  updateFilter: (filterToUpdate: ElectricityDashboardsFilter) => void;
  filter: ElectricityDashboardsFilter;
  showSum?: boolean;
  setShowSum?: (val: boolean) => void;
  showMinuteData?: boolean;
  setShowMinuteData?: (val: boolean) => void;
  showTrendData?: boolean;
  setShowTrendData?: (val: boolean) => void;
  withDate?: boolean;
  withFilters?: boolean;
  medium?: Medium;
};

const getNumberArrayFromString = (value?: string|number) => {
  if (!value) {
    return [];
  }
  if (typeof value === 'number') {
    return [value];
  }
  return value.split(',').map(val => parseInt(val)).filter(val => !isNaN(val));
}

const getOptions = (values: {name: string, id: number}[]) => {
  return values.map((item) => ({
    label: item.name,
    value: item.id,
  } as Option))
}

const ElectricityDashboardFilters: FC<ElectricityDashboardFiltersProps> = ({
  updateFilter,
  filter,
  showSum,
  setShowSum,
  showMinuteData,
  setShowMinuteData,
  showTrendData,
  setShowTrendData,
  withDate = true,
  withFilters = true,
  medium = Medium.electricity,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'electricityPage.dashboardPage',
  });
  const { branchOffices } = useBranchOffices();
  const { workshops } = useWorkshops();
  const { centers } = useCenters();
  const { groups } = useGroups();
  const { odometerTypes } = useOdometerTypes();
  const { odometers } = useOdometers();
  const { showGenericErrorToast } = useShowToast();
  const [unitOptions, setUnitOptions] = useState<Option[]>([]);

  const setFilterRangeDate = useCallback((): void => {
    if (
      filter.timeInterval &&
      filter.timeInterval !== BreakdownRequest.interval.SELECT_INTERVAL
    ) {
      const consumptionRange = getStartAndEndDateByTimeInterval(
        filter.timeInterval
      );

      updateFilter({
        ...filter,
        consumptionStartDate: consumptionRange.consumptionStartDate,
        consumptionEndDate: consumptionRange.consumptionEndDate,
      });
    }
  }, [filter.timeInterval]);

  const showDatePickersForInterval = useCallback((): boolean => {
    return filter.timeInterval === BreakdownRequest.interval.SELECT_INTERVAL;
  }, [filter.timeInterval]);

  const showConsumptionUnit = useCallback((): boolean => {
    return (
      (!!filter.timeInterval &&
        filter.timeInterval !== BreakdownRequest.interval.SELECT_INTERVAL) ||
      (showDatePickersForInterval() &&
        !!filter.consumptionStartDate &&
        !!filter.consumptionEndDate)
    );
  }, [
    filter.consumptionEndDate,
    filter.consumptionStartDate,
    filter.timeInterval,
    showDatePickersForInterval,
  ]);

  const fetchUnitOptions = useCallback(async () => {
    if (!showConsumptionUnit()) {
      return;
    }

    const requestBody: BreakdownRequest = {
      interval: filter.timeInterval as BreakdownRequest.interval,
      endDate: filter.consumptionEndDate,
      startDate: filter.consumptionStartDate,
    };

    try {
      const response = await TimeService.postBreakdown({ requestBody });

      setUnitOptions(
        response.data?.availableValues?.map((value) => ({
          value,
          label: t(`consumptionUnitEnum.${value}`),
        })) || []
      );
    } catch (e) {
      showGenericErrorToast();
    }
  }, [
    showConsumptionUnit,
    filter.timeInterval,
    filter.consumptionEndDate,
    filter.consumptionStartDate,
    t,
    showGenericErrorToast,
  ]);

  useEffect(() => {
    fetchUnitOptions();
  }, [fetchUnitOptions]);

  useEffect(() => {
    setFilterRangeDate();
  }, [setFilterRangeDate]);

  const timeIntervalOptions = Object.values(BreakdownRequest.interval).map(
    (interval) => ({
      value: interval,
      label: t(`timeIntervalEnum.${interval}`),
    })
  );

  const onPreviousClick = useCallback(() => {
    let newStartDate = new Date(filter.consumptionStartDate || '');
    let newEndDate = new Date(filter.consumptionEndDate || '');

    switch (filter.timeInterval) {
      case BreakdownRequest.interval._1_DAY:
        newStartDate = subDays(newStartDate, 1);
        newEndDate = subDays(newEndDate, 1);
        break;
      case BreakdownRequest.interval._1_WEEK:
        newStartDate = subDays(newStartDate, 7);
        newEndDate = subDays(newEndDate, 7);
        break;
      case BreakdownRequest.interval._1_MONTH:
        newStartDate = startOfMonth(subMonths(newStartDate, 1));
        newEndDate = endOfMonth(subMonths(newEndDate, 1));
        break;
      case BreakdownRequest.interval._3_MONTHS:
        newStartDate = startOfMonth(subMonths(newStartDate, 3));
        newEndDate = endOfMonth(subMonths(newEndDate, 3));
        break;
      case BreakdownRequest.interval._6_MONTHS:
        newStartDate = startOfMonth(subMonths(newStartDate, 6));
        newEndDate = endOfMonth(subMonths(newEndDate, 6));
        break;
      case BreakdownRequest.interval._1_YEAR:
        newStartDate = startOfMonth(subMonths(newStartDate, 12));
        newEndDate = endOfMonth(subMonths(newEndDate, 12));
        break;
    }

    updateFilter({
      ...filter,
      consumptionStartDate: newStartDate.toDateString(),
      consumptionEndDate: newEndDate.toDateString(),
    });
  }, [filter]);

  const onNextClick = useCallback(() => {
    let newStartDate = new Date(filter.consumptionStartDate || '');
    let newEndDate = new Date(filter.consumptionEndDate || '');

    switch (filter.timeInterval) {
      case BreakdownRequest.interval._1_DAY:
        newStartDate = addDays(newStartDate, 1);
        newEndDate = addDays(newEndDate, 1);
        break;
      case BreakdownRequest.interval._1_WEEK:
        newStartDate = addDays(newStartDate, 7);
        newEndDate = addDays(newEndDate, 7);
        break;
      case BreakdownRequest.interval._1_MONTH:
        newStartDate = startOfMonth(addMonths(newStartDate, 1));
        newEndDate = endOfMonth(addMonths(newEndDate, 1));
        break;
      case BreakdownRequest.interval._3_MONTHS:
        newStartDate = startOfMonth(addMonths(newStartDate, 3));
        newEndDate = endOfMonth(addMonths(newEndDate, 3));
        break;
      case BreakdownRequest.interval._6_MONTHS:
        newStartDate = startOfMonth(addMonths(newStartDate, 6));
        newEndDate = endOfMonth(addMonths(newEndDate, 6));
        break;
      case BreakdownRequest.interval._1_YEAR:
        newStartDate = startOfMonth(addMonths(newStartDate, 12));
        newEndDate = endOfMonth(addMonths(newEndDate, 12));
        break;
    }

    updateFilter({
      ...filter,
      consumptionStartDate: newStartDate.toDateString(),
      consumptionEndDate: newEndDate.toDateString(),
    });
  }, [filter]);

  const typeValue = getNumberArrayFromString(filter.typeId || '');
  const branchOfficeValue = getNumberArrayFromString(filter.branchOfficeId || '');
  const workshopValue = getNumberArrayFromString(filter.workshopId || '');
  const centerValue = getNumberArrayFromString(filter.centerId || '');
  const groupValue = getNumberArrayFromString(filter.groupId || '');
  const odometerValue = getNumberArrayFromString(filter.odometerId || '');

  const odometersOptions = odometers
    .filter(odometer => odometer.medium?.type === medium)
    .filter(item => typeValue.length === 0 || (item.type?.id && typeValue.includes(item.type?.id)))
    .filter(item => branchOfficeValue.length === 0 || !item.branchOffice?.id || (item.branchOffice?.id && branchOfficeValue.includes(item.branchOffice?.id)))
    .filter(item => workshopValue.length === 0 || !item.workshop?.id || (item.workshop?.id && workshopValue.includes(item.workshop?.id)))
    .filter(item => centerValue.length === 0 || !item.center?.id || (item.center?.id && centerValue.includes(item.center?.id)))
    .filter(item => groupValue.length === 0 || !item.group?.id || (item.group?.id && groupValue.includes(item.group?.id)))

  const selectedOdometers = odometersOptions.filter(item => odometerValue.includes(item.id));

  const odometerTypesOptions = odometerTypes
    .filter(item => typeValue.length > 0 || odometersOptions.map(odometer => odometer.type?.id).includes(item.id))
    .filter(item => typeValue.length > 0 || selectedOdometers.length === 0 || selectedOdometers.map(odometer => odometer.type?.id).includes(item.id))
  const branchOfficesOptions = branchOffices
    .filter(item => branchOfficeValue.length > 0 || odometersOptions.map(odometer => odometer.branchOffice?.id).includes(item.id))
    .filter(item => branchOfficeValue.length > 0 || selectedOdometers.length === 0 || selectedOdometers.map(odometer => odometer.branchOffice?.id).includes(item.id))
  const workshopsOptions = workshops
    .filter(item => workshopValue.length > 0 || odometersOptions.map(odometer => odometer.workshop?.id).includes(item.id))
    .filter(item => workshopValue.length > 0 || selectedOdometers.length === 0 || selectedOdometers.map(odometer => odometer.workshop?.id).includes(item.id))
  const centersOptions = centers
    .filter(item => centerValue.length > 0 || odometersOptions.map(odometer => odometer.center?.id).includes(item.id))
    .filter(item => centerValue.length > 0 || selectedOdometers.length === 0 || selectedOdometers.map(odometer => odometer.center?.id).includes(item.id))
  const groupsOptions = groups
    .filter(item => groupValue.length > 0 || odometersOptions.map(odometer => odometer.group?.id).includes(item.id))
    .filter(item => groupValue.length > 0 || selectedOdometers.length === 0 || selectedOdometers.map(odometer => odometer.group?.id).includes(item.id))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {withFilters && (
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <FilterItemWrapper key="typeId">
            <AutocompleteField
              options={getOptions(odometerTypesOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, typeId: [...new Set(value)].join(',') })
              }}
              label={t('odometerType')}
              value={odometerTypesOptions.length && getNumberArrayFromString(filter.typeId || '') || []}
            />
          </FilterItemWrapper>

          <FilterItemWrapper key="branchOfficeId">
            <AutocompleteField
              options={getOptions(branchOfficesOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, branchOfficeId: [...new Set(value)].join(',') })
              }}
              label={t('branchOffice')}
              value={branchOfficesOptions.length && branchOfficeValue || []}
            />
          </FilterItemWrapper>

          <FilterItemWrapper key="workshop">
            <AutocompleteField
              options={getOptions(workshopsOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, workshopId: [...new Set(value)].join(',') })
              }}
              label={t('workshop')}
              value={workshopsOptions.length && workshopValue || []}
            />
          </FilterItemWrapper>

          <FilterItemWrapper key="center">
            <AutocompleteField
              options={getOptions(centersOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, centerId: [...new Set(value)].join(',') })
              }}
              label={t('center')}
              value={centersOptions.length && getNumberArrayFromString(filter.centerId || '') || []}
            />
          </FilterItemWrapper>

          <FilterItemWrapper key="group">
            <AutocompleteField
              options={getOptions(groupsOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, groupId: [...new Set(value)].join(',') })
              }}
              label={t('group')}
              value={groupsOptions.length && getNumberArrayFromString(filter.groupId || '') || []}
            />
          </FilterItemWrapper>

          <FilterItemWrapper key="odometer">
            <AutocompleteField
              options={getOptions(odometersOptions)}
              onChange={(value) => {
                updateFilter({ ...filter, odometerId: [...new Set(value)].join(',') })
              }}
              label={t('odometer')}
              value={odometersOptions.length && getNumberArrayFromString(filter.odometerId || '') || []}
            />
          </FilterItemWrapper>
        </Box>
      )}
      {withDate && (
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <FilterItemWrapper key="timeInterval">
            <Select
              name="timeInterval"
              options={timeIntervalOptions}
              onChange={(value) => {
                updateFilter({
                  ...filter,
                  timeInterval: value as BreakdownRequest.interval,
                });
              }}
              onBlur={() => {}}
              error=""
              required={false}
              label={t('timeInterval')}
              value={(timeIntervalOptions.length && filter.timeInterval) || ''}
              withoutHelperText
              clearable
            />
          </FilterItemWrapper>
          {showConsumptionUnit() && (
            <FilterItemWrapper key="consumptionUnit">
              <Select
                name="consumptionUnit"
                options={unitOptions || []}
                onChange={(value) =>
                  updateFilter({
                    ...filter,
                    consumptionUnit: value as OdometerConsumptionSingleDto.unit,
                  })
                }
                onBlur={() => {}}
                error=""
                required={false}
                label={t('units')}
                value={(unitOptions.length && filter.consumptionUnit) || ''}
                withoutHelperText
                clearable
              />
            </FilterItemWrapper>
          )}
          {filter.timeInterval !==
            BreakdownRequest.interval.SELECT_INTERVAL && (
            <>
              <FilterItemWrapper sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIosNewIcon />}
                  onClick={() => onPreviousClick()}
                >
                  Předchozí
                </Button>
              </FilterItemWrapper>
              {filter.timeInterval === BreakdownRequest.interval._1_DAY ? (
                <FilterItemWrapper key="date">
                  <CDatePicker
                    name="date"
                    onChange={(value) => {
                      const date = value as Date;
                      updateFilter({
                        ...filter,
                        consumptionStartDate: date.toDateString(),
                        consumptionEndDate: date.toDateString(),
                      });
                    }}
                    onBlur={() => {}}
                    error=""
                    required={false}
                    label={t('day')}
                    value={
                      filter?.consumptionStartDate
                        ? new Date(filter?.consumptionStartDate as string)
                        : null
                    }
                    withoutHelperText
                  />
                </FilterItemWrapper>
              ) : (
                <FilterItemWrapper>
                  <center>
                    {format(
                      new Date(filter.consumptionStartDate || '2023-01-01'),
                      'dd.MM.yyyy'
                    )}
                    <br />
                    {format(
                      new Date(filter.consumptionEndDate || '2023-01-01'),
                      'dd.MM.yyyy'
                    )}
                  </center>
                </FilterItemWrapper>
              )}
              <FilterItemWrapper sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => onNextClick()}
                  disabled={
                    new Date(filter.consumptionEndDate || '2023-01-01') >
                    new Date()
                  }
                >
                  Další
                </Button>
              </FilterItemWrapper>
            </>
          )}
          {showDatePickersForInterval() && (
            <FilterItemWrapper key="consumptionStartDate">
              <CDatePicker
                name="consumptionStartDate"
                onChange={(value) => {
                  const date = value as Date;
                  updateFilter({
                    ...filter,
                    consumptionStartDate: date.toDateString(),
                  });
                }}
                onBlur={() => {}}
                error=""
                required={false}
                label={t('consumptionStartDate')}
                value={
                  filter?.consumptionStartDate
                    ? new Date(filter?.consumptionStartDate as string)
                    : null
                }
                withoutHelperText
              />
            </FilterItemWrapper>
          )}
          {showDatePickersForInterval() && (
            <FilterItemWrapper key="to">
              <CDatePicker
                name="to"
                onChange={(value) => {
                  const date = value as Date;
                  updateFilter({
                    ...filter,
                    consumptionEndDate: date.toDateString(),
                  });
                }}
                onBlur={() => {}}
                error=""
                required={false}
                label={t('consumptionEndDate')}
                minDate={
                  filter?.consumptionStartDate
                    ? new Date(filter?.consumptionStartDate as string)
                    : undefined
                }
                value={
                  filter?.consumptionEndDate
                    ? new Date(filter?.consumptionEndDate as string)
                    : null
                }
                withoutHelperText
              />
            </FilterItemWrapper>
          )}
          {showSum !== undefined && (
            <FilterItemWrapper width={160} sx={{ justifyContent: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={showSum}
                    onChange={(_, checked) => setShowSum && setShowSum(checked)}
                  />
                }
                label={t('showSum')}
              />
            </FilterItemWrapper>
          )}
          {showMinuteData !== undefined && (
            <FilterItemWrapper width={160} sx={{ justifyContent: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={showMinuteData}
                    onChange={(_, checked) =>
                      setShowMinuteData && setShowMinuteData(checked)
                    }
                  />
                }
                label={t('showMinuteData')}
              />
            </FilterItemWrapper>
          )}
          {showTrendData !== undefined && (
            <FilterItemWrapper width={160} sx={{ justifyContent: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={showTrendData}
                    onChange={(_, checked) =>
                      setShowTrendData && setShowTrendData(checked)
                    }
                  />
                }
                label={t('showTrendData')}
              />
            </FilterItemWrapper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ElectricityDashboardFilters;
