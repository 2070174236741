import { FC, ReactElement, useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WithLink from '@/components/routing/WithLink';
import Show from '@/components/ui/control-flow/Show';
import Tooltip from '@/components/ui/tooltip/Tooltip';
import { DashboardLayoutContext } from '@/contexts/layout/DashboardLayoutContext';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { AnyFunction, Menu } from '@/types/core-types';

import SideNav from './SideNav';

const DrawerButton: FC<{
  name: string;
  icon?: ReactElement;
  href?: string;
  onClick?: AnyFunction;
  target?: '_blank';
  active?: boolean;
  disabled?: boolean;
  menu?: Menu;
}> = ({ name, icon, href, active, menu, ...rest }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useIsMobile();

  const {
    open: [open, setOpen],
  } = useContext(DashboardLayoutContext);

  const [subMenuOpen, setSubMenuOpen] = useState(active);

  useLayoutEffect(() => {
    if (active) {
      setSubMenuOpen(true);
    }
  }, [active]);

  if (isMobile || open) {
    return (
      <>
        <ListItem disablePadding>
          <WithLink href={href}>
            <ListItemButton
              sx={{
                ...(active && {
                  '& svg': {
                    color: 'secondary.main',
                  },
                }),
              }}
              {...rest}
            >
              <Show when={icon !== undefined}>
                <ListItemIcon
                  sx={{
                    minWidth: '35px',
                  }}
                >
                  {icon}
                </ListItemIcon>
              </Show>
              <ListItemText
                sx={{
                  ...(active && {
                    color: 'secondary.main',
                  }),
                }}
                primary={t(`navigation.${name}`)}
                data-cy="menuItem"
              />
              <Show when={menu !== undefined}>
                <Tooltip
                  title={t(subMenuOpen ? 'common.close' : 'common.open')}
                  placement="right"
                >
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      setSubMenuOpen((prev) => !prev);
                    }}
                    sx={{
                      marginLeft: '65px',
                    }}
                    data-cy="subMenuChevron"
                  >
                    {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Tooltip>
              </Show>
            </ListItemButton>
          </WithLink>
        </ListItem>
        <Show when={menu !== undefined}>
          <Collapse
            sx={{
              marginLeft: '16px',
              borderLeft: `1px solid ${theme.palette.grey[100]}`,
            }}
            in={subMenuOpen}
            timeout="auto"
            unmountOnExit
          >
            <SideNav menu={menu} />
          </Collapse>
        </Show>
      </>
    );
  }

  return (
    <ListItem
      sx={{
        minHeight: '48px',
        paddingLeft: '12px',
      }}
      disablePadding
    >
      <Tooltip title={t(`navigation.${name}`)} placement="right">
        <IconButton
          sx={{
            ...(active && {
              '& svg': {
                color: 'secondary.main',
              },
            }),
          }}
          {...rest}
          onClick={() => {
            setOpen(true);
          }}
        >
          <WithLink href={href}>{icon}</WithLink>
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};

export default DrawerButton;
