import {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar as MuiAppBar, Autocomplete, Box, IconButton, Toolbar} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Show from '@/components/ui/control-flow/Show';
import Tooltip from '@/components/ui/tooltip/Tooltip';
import {DashboardLayoutContext} from '@/contexts/layout/DashboardLayoutContext';
import {useIsMobile} from '@/hooks/use-is-mobile';
import {ReactComponent as CompanyLogo} from '@/img/logo.svg';

import Avatar from './Avatar';
import Settings from './Settings';
import TopNav from './TopNav';
import {COLORS} from "@/utils/variables";
import CButton from "@/components/ui/common/button/CButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CDatePicker from "@/components/ui/common/date-picker/CDatePicker";
import WithLink from "@/components/routing/WithLink";
import ElectricityReadoutForm from "@/components/electricity-readings/ElectricityReadoutForm";
import {OdometerService, ReadoutService} from "@/_generatedApi";
import {format} from "date-fns";
import {useShowToast} from "@/hooks/use-show-toast";

const AppBar: FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const isMobile = useIsMobile();

  const {
    open: [open, setOpen],
  } = useContext(DashboardLayoutContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const { showToast } = useShowToast();

  return (
    <MuiAppBar
      sx={{
        ...(!isMobile && {
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }),
      }}
      position="fixed"
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '12px',
        }}
      >
        <Show when={isMobile || !open}>
          <Tooltip title={t('common.open')} placement="right">
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon
                sx={{
                  color: 'common.white',
                }}
              />
            </IconButton>
          </Tooltip>
        </Show>
        <Show when={!isMobile && open}>
          <Tooltip title={t('common.close')} placement="right">
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon
                sx={{
                  color: 'common.white',
                }}
              />
            </IconButton>
          </Tooltip>
        </Show>
        <Toolbar
          sx={{
            flexGrow: 1,
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <WithLink href={'/'}>
            <CompanyLogo />
          </WithLink>
          <TopNav/>
          <Show when={!isMobile}>
            <CButton type="button" onClick={() => setDialogOpen(true)} variant="add">Odečet</CButton>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Odečet</DialogTitle>
              <DialogContent>
                <ElectricityReadoutForm onSubmit={(data) => {
                  ReadoutService.postReadoutCreate({requestBody: {...data, date: format(data.date, 'yyyy-MM-dd') + 'T' + format(data.date, 'HH:mm:ssxxx')}})
                  setDialogOpen(false);
                  showToast('success', t('notifications.itemWasSuccessfullyCreated'));
                }} onCancel={() => setDialogOpen(false)} />
              </DialogContent>
            </Dialog>
            <Settings/>
            <Avatar dataCy="userAvatarProfile" ableMenu/>
          </Show>
        </Toolbar>
      </Box>
    </MuiAppBar>
  );
};

export default AppBar;
