import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { OdometerDto, OdometerMappingDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { useBranchOffices } from '@/hooks/data/use-branch-offices';
import { useCenters } from '@/hooks/data/use-centers';
import { useMediums } from '@/hooks/data/use-mediums';
import { useOdometerTypes } from '@/hooks/data/use-odometer-types';
import { useWorkshops } from '@/hooks/data/use-workshops';

import CDatePicker from '../ui/common/date-picker/CDatePicker';
import Select from '../ui/fields/Select';
import Textarea from '../ui/fields/Textarea';

import styles from './SettingsOdometerForm.module.css';
import SettingsOdometerMappingForm, {
  odometerMappingValidationSchema,
} from '@/components/settings-odometers/SettingsOdometerMappingForm';
import { format } from 'date-fns';
import TextField from '@mui/material/TextField';
import { useGroups } from '@/hooks/data/use-groups';

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', { count: 4 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    mediumId: yup.number().required('validation.required'),
    typeId: yup.number().required('validation.required'),
    idByUser: yup.number().nullable(),
    installationDate: yup.date().required('validation.required'),
    branchOfficeId: yup.number().required('validation.required'),
    workshopId: yup.number().nullable(),
    centerId: yup.number().nullable(),
    groupId: yup.number().nullable(),
    inputNumber: yup.number().nullable(),
    coefficient: yup.number().nullable(),
    comment: yup
      .string()
      .nullable()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(255, t('validation.maxChars', { count: 255 }) as string),
    optimalConsumption: yup.number().required('validation.required'),
    maxHourlyValue: yup.number().required('validation.required'),
    odometerValue: yup.number().nullable(),
    odometerValueDate: yup.date().nullable(),
    technicalName: yup.string().nullable(),
    externalId: yup.string().nullable(),
    unit: yup.string().required('validation.required'),
    odometerMappings: yup.array().of(odometerMappingValidationSchema(t)),
  });

export type SettingsOdometerFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type SettingsOdometerFormProps = {
  odometer?: OdometerDto;
  onSubmit: (values: SettingsOdometerFormOutputValues, id?: number) => void;
  onBack?: () => void;
  onCancel: () => void;
};

const unitMap = [
  {
    medium: 'Elektřina',
    units: ['kWh', 'kVArh'],
  },
  {
    medium: 'Voda',
    units: ['m3'],
  },
  {
    medium: 'Plyn',
    units: ['m3'],
  },
];

const SettingsOdometerForm: FC<SettingsOdometerFormProps> = ({
  odometer,
  onSubmit,
  onBack,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { branchOfficesOptions } = useBranchOffices();
  const { workshopsOptions } = useWorkshops();
  const { centersOptions } = useCenters();
  const { mediumsOptions } = useMediums();
  const { groupsOptions } = useGroups();
  const { odometerTypesOptions } = useOdometerTypes();
  const dialogType = odometer ? 'update' : 'add';

  const initialValues = {
    name: odometer?.name || null,
    mediumId: odometer?.medium?.id || null,
    typeId: odometer?.type?.id || null,
    idByUser: odometer?.idByUser || null,
    installationDate: odometer?.installationDate
      ? new Date(odometer?.installationDate as string)
      : null,
    branchOfficeId: odometer?.branchOffice?.id || null,
    workshopId: odometer?.workshop?.id || null,
    centerId: odometer?.center?.id || null,
    groupId: odometer?.group?.id || null,
    inputNumber: odometer?.inputNumber || null,
    coefficient: odometer?.coefficient || null,
    comment: odometer?.comment || null,
    optimalConsumption: odometer?.optimalConsumption || null,
    maxHourlyValue: odometer?.maxHourlyValue || null,
    odometerValue: odometer?.odometerValue || null,
    odometerValueDate: odometer?.odometerValueDate
      ? new Date(odometer?.odometerValueDate as string)
      : null,
    technicalName: odometer?.technicalName || null,
    externalId: odometer?.externalId || null,
    odometerMappings: odometer?.odometerMappings || [],
    unit: odometer?.unit || null,
  };

  const handleSubmit = async (values: SettingsOdometerFormOutputValues) => {
    onSubmit(values, odometer?.id ? odometer.id : undefined);
  };

  const [open, setOpen] = useState<number | null>(null);
  const [addedMapping, setAddedMapping] = useState(false);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      {onBack && (
        <div>
          <Button
            type="button"
            size="large"
            style={{ marginBottom: '20px' }}
            data-cy-button="back"
            onClick={() => onBack()}
            startIcon={<BackIcon />}
          >
            {t('common.back')}
          </Button>
        </div>
      )}

      <Form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ isSubmitting, resetForm, errors, values }) => {
          const medium = mediumsOptions?.find(
            (item) => item.value === values.mediumId
          );

          return (
            <Box
              sx={{
                maxWidth: '900px',
                width: '100%',
              }}
            >
              <div className={classNames(styles.container)}>
                <div className={classNames(styles.column)}>
                  <Typography variant="h5" mb="24px">
                    {t('odometersSettingsPage.odometerInfo')}
                  </Typography>

                  <Field<string>
                    name="name"
                    render={(props) => (
                      <Input
                        autoFocus
                        label={t('odometersSettingsPage.name')}
                        {...props}
                        required
                        data-cy-field="name"
                      />
                    )}
                  />

                  <Field<number>
                    name="mediumId"
                    render={(props) => (
                      <Select
                        options={mediumsOptions}
                        label={t('odometersSettingsPage.medium')}
                        {...props}
                        required
                        data-cy-field="medium"
                        clearable
                      />
                    )}
                  />
                  <Field<number>
                    name="typeId"
                    render={(props) => (
                      <Select
                        options={odometerTypesOptions}
                        label={t('odometersSettingsPage.type')}
                        {...props}
                        required
                        data-cy-field="type"
                        clearable
                      />
                    )}
                  />

                  {/*<Field<number>*/}
                  {/*  name="idByUser"*/}
                  {/*  render={(props) => (*/}
                  {/*    <Input*/}
                  {/*      type="number"*/}
                  {/*      label={t('odometersSettingsPage.idByUser')}*/}
                  {/*      {...props}*/}
                  {/*      required*/}
                  {/*      data-cy-field="idByUser"*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  <Field<Date>
                    name="installationDate"
                    render={(props) => {
                      return (
                        <CDatePicker
                          {...props}
                          required
                          label={t('odometersSettingsPage.installationDate')}
                        />
                      );
                    }}
                  />

                  <Field<string>
                    name="unit"
                    render={(props) => (
                      <Autocomplete
                        style={{ backgroundColor: 'white' }}
                        {...props}
                        disablePortal
                        onChange={(event: any, newValue: string | null) => {
                          props.onChange(newValue);
                        }}
                        options={unitMap.find(item => item.medium === medium?.label)?.units || []}
                        fullWidth
                        sx={{ mb: 2 }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            label="Jednotka"
                          />
                        )}
                      />
                    )}
                  />

                  {/*<Field<number>*/}
                  {/*  name="odometerValue"*/}
                  {/*  render={(props) => (*/}
                  {/*    <Input*/}
                  {/*      type="number"*/}
                  {/*      label={t('odometersSettingsPage.odometerValue')}*/}
                  {/*      {...props}*/}
                  {/*      required*/}
                  {/*      data-cy-field="odometerValue"*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}

                  {/*<Field<Date>*/}
                  {/*  name="odometerValueDate"*/}
                  {/*  render={(props) => {*/}
                  {/*    return (*/}
                  {/*      <CDatePicker*/}
                  {/*        {...props}*/}
                  {/*        required*/}
                  {/*        label={t('odometersSettingsPage.odometerValueDate')}*/}
                  {/*        withTime={true}*/}
                  {/*      />*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*/>*/}

                  <Field<number>
                    name="maxHourlyValue"
                    render={(props) => (
                      <Input
                        type="number"
                        label={t('odometersSettingsPage.maxHourlyValue')}
                        {...props}
                        required
                        data-cy-field="maxHourlyValue"
                      />
                    )}
                  />

                  <Field<number>
                    name="optimalConsumption"
                    render={(props) => (
                      <Input
                        type="number"
                        label={t('odometersSettingsPage.optimalConsumption')}
                        {...props}
                        required
                        data-cy-field="optimalConsumption"
                      />
                    )}
                  />
                </div>

                <div className={classNames(styles.column)}>
                  <Typography variant="h5" mb="24px">
                    {t('odometersSettingsPage.assigned')}
                  </Typography>

                  <Field<number>
                    name="branchOfficeId"
                    render={(props) => (
                      <Select
                        options={branchOfficesOptions}
                        label={t('odometersSettingsPage.branchOfficeName')}
                        {...props}
                        required
                        data-cy-field="branchOfficeId"
                        clearable
                      />
                    )}
                  />

                  <Field<number>
                    name="workshopId"
                    render={(props) => (
                      <Select
                        options={workshopsOptions}
                        label={t('odometersSettingsPage.workshopName')}
                        {...props}
                        data-cy-field="workshopId"
                        clearable
                      />
                    )}
                  />

                  <Field<number>
                    name="centerId"
                    render={(props) => (
                      <Select
                        options={centersOptions}
                        label={t('odometersSettingsPage.centerName')}
                        {...props}
                        data-cy-field="centerId"
                        clearable
                      />
                    )}
                  />

                  <Field<number>
                    name="groupId"
                    render={(props) => (
                      <Select
                        options={groupsOptions}
                        label={t('odometersSettingsPage.groupName')}
                        {...props}
                        data-cy-field="groupId"
                        clearable
                      />
                    )}
                  />

                  <div className={classNames(styles.column)}>
                    <Typography variant="h5" mb="24px">
                      {t('odometersSettingsPage.mapToOdometer')}
                    </Typography>

                    <Field<Array<OdometerMappingDto>>
                      name="odometerMappings"
                      render={(props) => (
                        <>
                          <List
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                              mb: 3,
                            }}
                          >
                            {props.value.map((item, index) => (
                              <>
                                <ListItem
                                  sx={index > 0 ? { bgcolor: '#eeeeee' } : {}}
                                  secondaryAction={
                                    index == 0 ? (
                                      <IconButton edge="end">
                                        <EditIcon
                                          onClick={() => setOpen(index)}
                                        />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={item.externalId}
                                    secondary={format(
                                      new Date(item.validFrom),
                                      'dd.MM.yyyy HH:mm:ss'
                                    )}
                                  />
                                </ListItem>
                                <Divider component="li" />
                              </>
                            ))}
                            {!addedMapping && (
                              <ListItem sx={{ justifyContent: 'space-evenly' }}>
                                {props.value.length > 0 && (
                                  <>
                                    <Button
                                      type="button"
                                      onClick={() => setOpen(-1)}
                                      variant="add"
                                    >
                                      Elektroměr
                                    </Button>
                                    <Button
                                      type="button"
                                      onClick={() => setOpen(-2)}
                                      variant="add"
                                    >
                                      Sběrnice
                                    </Button>
                                  </>
                                )}
                                {props.value.length === 0 && (
                                  <Button
                                    type="button"
                                    onClick={() => setOpen(-3)}
                                    variant="add"
                                  ></Button>
                                )}
                              </ListItem>
                            )}
                          </List>
                          <Dialog
                            open={open != null}
                            onClose={() => setOpen(null)}
                          >
                            <DialogTitle>Mapování</DialogTitle>
                            <DialogContent>
                              <SettingsOdometerMappingForm
                                onSubmit={(mapping) => {
                                  let value: Array<OdometerMappingDto> = [];
                                  const transformedMapping = {
                                    ...mapping,
                                    validFrom:
                                      format(mapping.validFrom, 'yyyy-MM-dd') +
                                      'T' +
                                      format(mapping.validFrom, 'HH:mm:ssxxx'),
                                  };
                                  if (open === -1 || open === -2) {
                                    value = [
                                      transformedMapping,
                                      ...props.value,
                                    ];
                                  } else if (open !== null) {
                                    let x = [...props.value];
                                    x.splice(open, 1, transformedMapping);
                                    value = x;
                                  }
                                  setAddedMapping(true);
                                  value.sort((a, b) => {
                                    if (a.validFrom > b.validFrom) return -1;
                                    if (a.validFrom < b.validFrom) return 1;
                                    return 0;
                                  });
                                  props.onChange(value as any);
                                  setOpen(null);
                                }}
                                onCancel={() => {
                                  setOpen(null);
                                }}
                                odometerMapping={
                                  open !== null && open >= 0
                                    ? props.value[open]
                                    : props.value?.[0]
                                }
                                type={open || 0}
                              />
                            </DialogContent>
                          </Dialog>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>

              <Box width="100%" display="flex" flexDirection="column">
                <Field<string>
                  name="comment"
                  render={(props) => (
                    <Textarea
                      label={t('odometersSettingsPage.comment')}
                      {...props}
                      data-cy-field="comment"
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  type="button"
                  size="large"
                  data-cy-button="cancel"
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                />
                <Button
                  disableElevation
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  data-cy-button="submit"
                  variant={dialogType}
                  onMouseDown={(e) => e.preventDefault()}
                />
              </Box>
            </Box>
          );
        }}
      </Form>
    </Box>
  );
};

export default SettingsOdometerForm;
