export const prettyUnit = (unit?: string) => {
  if (unit && unit.length > 0) {
    return (
      <>
        {unit.split('').map(char => {
          if (!isNaN(parseInt(char))) {
            return <sup>{char}</sup>
          }

          return char
        })}
      </>
    );
  }
  return <>{unit}</>;
};
