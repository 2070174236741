import { FC, useRef } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { v4 } from 'uuid';


import { FieldCommonProps } from '../../fields/types';
import { FieldProps } from '../../form/types';
import { TimePicker } from "@mui/x-date-pickers";

const useIds = () => {
  return useRef({
    input: v4(),
    label: v4(),
    helper: v4(),
  });
};

const CTimePicker: FC<
  FieldCommonProps<{
    withoutHelperText?: boolean;
  }> &
    FieldProps<Date | null>
> = ({
  label,
  help,
  onChange,
  error,
  required = false,
  value,
  onBlur,
  withoutHelperText = false,
}) => {
  const ids = useIds();

  return (
    <FormControl
      sx={{
        '& .MuiFormLabel-root': {
          maxWidth: 'calc(100% - 40px)',
        },
        ...(!!error && {
          '&, & svg, & .MuiFormLabel-root, & .MuiFormHelperText-root': {
            color: 'error.main',
          },
        }),
      }}
    >
      <TimePicker
        sx={{ backgroundColor: 'white' }}
        label={label}
        value={value || null}
        onChange={(value) => {
          onChange(value);
        }}
        slotProps={{
          textField: {
            error: !!error,
            id: ids.current.input,
            required,
            label,
            onBlur,
            placeholder: '',
          },
          actionBar: {
            actions: ['clear'],
          },
          popper: {
            placement: 'bottom-end',
          },
        }}
      />

      {!withoutHelperText && (
        <Box
          sx={{
            height: '25px',
          }}
        >
          <FormHelperText
            sx={{ marginTop: '0px', fontSize: '12px' }}
            id={ids.current.helper}
          >
            {error || help}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default CTimePicker;
