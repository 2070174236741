import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {TFunction} from 'i18next';
import * as yup from 'yup';

import {ReadoutDto} from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CDatePicker from '../ui/common/date-picker/CDatePicker';

import {useOdometers} from "@/hooks/data/use-odometers";
import Select from "@/components/ui/fields/Select";

const validationSchema = (t: TFunction) =>
  yup.object({
    odometerId: yup.number().required('validation.required'),
    date: yup.date().required('validation.required'),
    value: yup.number().required('validation.required'),
  });

export type ElectricityReadoutFormOutputValues = yup.InferType<ReturnType<typeof validationSchema>>;

export type ElectricityReadoutFormProps = {
  odometerId?: number;
  onSubmit: (values: ElectricityReadoutFormOutputValues) => void;
  onBack?: () => void;
  onCancel: () => void;
};

const ElectricityReadoutForm: FC<ElectricityReadoutFormProps> = ({
                                                                   odometerId,
                                                                             onSubmit,
                                                                             onBack,
                                                                             onCancel,
                                                                           }) => {
  const {t} = useTranslation();
  const dialogType = odometerId ? 'update' : 'add';

  const initialValues = {
    odometerId: odometerId || null,
    value: null,
    date: new Date(),
  };

  const handleSubmit = async (values: ElectricityReadoutFormOutputValues) => {
    onSubmit(values);
  };

  const [checked, setChecked] = useState(true);

  const {odometersOptions} = useOdometers();

  return (
    <Form
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({isSubmitting, resetForm}) => (
        <div style={{marginTop: 8, width: 375, display: 'flex', flexDirection: 'column'}}>
          <Field<number>
            name="odometerId"
            render={(props) => (
              <Select
                options={odometersOptions}
                label="Měřidlo"
                {...props}
                required
                data-cy-field="odometerId"
                clearable
              />
            )}
          />
          <Field<number>
            name="value"
            render={(props) => (
              <Input
                type="number"
                label="Hodnota"
                {...props}
                required
                data-cy-field="value"
              />
            )}
          />

          <FormControlLabel control={<Checkbox defaultChecked value={checked} onChange={() => setChecked(!checked)} />} label="Zadat k aktuálnímu datu" />
          {
            !checked &&
              <Field<Date>
                  name="date"
                  render={(props) => {
                    return (
                      <CDatePicker
                        {...props}
                        required
                        label="Datum a čas odečtu"
                        withTime={true}
                      />
                    );
                  }}
              />
          }



          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              type="button"
              size="large"
              data-cy-button="cancel"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
            <Button
              disableElevation
              type="submit"
              size="large"
              disabled={isSubmitting}
              data-cy-button="submit"
              variant={dialogType}
              onMouseDown={(e) => e.preventDefault()}
            />
          </Box>
        </div>
      )}
    </Form>
  );
};

export default ElectricityReadoutForm;
