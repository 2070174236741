import { FC, PropsWithChildren, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import { DashboardLayoutProvider } from '@/contexts/layout/DashboardLayoutContext';
import { useIsTouchDevice } from '@/hooks/use-is-touch-device';

import AppBar from './AppBar';
import Drawer from './Drawer';
import Main from './Main';

const DashboardLayout: FC<PropsWithChildren> = ({ children }) => {
  const isTouchDevice = useIsTouchDevice();
  const theme = useTheme();

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
  }, [theme]);

  return (
    <DashboardLayoutProvider>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          ...(!isTouchDevice && {
            '& .PrivateSwipeArea-root': {
              display: 'none',
            },
          }),
        }}
      >
        <AppBar />
        <Drawer />
        <Main>{children}</Main>
      </Box>
    </DashboardLayoutProvider>
  );
};

export default DashboardLayout;
