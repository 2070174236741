import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkshopContext } from '@/pages/settings-workshops-page/SettingsWorkshopPage';

import FilterItemWrapper from '../fields/FilterItemWrapper';
import Input from '../fields/Input';
import Select from '../fields/Select';

export type WorkshopFilter = {
  name?: string;
  branchOfficeId?: string;
};

export type WorkshopsFiltersProps = {
  updateFilter: (key: string, value: string) => void;
  filter: WorkshopFilter;
};

const WorkshopsFilters: FC<WorkshopsFiltersProps> = ({
  updateFilter,
  filter,
}) => {
  const { t } = useTranslation();
  const { branchOffices } = useContext(WorkshopContext);

  const filters = () => {
    return [
      <Input
        key="name"
        name="name"
        onChange={(value) => updateFilter('name', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('workshopsSettingsPage.name')}
        value={filter.name || ''}
      />,

      <Select
        key="branchOffice"
        name="branchOffice"
        options={branchOffices}
        onChange={(value) => updateFilter('branchOfficeId', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('workshopsSettingsPage.branchOffice')}
        value={filter.branchOfficeId || ''}
        clearable
      />,
    ];
  };

  return (
    <>
      {filters().map((filter) => (
        <FilterItemWrapper key={filter.key}>{filter}</FilterItemWrapper>
      ))}
    </>
  );
};

export default WorkshopsFilters;
