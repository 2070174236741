import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { CenterDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { SettingsCentersContext } from '@/pages/settings-centers-page/SettingsCentersPage';

import Select from '../ui/fields/Select';
import Textarea from '../ui/fields/Textarea';

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', { count: 4 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    branchOfficeId: yup.number().required('validation.required'),
    workshopId: yup.number().required('validation.required'),
    comment: yup
      .string()
      .nullable()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(255, t('validation.maxChars', { count: 255 }) as string),
  });

export type SettingsCenterFormInputValues = {
  name: string | null;
  branchOfficeId: number | null;
  workshopId: number | null;
  comment: string | null;
};

export type SettingsCenterFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type SettingsCenterFormProps = {
  center: CenterDto | null;
  onSubmit: (values: SettingsCenterFormOutputValues, id?: number) => void;
  onCancel: () => void;
};

const SettingsCenterForm: FC<SettingsCenterFormProps> = ({
  center,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { workshops, branchOffices } = useContext(SettingsCentersContext);
  const dialogType = center ? 'update' : 'add';

  const initialValues: SettingsCenterFormInputValues = {
    name: center?.name || null,
    branchOfficeId: center?.branchOffice?.id || null,
    workshopId: center?.workshop?.id || null,
    comment: center?.comment || null,
  };

  const handleSubmit = async (values: SettingsCenterFormInputValues) => {
    onSubmit(
      values as SettingsCenterFormOutputValues,
      center?.id ? center.id : undefined
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ isSubmitting, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('centersSettingsPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />

            <Field<string>
              name="branchOfficeId"
              render={(props) => (
                <Select
                  options={branchOffices}
                  label={t('odometersSettingsPage.branchOfficeName')}
                  {...props}
                  required
                  data-cy-field="branchOffice"
                  clearable
                />
              )}
            />
            <Field<string>
              name="comment"
              render={(props) => (
                <Textarea
                  label={t('centersSettingsPage.comment')}
                  {...props}
                  data-cy-field="comment"
                />
              )}
            />

            <Field<number>
              name="workshopId"
              render={(props) => (
                <Select
                  options={workshops}
                  label={t('centersSettingsPage.workshop')}
                  {...props}
                  required
                  data-cy-field="workshop"
                  clearable
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default SettingsCenterForm;
