import { createContext, FC, useCallback, useEffect, useState } from 'react';

import {
  AlarmHistoryEventDto,
  AlarmService,
  OdometerDto,
  OdometerService,
} from '@/_generatedApi';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import { Option } from '@/components/ui/fields/types';
import ElectricityAlarmsHistoryFilters, {
  ElectricityAlarmHistoryFilter,
} from '@/components/ui/filters/ElectricityAlarmsHistoryFilters';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateFilterData } from '@/utils/immutable';

export const ElectricityAlarmsHistoryContext = createContext<Option[] | null>(
  null
);

const ElectricityAlarmsHistoryPage: FC = () => {
  const [alarmsHistory, setAlarmsHistory] = useState<AlarmHistoryEventDto[]>(
    []
  );
  const [odometers, setOdometers] = useState<Option[]>([]);
  const [countOfAlarmsHistory, setCountOfAlarmsHistory] = useState(0);
  const { showGenericErrorToast } = useShowToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [filter, setFilter] = useState<ElectricityAlarmHistoryFilter>({});

  const alarmsHistoryColumnsDef: ColDef<AlarmHistoryEventDto>[] = [
    { key: 'odometer', nested: true, isDate: false, sortable: true },
    { key: 'reason', nested: false, isDate: false, sortable: true },
    { key: 'eventDate', nested: false, isDate: true, sortable: true },
  ];

  const fetchAlarmsHistory = useCallback(async () => {
    try {
      const alarmsHistory = await AlarmService.getAlarmConsumptionSummary({
        // Both interfaces are generated automatically based on the api definition, unfortunately incompatible
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(filter as any),
      });

      const alarmsHistoryData =
        alarmsHistory.data?.map((historyData, index) => {
          return {
            id: index,
            odometer: historyData.odometer,
            reason: historyData.reason,
            eventDate: historyData.eventDate,
          };
        }) || [];

      setAlarmsHistory(alarmsHistoryData);
      setCountOfAlarmsHistory(alarmsHistoryData.length || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [filter]);

  const fetchOdometers = useCallback(async () => {
    try {
      const odometers = await OdometerService.getOdometerList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      const mappedOdometersToSelect = odometers.data?.map((item): Option => {
        const odometer = item as OdometerDto;
        return {
          value: odometer?.id || 0,
          label: odometer?.name || '',
        };
      });

      if (mappedOdometersToSelect?.length) {
        setOdometers(mappedOdometersToSelect);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [odometers]);

  useEffect(() => {
    fetchOdometers();
  }, []);

  useEffect(() => {
    fetchAlarmsHistory();
  }, [filter, rowsPerPage, currentPage]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  return (
    <ElectricityAlarmsHistoryContext.Provider value={odometers}>
      <CDataGrid
        colDef={alarmsHistoryColumnsDef}
        translationKey="electricityPage.alarmHistoryPage"
        data={alarmsHistory}
        filters={
          <ElectricityAlarmsHistoryFilters
            updateFilter={updateFilter}
            filter={filter}
          />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfAlarmsHistory,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
    </ElectricityAlarmsHistoryContext.Provider>
  );
};
export default ElectricityAlarmsHistoryPage;
