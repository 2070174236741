import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { ErrorCodes } from '@/constants/errorCodes';
import { UserContext } from '@/contexts/user-context/UserContext';
import { useShowToast } from '@/hooks/use-show-toast';

const ApiClient: React.FC = () => {
  const { auth, logout } = useContext(UserContext);
  const { showToast } = useShowToast();
  const { t, i18n } = useTranslation();

  const token = auth?.token;

  useEffect(() => {
    axios.defaults.baseURL = import.meta.env.VITE_BE_API_URL;

    const requestInterceptor = axios.interceptors.request.use((config) => {
      if (config.headers) {
        if (auth?.isAuthenticated && token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['Accept-language'] = i18n.language;
      }

      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          auth?.isAuthenticated &&
          error.response.status === ErrorCodes.UNAUTHORIZED
        ) {
          logout();
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [
    token,
    logout,
    showToast,
    t,
    i18n.language,
    auth?.isAuthenticated,
    auth?.token,
  ]);

  return null;
};

export default ApiClient;
