import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { prettyFormatNumber } from '@/utils/format';

import styles from './odometerGaugeGraph.module.css';
import { prettyUnit } from "@/utils/formatString";

const OdometerGaugeCounter: FC<{ consumption: number, totalConsumption: number, unit?: string }> = ({
  consumption, totalConsumption, unit
}) => {
  const { t, i18n } = useTranslation();

  let consumptionLabel = unit;
  switch (consumptionLabel) {
    case 'kWh':
      consumptionLabel = 'příkon [kW]';
      break;
    case 'kVArh':
      consumptionLabel = 'výkon [kVAr]'
      break;
    default:
      consumptionLabel = `[${consumptionLabel}/hod]`
  }

  return (
    <div className={classNames(styles.counterWrapper)}>
      <div className={classNames(styles.consumption)}>
        {prettyFormatNumber(consumption, i18n.language)}
      </div>
      <div className={classNames(styles.graphUnit)}>
        {unit ? prettyUnit(consumptionLabel) : t('electricityReadingsPage.gauge.graphUnit')}
      </div>
      <div className={classNames(styles.counterTitle)}>
        {t('electricityReadingsPage.gauge.counterTitle')}
      </div>
      <div className={classNames(styles.counter)}>
        <div className={classNames(styles.valueWrapper)}>
          <div className={classNames(styles.value)}>
            {prettyFormatNumber(totalConsumption, i18n.language)}
          </div>
          <div className={classNames(styles.unit)}>
            {prettyUnit(unit) || t('electricityReadingsPage.gauge.counterUnit')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OdometerGaugeCounter;
