import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BreakdownRequest,
  OdometerConsumptionMultipleDto,
  OdometerService,
  PowerFactorDataDto,
  PowerFactorDataService,
} from '@/_generatedApi';
import { getStartAndEndDateByTimeInterval } from '@/utils/format';
import ReactECharts from 'echarts-for-react';
import ElectricityDashboardsFilters from '@/components/ui/filters/ElectricityDashboardFilters';
import { useDashboardFilter } from '@/hooks/useDashboardFilter';
import { Alert, Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import FilterItemWrapper from '@/components/ui/fields/FilterItemWrapper';
import Select from '@/components/ui/fields/Select';
import { usePowerFactors } from '@/hooks/data/usePowerFactors';
import { useRepeatFunctionPeriodically } from '@/hooks/use-interval';
import { DATA_REFRESH_INTERVAL } from '@/utils/variables';
import { format } from 'date-fns';

export const PowerFactorPage: FC = () => {
  const { t } = useTranslation();

  const { filter, updateFilter, remainingFiltersToFetch } = useDashboardFilter([
    'consumptionUnit',
    'timeInterval',
  ]);
  const [loadingData, setLoadingData] = useState(true);
  const { powerFactorsOptions } = usePowerFactors();
  const [powerFactorId, setPowerFactorId] = useState<number | undefined>(
    undefined
  );

  const [data, setData] = useState<PowerFactorDataDto[]>();

  const fetchData = useCallback(() => {
    setLoadingData(true);
    PowerFactorDataService.getEnergyPowerFactorConsumption({
      consumptionStartDate: filter.consumptionStartDate || '',
      consumptionEndDate: filter.consumptionEndDate || '',
      consumptionUnit: filter.consumptionUnit || '1 day',
      powerFactorId: powerFactorId,
    }).then((response) => {
      setData(response.data);
      setLoadingData(false);
    });
  }, [powerFactorId, filter]);

  useEffect(() => fetchData(), [filter, powerFactorId]);

  useRepeatFunctionPeriodically(() => {
    fetchData();
  }, DATA_REFRESH_INTERVAL);

  const optionForItem = (data: PowerFactorDataDto) => ({
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any) {
        params = params[0];
        var date = new Date(params.name);
        return format(date, 'dd.MM. HH:mm') + ' : ' + params.value[1];
      },
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      min: 0.5,
      max: 1,
    },
    visualMap: {
      show: false,
      pieces: [
        {
          lte: 1,
          gte: data.powerFactor?.cosPhiBreakingPoint || 0.95,
          color: '#4bcfa7'
        },
      ],
      outOfRange: {
        color: 'red'
      }
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'slider',
        labelFormatter: '',
      },
      {
        type: 'inside',
        zoomLock: true,
      },
    ],
    series: [
      {
        data: data.data.map((item) => ({
          name: item.date,
          value: [item.date, item.value],
        })),
        type: 'line',
        smooth: true,
      },
      {
        type: 'line',
        symbol: 'none',
        lineStyle: {
          color: 'rgba(255, 0, 0, 0.5)',
          width: 2,
          type: 'solid',
        },
        areaStyle: {
          color: 'transparent',
        },
        data:
          data.data.length > 0
            ? [
                [
                  data.data[0].date,
                  data.powerFactor?.cosPhiBreakingPoint || 0.95,
                ],
                [
                  data.data[data.data.length - 1].date,
                  data.powerFactor?.cosPhiBreakingPoint || 0.95,
                ],
              ]
            : [],
      },
    ],
  });

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Box sx={{ display: 'flex', gap: '10px', mb: 2 }}>
          <FilterItemWrapper>
            <Select
              name="type"
              options={powerFactorsOptions}
              onChange={(value) => {
                typeof value === 'number'
                  ? setPowerFactorId(value)
                  : setPowerFactorId(undefined);
              }}
              onBlur={() => {}}
              error=""
              required={false}
              value={powerFactorId || 0}
              withoutHelperText
              clearable
            />
          </FilterItemWrapper>
        </Box>
        <ElectricityDashboardsFilters
          filter={filter}
          updateFilter={updateFilter}
          withFilters={false}
        />
      </div>

      {remainingFiltersToFetch.length > 0 && (
        <Alert severity="info">
          {t('odometersSettingsPage.remainingFilters')}:
          <ul>
            {remainingFiltersToFetch.map((filterName) => (
              <li key={filterName}>
                {t(`electricityPage.dashboardPage.${filterName}`)}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      <div style={{ height: 24 }}>
        {(remainingFiltersToFetch.length > 0 || loadingData) && (
          <LinearProgress />
        )}
      </div>

      {data?.map((item) => (
        <Fragment key={item.powerFactor?.id}>
          <Typography variant="h2" sx={{ fontSize: '22px', mt: 4, mb: -2 }}>
            {item.powerFactor?.name}
          </Typography>
          <ReactECharts
            opts={{ locale: 'cs' }}
            style={{ height: 300, width: '100%' }}
            option={optionForItem(item)}
          />
        </Fragment>
      ))}
    </div>
  );
};
