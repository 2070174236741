import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import * as yup from 'yup';

import { UserDto, UserRequestDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Password from '@/components/ui/fields/Password';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { phoneSchema } from '@/utils/validation';

import Select from '../ui/fields/Select';
import { Option } from '../ui/fields/types';

export type SettingsUsersFormInputValues = {
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  role: string | null;
  registrationPassword: string | null;
};

export type SettingsUsersFormProps = {
  user: UserDto | null;
  onSubmit: (values: UserRequestDto, id?: number) => void;
  onCancel: () => void;
};

const SettingsUsersForm: FC<SettingsUsersFormProps> = ({
  user,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dialogType = user ? 'update' : 'add';

  const validationSchema = yup.object({
    username: yup
      .string()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    firstName: yup
      .string()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    lastName: yup
      .string()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    email: yup
      .string()
      .email()
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    phone: phoneSchema,
    role: yup.string().required('validation.required'),
    registrationPassword: user
      ? yup
          .string()
          .nullable()
          .min(4, t('validation.minChars', { count: 4 }) as string)
          .max(50, t('validation.maxChars', { count: 50 }) as string)
      : yup
          .string()
          .min(4, t('validation.minChars', { count: 4 }) as string)
          .max(50, t('validation.maxChars', { count: 50 }) as string)
          .required('validation.required'),
  });

  const initialValues = {
    username: user?.username || null,
    firstName: user?.firstName || null,
    lastName: user?.lastName || null,
    email: user?.email || null,
    phone: user?.phone || null,
    role: user?.role || null,
    registrationPassword: null,
  };

  const handleSubmit = async (values: SettingsUsersFormInputValues) => {
    onSubmit(values as UserRequestDto, user?.id ? user.id : undefined);
  };

  const rolesOptions = Object.keys(UserRequestDto.role).map((role) => ({
    label: t(`enums.userRoles.${role}`),
    value: role,
  })) as Option[];

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="firstName"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('usersSettingsPage.firstName')}
                  {...props}
                  required
                  data-cy-field="firstName"
                />
              )}
            />

            <Field<string>
              name="lastName"
              render={(props) => (
                <Input
                  label={t('usersSettingsPage.lastName')}
                  {...props}
                  required
                  data-cy-field="lastName"
                />
              )}
            />
            <Field<string>
              name="email"
              render={(props) => (
                <Input
                  label={t('usersSettingsPage.email')}
                  {...props}
                  required
                  data-cy-field="email"
                />
              )}
            />
            <Field<string>
              name="phone"
              render={(props) => (
                <Input
                  label={t('usersSettingsPage.phone')}
                  {...props}
                  required
                  data-cy-field="phone"
                />
              )}
            />
            <Field<string>
              name="role"
              render={(props) => (
                <Select
                  label={t('usersSettingsPage.role')}
                  {...props}
                  required
                  data-cy-field="role"
                  options={rolesOptions}
                  clearable
                />
              )}
            />
            <Field<string>
              name="username"
              render={(props) => (
                <Input
                  label={t('usersSettingsPage.username')}
                  {...props}
                  required
                  data-cy-field="userName"
                />
              )}
            />
            {!user && (
              <Field<string>
                name="registrationPassword"
                render={(props) => (
                  <Password
                    label={t('usersSettingsPage.registrationPassword')}
                    {...props}
                    required={!user}
                    data-cy-field="registrationPassword"
                  />
                )}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default SettingsUsersForm;
