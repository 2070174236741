import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';

import { AlarmDto, UserDto, UserService } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Checkbox from '@/components/ui/fields/Checkbox';
import Input from '@/components/ui/fields/Input';
import { Option } from '@/components/ui/fields/types';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { ElectricityAlarmsContext } from '@/pages/electricity-alarms-page/ElectricityAlarmsPage';
import { phoneSchema } from '@/utils/validation';

import Select from '../ui/fields/Select';
import CDatePicker from '@/components/ui/common/date-picker/CDatePicker';
import CTimePicker from '@/components/ui/common/time-picker/CTimePicker';

const validationSchema = yup.object({
  odometerId: yup.number().required('validation.required'),
  value: yup
    .number()
    .min(0, 'validation.zeroOrMore')
    .max(100, 'validation.hundredOrLess')
    .required('validation.required'),
  usersId: yup.array().of(yup.number()).min(1, 'validation.required'),
  externalEmail: yup.string().email().nullable(),
  externalPhone: yup
    .string()
    .nullable()
    .matches(/^\+\d+$/, 'validation.phone'),
  sendByPhone: yup.boolean(),
  sendByEmail: yup.boolean(),
  type: yup.number().required('validation.required'),
  fromDate: yup.date().required('validation.required'),
  intervalUnit: yup.string().required('validation.required'),
  intervalValue: yup.number().required('validation.required'),
  periodUnit: yup.string().nullable(),
  periodValue: yup.number().nullable(),
  textTemplate: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
});

export type ElectricityAlarmFormProps = {
  alarm: AlarmDto | null;
  onSubmit: (
    values: ElectricityAlarmFormOutputValues,
    id?: number | null
  ) => void;
  onCancel: () => void;
};

export type ElectricityAlarmFormInputValues = {
  odometerId: number | null;
  value: number | null;
  usersId: number[];
  externalEmail: string | null;
  externalPhone: string | null;
  sendByPhone: boolean | null;
  sendByEmail: boolean | null;
  type: number | null;
  textTemplate: string | null;
  fromDate: Date | null;
  toDate: Date | null;
  intervalUnit: string | null;
  intervalValue: number | null;
  periodUnit: string | null;
  periodValue: number | null;
  name: string | null;
};

export type ElectricityAlarmFormOutputValues = yup.InferType<
  typeof validationSchema
>;

const ElectricityAlarmForm: FC<ElectricityAlarmFormProps> = ({
  alarm,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const odometers = useContext(ElectricityAlarmsContext);
  const dialogType = alarm ? 'update' : 'add';

  const { showGenericErrorToast } = useShowToast();

  const [users, setUsers] = useState<Option[]>([]);

  const initialValues: ElectricityAlarmFormInputValues = {
    odometerId: alarm?.odometer?.id || null,
    value: Number(alarm?.value) || null,
    usersId: alarm?.users?.map((user) => user.id || 0) || [],
    externalEmail: alarm?.externalEmail || null,
    externalPhone: alarm?.externalPhone || null,
    sendByPhone: alarm?.sendByPhone || false,
    sendByEmail: alarm?.sendByEmail || false,
    type: alarm?.type || 1,
    fromDate: new Date(alarm?.fromDate as string) || null,
    toDate: new Date(alarm?.toDate as string) || null,
    intervalUnit: alarm?.intervalUnit || null,
    intervalValue: alarm?.intervalValue || null,
    periodUnit: alarm?.periodUnit || null,
    periodValue: alarm?.periodValue || null,
    textTemplate:
      alarm?.textTemplate ||
      'Alarm spuštěn, období od {{ od }} - {{ do }}. Dosažená hodnota {{ hodnota }}.',
    name: alarm?.name || null,
  };

  const fetchUsers = useCallback(async () => {
    try {
      const users = await UserService.getUserList({
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      const mappedUsersToSelect = users.data?.map((item): Option => {
        const user = item as UserDto;
        return {
          value: user.id || 0,
          label: `${user.firstName} ${user.lastName}` || 0,
        };
      });

      if (mappedUsersToSelect?.length) {
        setUsers(mappedUsersToSelect);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [showGenericErrorToast]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSubmit = async (values: ElectricityAlarmFormInputValues) => {
    onSubmit(
      values as ElectricityAlarmFormOutputValues,
      alarm ? alarm.id : undefined
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="odometerId"
              render={(props) => (
                <Select
                  autoFocus
                  options={odometers || []}
                  label={t('electricityPage.alarmPage.odometer')}
                  {...props}
                  required
                  data-cy-field="odometer"
                  clearable
                />
              )}
            />
            <Field<number>
              name="type"
              render={(props) => (
                <Select
                  options={[
                    { value: 1, label: 'Přesažení hodnoty v intervalu' },
                    { value: 2, label: 'Nedosažení hodnoty v intervalu' },
                    {
                      value: 3,
                      label:
                        'Neexistuje období s hodnotou nižší než nastavená v intervalu',
                    },
                    {
                      value: 4,
                      label:
                        'Existuje období s hodnotou vyšší než nastavená v intervalu',
                    },
                  ]}
                  label={t('electricityPage.alarmPage.type')}
                  {...props}
                  required
                  clearable
                />
              )}
            />

            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  type="text"
                  label={t('electricityPage.alarmPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />

            <Field<number>
              name="value"
              render={(props) => (
                <Input
                  type="number"
                  label={t('electricityPage.alarmPage.value')}
                  {...props}
                  required
                  data-cy-field="value"
                />
              )}
            />
            <div style={{ display: 'flex' }}>
              <Field<number>
                name="intervalValue"
                render={(props) => (
                  <Input
                    type="number"
                    label={t('electricityPage.alarmPage.intervalValue')}
                    {...props}
                    required
                    data-cy-field="value"
                  />
                )}
              />
              <Field<number>
                name="intervalUnit"
                render={(props) => (
                  <Select
                    autoFocus
                    options={[
                      { value: 'minute', label: 'minuta' },
                      { value: 'hour', label: 'hodina' },
                      { value: 'day', label: 'den' },
                      { value: 'week', label: 'týden' },
                      { value: 'month', label: 'měsíc' },
                    ]}
                    {...props}
                    required
                    clearable
                  />
                )}
              />
            </div>
            {values.type && values.type > 2 && (
              <div style={{ display: 'flex' }}>
                <Field<number>
                  name="periodValue"
                  render={(props) => (
                    <Input
                      type="number"
                      label={t('electricityPage.alarmPage.periodValue')}
                      {...props}
                      required
                      data-cy-field="value"
                    />
                  )}
                />
                <Field<number>
                  name="periodUnit"
                  render={(props) => (
                    <Select
                      autoFocus
                      options={[
                        { value: 'minute', label: 'minuta' },
                        { value: 'hour', label: 'hodina' },
                        { value: 'day', label: 'den' },
                        { value: 'week', label: 'týden' },
                        { value: 'month', label: 'měsíc' },
                      ]}
                      {...props}
                      required
                      clearable
                    />
                  )}
                />
              </div>
            )}
            {
              values.intervalUnit && ['minute', 'hour', 'day'].includes(values.intervalUnit) &&
              <div style={{ display: 'flex' }}>
                <Field<Date>
                  name="fromDate"
                  render={(props) => {
                    return (
                      <CTimePicker
                        {...props}
                        required
                        label={t('electricityPage.alarmPage.fromDate')}
                      />
                    );
                  }}
                />
                <Field<Date>
                  name="toDate"
                  render={(props) => {
                    return (
                      <CTimePicker
                        {...props}
                        required
                        label={t('electricityPage.alarmPage.toDate')}
                      />
                    );
                  }}
                />
              </div>
            }
            <Field<string>
              name="usersId"
              render={(props) => (
                <Select
                  options={users}
                  label={t('electricityPage.alarmPage.users')}
                  {...props}
                  required
                  data-cy-field="odometer"
                  multiple
                  clearable
                />
              )}
            />
            <Box>
              <Typography variant="h6">
                {t('electricityPage.alarmPage.notify')}
              </Typography>
              <Field<boolean>
                name="sendByPhone"
                render={(props) => (
                  <Checkbox
                    label={t('electricityPage.alarmPage.sendByPhone')}
                    {...props}
                    data-cy-field="sendByPhone"
                  />
                )}
              />
              <Field<boolean>
                name="sendByEmail"
                render={(props) => (
                  <Checkbox
                    label={t('electricityPage.alarmPage.sendByEmail')}
                    {...props}
                    data-cy-field="sendByEmail"
                  />
                )}
              />
            </Box>
            <Field<string>
              name="externalEmail"
              render={(props) => (
                <Input
                  label={t('electricityPage.alarmPage.externalEmail')}
                  {...props}
                  required
                  data-cy-field="externalEmail"
                />
              )}
            />
            <Field<string>
              name="externalPhone"
              render={(props) => (
                <Input
                  label={t('electricityPage.alarmPage.externalPhone')}
                  {...props}
                  required
                  data-cy-field="externalPhone"
                />
              )}
            />

            <Field<string>
              name="textTemplate"
              render={(props) => (
                <Input
                  type="text"
                  label={t('electricityPage.alarmPage.textTemplate')}
                  {...props}
                  required
                  multiline
                  data-cy-field="value"
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ElectricityAlarmForm;
