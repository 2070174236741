/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OdometerConsumptionDataDto } from './OdometerConsumptionDataDto';
import type { OdometerHeaderDto } from './OdometerHeaderDto';

export type OdometerConsumptionSingleDto = {
  unit: OdometerConsumptionSingleDto.unit;
  consumptionData: Array<OdometerConsumptionDataDto>;
  odometer: OdometerHeaderDto;
};

export namespace OdometerConsumptionSingleDto {
  export enum unit {
    _1_MINUTE = '1 minute',
    _15_MINUTES = '15 minutes',
    _1_HOUR = '1 hour',
    _1_DAY = '1 day',
    _1_WEEK = '1 week',
    _1_MONTH = '1 month',
    _3_MONTHS = '3 months',
    _6_MONTHS = '6 months',
    _1_YEAR = '1 year',
  }
}
