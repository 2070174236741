import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Typography } from '@mui/material';
import classNames from 'classnames';

import {
  CancelablePromise,
  OdometerConsumptionMultipleDto,
  OdometerService,
} from '@/_generatedApi';
import ElectricityDashboardsFilters from '@/components/ui/filters/ElectricityDashboardFilters';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useRepeatFunctionPeriodically } from '@/hooks/use-interval';
import { useShowToast } from '@/hooks/use-show-toast';
import { DATA_REFRESH_INTERVAL } from '@/utils/variables';
import LinearProgress from '@mui/material/LinearProgress';

import styles from './online.module.css';
import ElectricityTrendGraph from '@/components/graphs/ElectricityTrendGraph';
import { useDashboardFilter } from '@/hooks/useDashboardFilter';
import OdometerCharts from './OdometerCharts';
import { Medium } from "@/constants/mediums";

type OnlineProps = {
  medium: Medium,
  allowMinuteData: boolean,
}

const Online: FC<OnlineProps> = ({medium, allowMinuteData }) => {
  const { showGenericErrorToast } = useShowToast();
  const [odometersConsumption, setOdometersConsumption] = useState<
    OdometerConsumptionMultipleDto[]
  >([]);
  const { t } = useTranslation();

  const { filter, updateFilter, remainingFiltersToFetch } = useDashboardFilter([
    'consumptionUnit',
    'timeInterval',
  ], medium);

  const [loadingData, setLoadingData] = useState(true);

  const [showSum, setShowSum] = useState(false);
  const [showMinuteData, setShowMinuteData] = useState(false);
  const [showTrendData, setShowTrendData] = useState(false);
  const [promise, setPromise] = useState<CancelablePromise<any>>();

  const readyToFetchConsumptionData = useCallback((): boolean => {
    return !!filter.consumptionUnit;
  }, [filter]);

  const fetchOdometersConsumption = useCallback(async () => {
    try {
      setLoadingData(true);
      if (!promise?.isCancelled) {
        promise?.cancel();
      }

      const newPromise = OdometerService.getOdometerConsumption({
        ...(filter as any),
        medium,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      newPromise.then((response) => {
        if (response.data) {
          setPromise(undefined);
          setLoadingData(false);
          setOdometersConsumption(response.data);
        }
      });

      setPromise(newPromise);
    } catch (e) {
      console.log(e);
      showGenericErrorToast();
    }
  }, [filter, showGenericErrorToast]);

  useEffect(() => {
    if (readyToFetchConsumptionData()) {
      fetchOdometersConsumption();
    }
  }, [
    fetchOdometersConsumption,
    filter,
    showGenericErrorToast,
    readyToFetchConsumptionData,
  ]);

  useRepeatFunctionPeriodically(() => {
    if (readyToFetchConsumptionData()) {
      fetchOdometersConsumption();
    }
  }, DATA_REFRESH_INTERVAL);

  const sumData = useMemo(() => {
    const values = new Map<string, OdometerConsumptionMultipleDto>();
    odometersConsumption.forEach((item) => {
      const unit = item.odometer.unit;
      const value = values.get(unit);
      if (value) {
        value.odometer.optimalConsumption += item.odometer.optimalConsumption;
        value.odometer.maxHourlyValue += item.odometer.maxHourlyValue;

        item.data.forEach((consumption) => {
          const foundValue = value.data.find(
            (row) => row.fromDate === consumption.fromDate
          );
          if (foundValue) {
            foundValue.value += consumption.value;
          }
        });

        values.set(unit, value);
      } else {
        const value: OdometerConsumptionMultipleDto = {
          odometer: {
            id: 0,
            unit: unit,
            name: unit,
            optimalConsumption: item.odometer.optimalConsumption,
            maxHourlyValue: item.odometer.maxHourlyValue,
          },
          data: [...item.data.map((_) => ({ ..._ }))],
          lastTwoDayData: [],
          quarterHourData: [],
          lastMonthAverage: 0,
          totalConsumption: 0,
          minuteData: [],
          lastThreeMonthData: [],
        };
        values.set(unit, value);
      }
    });
    return values;
  }, [odometersConsumption]);

  const renderOdometers = () => {
    if (!showSum) {
      return odometersConsumption.map((odometerEntity) => (
        <OdometerCharts
          key={odometerEntity.odometer.id}
          odometerEntity={odometerEntity}
          filter={filter}
          forceShowMinuteData={showMinuteData}
          forceShowTrendData={showTrendData}
          allowMinuteData={allowMinuteData}
          medium={medium}
        />
      ));
    } else {
      const values: any[] = [];
      sumData.forEach((sumItem) => {
        values.push(
          <>
            <Typography variant="h2" sx={{ fontSize: '22px' }}>
              {sumItem.odometer.name}
            </Typography>
            <ElectricityTrendGraph
              data={sumItem}
              unit={filter.consumptionUnit}
              setDate={() => {}}
              medium={medium}
            />
          </>
        );
      });
      return values;
    }
  };

  return (
    <div className={classNames(styles.page)}>
      <div className={classNames(styles.filtersWrapper)}>
        <ElectricityDashboardsFilters
          filter={filter}
          updateFilter={updateFilter}
          showSum={showSum}
          setShowSum={setShowSum}
          showMinuteData={allowMinuteData ? showMinuteData : undefined}
          setShowMinuteData={setShowMinuteData}
          showTrendData={allowMinuteData ? showTrendData : undefined}
          setShowTrendData={setShowTrendData}
          medium={medium}
        />
      </div>

      {remainingFiltersToFetch.length > 0 && (
        <Alert severity="info">
          {t('odometersSettingsPage.remainingFilters')}:
          <ul>
            {remainingFiltersToFetch.map((filterName) => (
              <li key={filterName}>
                {t(`electricityPage.dashboardPage.${filterName}`)}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      <div style={{ height: 24 }}>
        {(remainingFiltersToFetch.length > 0 || loadingData) && (
          <LinearProgress />
        )}
      </div>

      {renderOdometers()}
    </div>
  );
};

export default Online;
