import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { GroupDto } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';

import Textarea from '../ui/fields/Textarea';

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, t('validation.minChars', { count: 4 }) as string)
      .max(50, t('validation.maxChars', { count: 50 }) as string)
      .required('validation.required'),
    comment: yup
      .string()
      .nullable()
      .min(2, t('validation.minChars', { count: 2 }) as string)
      .max(255, t('validation.maxChars', { count: 255 }) as string),
  });

export type GroupFormInputValues = {
  name: string | null;
  comment: string | null;
};

export type GroupFormOutputValues = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export type GroupFormProps = {
  group: GroupDto | null;
  onSubmit: (values: GroupFormOutputValues, id?: number) => void;
  onCancel: () => void;
};

const GroupForm: FC<GroupFormProps> = ({
  group,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dialogType = group ? 'update' : 'add';

  const initialValues: GroupFormInputValues = {
    name: group?.name || null,
    comment: group?.comment || null,
  };

  const handleSubmit = async (values: GroupFormInputValues) => {
    onSubmit(
      values as GroupFormOutputValues,
      group ? group.id : undefined
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ isSubmitting, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="name"
              render={(props) => (
                <Input
                  autoFocus
                  label={t('groupsSettingsPage.name')}
                  {...props}
                  required
                  data-cy-field="name"
                />
              )}
            />

            <Field<string>
              name="comment"
              render={(props) => (
                <Textarea
                  label={t('groupsSettingsPage.comment')}
                  {...props}
                  data-cy-field="comment"
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default GroupForm;
