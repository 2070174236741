import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { COLORS } from '@/utils/variables';

const theme = createTheme({
  palette: {
    primary: COLORS.purple,
    secondary: COLORS.turquoise,
    error: COLORS.error,
    grey: {
      '400': COLORS.gray.main,
      '700': COLORS.gray.dark,
    },
    red: {
      light: COLORS.red.light,
      main: COLORS.red.main,
    },
    turquoise: {
      light: COLORS.turquoise.light,
      main: COLORS.turquoise.main,
    },
    blue: {
      light: COLORS.blue.light,
      main: COLORS.blue.main,
    },
    yellow: {
      light: COLORS.yellow.light,
      main: COLORS.yellow.main,
    },
    background: {
      default: COLORS.gray.light,
      paper: COLORS.white.main,
    },
  },
  typography: {
    fontFamily: 'transducer',
    h1: {
      fontSize: 34,
      fontWeight: 500,
    },
    h2: {
      fontSize: 32,
      fontWeight: 500,
    },
    h3: {
      fontSize: 28,
      fontWeight: 500,
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState: { variant, color } }) => ({
          ...(!!(variant === 'contained' && color === 'secondary') && {
            color: COLORS.purple.main,
            '&:hover': {
              backgroundColor: COLORS.turquoise.light,
            },
          }),
        }),
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    red: Palette['primary'];
    turquoise: Palette['primary'];
    blue: Palette['primary'];
    yellow: Palette['primary'];
  }

  interface PaletteOptions {
    red?: PaletteOptions['primary'];
    turquoise?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
  }
}

const MuiThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          color: COLORS.purple.main,
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
