import { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import {
  OdometerConsumptionMultipleDto,
  OdometerConsumptionSingleDto,
} from '@/_generatedApi';
import 'echarts/i18n/langCS';
import { format } from 'date-fns';
import { Medium } from '@/constants/mediums';
import { prettyUnit } from "@/utils/formatString";

const ElectricityTrendGraph: FC<{
  data: OdometerConsumptionMultipleDto;
  unit?: OdometerConsumptionSingleDto.unit;
  setDate: (date: Date) => void;
  medium: Medium;
}> = ({ data, unit, setDate, medium }) => {
  let maximumCoefficient = 1;
  switch (unit) {
    case OdometerConsumptionSingleDto.unit._1_MINUTE:
      maximumCoefficient = 1 / 60;
      break;
    case OdometerConsumptionSingleDto.unit._15_MINUTES:
      maximumCoefficient = 1 / 4;
      break;
    case OdometerConsumptionSingleDto.unit._1_DAY:
      maximumCoefficient = 24;
      break;
    case OdometerConsumptionSingleDto.unit._1_WEEK:
      maximumCoefficient = 24 * 7;
      break;
    case OdometerConsumptionSingleDto.unit._1_MONTH:
      maximumCoefficient = 24 * 30;
      break;
    case OdometerConsumptionSingleDto.unit._3_MONTHS:
      maximumCoefficient = 24 * 91;
      break;
    case OdometerConsumptionSingleDto.unit._6_MONTHS:
      maximumCoefficient = 24 * 182;
      break;
    case OdometerConsumptionSingleDto.unit._1_YEAR:
      maximumCoefficient = 24 * 365;
      break;
  }

  const option = {
    title: {
      text: 'Průběžná spotřeba',
      subtext: '',
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any) {
        const param = params[0];
        return (
          format(param.value[0], 'dd.MM. HH:mm') +
          ': ' +
          Math.round(param.value[1] * 100) / 100 +
          ' ' +
          data.odometer.unit.replace('3', '³')
        );
      },
      axisPointer: {
        type: 'cross',
      },
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true,
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisLabel: {
        formatter: (value: Date) => format(value, 'dd.MM. HH:mm'),
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: `{value} ${data.odometer.unit?.replace('3', '³') || ''}`,
      },
      axisPointer: {
        snap: true,
      },
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gte: 0,
          lte: data.odometer.optimalConsumption * maximumCoefficient,
          color:
            medium === Medium.electricity ? '#4bcfa7' : (medium === Medium.water ? '#5470C6' : '#FFD700'),
        },
        {
          gt: data.odometer.optimalConsumption * maximumCoefficient,
          lte: data.odometer.maxHourlyValue * maximumCoefficient,
          color: 'orange',
        },
      ],
      outOfRange: {
        color: 'red',
      },
    },
    dataZoom: [
      {
        type: 'slider',
        labelFormatter: '',
      },
      {
        type: 'inside',
        zoomLock: true,
      },
    ],
    series: [
      {
        name: 'Electricity',
        type: 'line',
        smooth: true,
        // prettier-ignore
        data: data.data.map(value => [new Date(value.fromDate), value.value]),
        // markArea: {
        //   itemStyle: {
        //     color: 'rgba(255, 173, 177, 0.4)'
        //   },
        //   data: [
        //     [
        //       {
        //         name: 'Alarm 1',
        //         xAxis: '07:30'
        //       },
        //       {
        //         xAxis: '10:00'
        //       }
        //     ],
        //     [
        //       {
        //         name: 'Alarm 2',
        //         xAxis: '17:30'
        //       },
        //       {
        //         xAxis: '22:30'
        //       }
        //     ]
        //   ]
        // }
      },
    ],
  };

  return (
    <ReactECharts
      opts={{ locale: 'cs' }}
      style={{ height: 300, width: '100%' }}
      option={option}
      onChartReady={(chart) => {
        const zr = chart.getZr();
        zr.on('click', (params: any) => {
          const pointInPixel = [params.offsetX, params.offsetY];
          const pointInGrid = chart.convertFromPixel('series', pointInPixel);

          setDate(new Date(pointInGrid[0] + 450000));
        });
      }}
    />
  );
};

export default ElectricityTrendGraph;
