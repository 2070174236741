/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PowerFactorDataDto } from '../models/PowerFactorDataDto';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PowerFactorDataService {
  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getEnergyPowerFactorConsumption({
    consumptionStartDate,
    consumptionEndDate,
    consumptionUnit,
    powerFactorId,
  }: {
    consumptionStartDate: string;
    consumptionEndDate: string;
    consumptionUnit:
      | '1 minute'
      | '15 minutes'
      | '1 hour'
      | '1 day'
      | '1 week'
      | '1 month'
      | '3 months'
      | '6 months'
      | '1 year';
    powerFactorId?: number;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<PowerFactorDataDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/energy/power-factor/consumption',
      query: {
        consumptionStartDate: consumptionStartDate,
        consumptionEndDate: consumptionEndDate,
        consumptionUnit: consumptionUnit,
        powerFactorId: powerFactorId,
      },
    });
  }
}
