import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

import {ConsumptionDto} from '@/_generatedApi';
import { Months } from '@/constants/months';
import { prettyFormatNumber } from '@/utils/format';

import OverviewCard from '../ui/overview-card/OverviewCard';
import {groupBy} from "@/utils/functions";
import { Medium } from "@/constants/mediums";

const ElectricityConsumptionGraph: FC<{
  data: ConsumptionDto[];
}> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const lastYearLabel = t('dashboardPage.lastYearValue');

  const yearData = groupBy(data, item => (new Date(item.fromDate)).getFullYear())
  const thisYear = (new Date()).getFullYear();
  const lastYear = thisYear - 1;

  const months = Months.map((month) => t(`dashboardPage.months.${month}`));

  const medium = data[0]?.medium.type;
  const mainColor = medium == Medium.electricity ? '#4BCFA7' : (medium == Medium.water ? '#5470C6' : '#FFD700');
  const accentColor = medium == Medium.electricity ? '#8fe6d3' : (medium == Medium.water ? '#9AA9CF' : '#FFDAA4');

  const option = {
    grid: { top: 40, right: 10, bottom: 80, left: 60 },

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      formatter: lastYearLabel,
      data: [lastYearLabel],
      bottom: 0,
      left: 0,
    },
    xAxis: [
      {
        type: 'category',
        data: months,
      },
    ],
    yAxis: [
      {
        name: data[0]?.unit?.replace('3', '³'),
        type: 'value',
        axisLabel: {
          formatter: (value: number) =>
            prettyFormatNumber(value, i18n.language),
        },
      },
    ],
    series: [
      {
        type: 'bar',
        zlevel: 2,
        emphasis: {
          focus: 'series',
        },
        name: t('dashboardPage.currentYearValue'),
        itemStyle: { color: mainColor },
        barGap: -0.4,
        data: yearData.get(thisYear)?.map(item => item.consumption),
      },
      {
        name: lastYearLabel,
        zlevel: 1,
        type: 'bar',
        itemStyle: { color: accentColor },
        emphasis: {
          focus: 'series',
        },
        data: yearData.get(lastYear)?.map(item => item.consumption),
      },
    ],
  };

  return (
    <OverviewCard
      title={{ text: data[0]?.medium.name, variant: 'h5' }}
      description={t('dashboardPage.electricityGraphDescription')}
    >
      <ReactECharts style={{ height: 200, width: '100%' }} option={option} />
    </OverviewCard>
  );
};

export default ElectricityConsumptionGraph;
