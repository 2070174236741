import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'wouter';

import OdometerDetail from '@/components/odometer-detail/OdometerDetail';
import { ROUTE_PATHS } from '@/Routes';

import styles from './detail.module.css';
import { Medium } from '@/constants/mediums';

type ReadingsDetailPageProps = {
  medium: Medium;
};

const ReadingsDetailPage: FC<ReadingsDetailPageProps> = ({medium}) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  return (
    <div className={classNames(styles.page)}>
      <div>
        <Button
          variant="outlined"
          type="button"
          size="large"
          data-cy-button="back"
          startIcon={<BackIcon />}
          onClick={() =>
            setLocation(
              `${ROUTE_PATHS.ENERGY}/${medium}${medium === Medium.electricity ? ROUTE_PATHS.READINGS : (medium === Medium.water ? ROUTE_PATHS.WATER_READINGS : ROUTE_PATHS.GAS_READINGS)}`
            )
          }
        >
          {t('common.back')}
        </Button>
      </div>
      <OdometerDetail route={medium === Medium.electricity ? ROUTE_PATHS.READING_DETAIL : (medium === Medium.water ? ROUTE_PATHS.WATER_READING_DETAIL : ROUTE_PATHS.GAS_READING_DETAIL)} />
    </div>
  );
};

export default ReadingsDetailPage;
