import { FC, useContext } from 'react';
import { Drawer as MuiDrawer, SwipeableDrawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DashboardLayoutContext } from '@/contexts/layout/DashboardLayoutContext';
import { useIsMobile } from '@/hooks/use-is-mobile';

import DrawerBody from './DrawerBody';

const Drawer: FC = () => {
  const theme = useTheme();

  const isMobile = useIsMobile();

  const {
    open: [open, setOpen],
  } = useContext(DashboardLayoutContext);

  const DRAWER_WIDTH = 255;

  if (isMobile) {
    return (
      <SwipeableDrawer
        sx={{
          minWidth: `${DRAWER_WIDTH}px`,
          '& .MuiDrawer-paper': {
            height: '100%',
            minWidth: `${DRAWER_WIDTH}px`,
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
          },
        }}
        anchor="left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <DrawerBody />
      </SwipeableDrawer>
    );
  }

  return (
    <MuiDrawer
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '&, & .MuiDrawer-paper': {
          width: `calc(${theme.spacing(isMobile ? 7 : 8)} + 1px)`,
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ...(open && {
            width: `${DRAWER_WIDTH}px`,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }),
        },
        '& .MuiDrawer-paper': {
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        },
      }}
      variant="permanent"
      anchor="left"
      open={open}
    >
      <DrawerBody />
    </MuiDrawer>
  );
};

export default Drawer;
