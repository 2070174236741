import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
GroupDto, GroupRequestDto, GroupService,
} from '@/_generatedApi';
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import Modal from '@/components/ui/common/modal/CModal';
import { Option } from '@/components/ui/fields/types';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateFilterData, updateObjectInArray } from '@/utils/immutable';
import { getSkipCount } from '@/utils/request';
import { nullFieldsToUndefined } from '@/utils/validation';
import GroupsFilters, {GroupFilter} from "@/components/ui/filters/GroupsFilters";
import GroupForm, {GroupFormOutputValues} from "@/components/settings-groups/SettingsGroupForm";

const fetchAllGroups = async () => {
  return (
    await GroupService.getGroupList({
      sortBy: 'name',
      descending: false,
      skip: 0,
      take: MAX_TAKE_ITEMS,
      deleted: false
    })
  ).data as GroupDto[];
};

const fetchGroupsForPage = async (
  page: number,
  itemsPerPage: number,
  filter: GroupFilter
) => {
  const skip = getSkipCount(page, itemsPerPage);

  return await GroupService.getGroupList({
    sortBy: 'name',
    descending: false,
    skip,
    take: itemsPerPage,
    deleted: false,
    ...filter,
  });
};

export const GroupContext = createContext<Option[] | null>(null);

const SettingsGroupPage: FC = () => {
  const { t } = useTranslation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const [showModal, setShowModal] = useState(false);
  const [countOfGroups, setCountOfGroups] = useState(0);
  const [groups, setGroups] = useState<GroupDto[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);

  const [chosenGroup, setChosenGroup] =
    useState<GroupDto | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<GroupFilter>({});

  const groupSettingsColumnsDef: ColDef<GroupDto>[] = [
    { key: 'name', nested: false, isDate: false, sortable: true },
    { key: 'comment', nested: false, isDate: false, sortable: false },
  ];

  const fetchData = useCallback(async () => {
    try {
      const filteredData = await fetchGroupsForPage(
        currentPage,
        rowsPerPage,
        filter
      );
      setGroups(filteredData.data as GroupDto[]);
      setCountOfGroups(filteredData.metadata?.total || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [currentPage, filter, rowsPerPage, showGenericErrorToast]);

  const handleAddGroup = () => {
    setChosenGroup(null);
    setShowModal(true);
  };

  const handleEditGroup = (group: GroupDto) => {
    if (!group) return;
    setChosenGroup(group);
    setShowModal(true);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSubmit = async (
    values: GroupFormOutputValues,
    id?: number
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    if (id) {
      try {
        const data = (
          await GroupService.putGroupUpdate({
            id,
            requestBody: valuesWithoutNullFields as GroupRequestDto,
          })
        ).data;

        if (data) {
          setGroups(
            (groups) =>
              updateObjectInArray(groups, data) as GroupDto[]
          );
        }

        setChosenGroup(null);
        setShowModal(false);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      } catch (e) {
        showGenericErrorToast();
      }
    } else {
      try {
        const data = (
          await GroupService.postGroupCreate({
            requestBody: valuesWithoutNullFields as GroupRequestDto,
          })
        ).data;

        if (data) {
          fetchData();
        }

        setShowModal(false);
        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      } catch (e) {
        showGenericErrorToast();
      }
    }
  };

  const handleDeleteGroup = async (
    id: number,
    setShowModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (!id) return;

    try {
      await GroupService.deleteGroupDelete({ id });

      if (groups.length === 1 && currentPage !== 1) {
        setCurrentPage((page) => page - 1);
      }

      fetchData();

      showToast('success', t('notifications.itemWasSuccessfullyDeleted'));
      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleCancel = () => {
    setChosenGroup(null);
    setShowModal(false);
  };

  const updateFilter = async (
    key: string,
    value: string | boolean | undefined
  ) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData, filter, rowsPerPage]);

  return (
    <>
      <CDataGrid
        colDef={groupSettingsColumnsDef}
        translationKey="groupsSettingsPage"
        data={groups}
        onAdd={handleAddGroup}
        onDelete={handleDeleteGroup}
        onEdit={(values) => handleEditGroup(values as GroupDto)}
        filters={
          <GroupsFilters updateFilter={updateFilter} filter={filter} />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfGroups,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <Modal
        open={showModal}
        title={t('navigation.groups')}
        onClose={() => setShowModal(false)}
        type={chosenGroup ? 'edit' : 'create'}
        width={1000}
      >
        <GroupForm
          group={chosenGroup}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Modal>
    </>
  );
};
export default SettingsGroupPage;
