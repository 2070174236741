import * as yup from 'yup';

export const passwordSchema = yup
  .string()
  .matches(
    new RegExp('.*[A-Z].*'),
    'loginPage.validation.One uppercase character'
  )
  .matches(
    new RegExp('.*[a-z].*'),
    'loginPage.validation.One lowercase character'
  )
  .matches(new RegExp('.*\\d.*'), 'loginPage.validation.One number')
  .matches(
    new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'),
    'loginPage.validation.One special character'
  )
  .min(8, 'loginPage.validation.Must be at least 8 characters in length');

export const phoneSchema = yup
  .string()
  .nullable()
  .matches(/^\+\d+$/, 'validation.phone')
  .required('validation.required');

export const nullFieldsToUndefined = (
  values: Record<string, unknown>
): Record<string, unknown> => {
  for (const key in values) {
    if (values[key] === null || values[key] === '') {
      values[key] = undefined;
    }
  }
  return values;
};

export const isNumericString = (value: unknown) =>
  typeof value === 'string' && /^\d+$/.test(value);
