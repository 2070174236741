import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import noop from 'lodash/noop';

import { useSaved } from '@/hooks/use-saved';

import { FieldProps, OutputValue } from './types';

const Field = <T,>({
  name,
  notifyChange = noop,
  render,
}: {
  name: string;
  notifyChange?: (value: OutputValue) => void;
  render: (props: Omit<FieldProps<T>, 'required'>) => ReactElement | null;
}): ReactElement | null => {
  const { t } = useTranslation();

  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);

  const savedFunctions = useSaved({
    setValue,
    setTouched,
    notifyChange,
  });

  const onChange = useCallback(
    (value: OutputValue) => {
      savedFunctions.current.setValue(value);
      savedFunctions.current.notifyChange(value);
    },
    [savedFunctions]
  );

  const onBlur = useCallback(() => {
    savedFunctions.current.setTouched(true);
  }, [savedFunctions]);

  const errorMsg = useMemo(() => {
    if (!touched || !error) {
      return '';
    }
    return t(typeof error === 'string' ? error : 'error');
  }, [error, t, touched]);

  return render({
    name,
    value,
    onChange,
    onBlur,
    error: errorMsg,
  });
};

export default Field;
