/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PowerFactorDto } from '../models/PowerFactorDto';
import type { PowerFactorRequestDto } from '../models/PowerFactorRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PowerFactorService {
  /**
   * @returns any Power factor list.
   * @throws ApiError
   */
  public static getPowerFactorList({
    take,
    skip,
    name,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    name?: string;
    sortBy?: 'id' | 'name';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<PowerFactorDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/power-factor/',
      query: {
        name: name,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postPowerFactorCreate({
    requestBody,
  }: {
    /**
     * Create new power factor
     */
    requestBody: PowerFactorRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: PowerFactorDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/power-factor/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getPowerFactorFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: PowerFactorDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/power-factor/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Power factor not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putPowerFactorUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update power factor
     */
    requestBody: PowerFactorRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: PowerFactorDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/power-factor/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Center not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deletePowerFactorDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/power-factor/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Power factor not found`,
      },
    });
  }
}
