import { FC } from 'react';
import { useTranslation } from 'react-i18next';


import FilterItemWrapper from '../fields/FilterItemWrapper';
import Input from '../fields/Input';

export type GroupFilter = {
  name?: string;
  sortBy?:
    | 'id'
    | 'name'
    | 'comment'
    | undefined;
  descending?: boolean;
};

export type GroupsFiltersProps = {
  updateFilter: (key: string, value: string | undefined) => void;
  filter: GroupFilter;
};

const GroupsFilters: FC<GroupsFiltersProps> = ({
  updateFilter,
  filter,
}) => {
  const { t } = useTranslation();

  const filters = () => {
    return [
      <Input
        key="name"
        name="name"
        onChange={(value) => updateFilter('name', value as string)}
        onBlur={() => {}}
        error=""
        required={false}
        label={t('groupsSettingsPage.name')}
        value={filter.name || ''}
      />,
    ];
  };

  return (
    <>
      {filters().map((filter) => (
        <FilterItemWrapper key={filter.key}>{filter}</FilterItemWrapper>
      ))}
    </>
  );
};

export default GroupsFilters;
