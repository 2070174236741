import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import * as yup from 'yup';

import { UserService } from '@/_generatedApi';
import ProfileChangePasswordForm from '@/components/profile/ProfileChangePasswordForm';
import Button from '@/components/ui/common/button/CButton';
import Modal from '@/components/ui/common/modal/CModal';
import Input from '@/components/ui/fields/Input';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { UserContext } from '@/contexts/user-context/UserContext';
import { useShowToast } from '@/hooks/use-show-toast';
import { nullFieldsToUndefined } from '@/utils/validation';

const validationSchema = yup.object({
  username: yup.string().email(),
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string(),
});

export type ProfileFormValues = yup.InferType<typeof validationSchema>;

const ProfileForm: FC = () => {
  const { t } = useTranslation();
  const { showGenericErrorToast, showToast } = useShowToast();
  const { user, setUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const initialValues = {
    username: user?.username || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
  } satisfies ProfileFormValues;

  const handleSubmit = async (values: ProfileFormValues) => {
    try {
      if (!user) return;
      const updatedUser = (
        await UserService.putUserUpdate({
          id: user.id,
          requestBody: {
            ...user,
            ...nullFieldsToUndefined(values),
          },
        })
      ).data;

      if (updatedUser) {
        setUser(updatedUser);
      }

      showToast('success', t('notifications.itemWasSuccessfullyEdited'));
    } catch (e) {
      showGenericErrorToast();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '374px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="username"
              render={(props) => (
                <Input
                  label={t('profilePage.email')}
                  {...props}
                  data-cy-field="username"
                />
              )}
            />

            <Field<string>
              name="firstName"
              render={(props) => (
                <Input
                  label={t('profilePage.firstName')}
                  {...props}
                  data-cy-field="firstName"
                />
              )}
            />
            <Field<string>
              name="lastName"
              render={(props) => (
                <Input
                  label={t('profilePage.lastName')}
                  {...props}
                  data-cy-field="lastName"
                />
              )}
            />
            <Field<string>
              name="phone"
              render={(props) => (
                <Input
                  label={t('profilePage.contact')}
                  {...props}
                  data-cy-field="contact"
                />
              )}
            />
            <Box
              sx={{
                marginBottom: '25px',
              }}
            >
              <Button
                type="button"
                size="large"
                variant="common"
                data-cy-button="change-password"
                onClick={() => setShowModal(true)}
              >
                {t('profilePage.changePassword')}
              </Button>
              <Modal
                open={showModal}
                title={t('profilePage.changePassword')}
                onClose={() => setShowModal(false)}
                type="edit"
              >
                <ProfileChangePasswordForm />
              </Modal>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="button"
                size="large"
                disabled={!dirty}
                data-cy-button="cancel"
                onClick={resetForm}
                onMouseDown={(e) => e.preventDefault()}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                size="large"
                variant="update"
                disabled={isSubmitting}
                data-cy-button="save"
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ProfileForm;
