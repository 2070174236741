import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {BreakdownRequest, OdometerConsumptionMultipleDto, OdometerService} from "@/_generatedApi";
import {getStartAndEndDateByTimeInterval} from "@/utils/format";
import ReactECharts from "echarts-for-react";

export const SankeyPage: FC = () => {
  const [data, setData] = useState<OdometerConsumptionMultipleDto[]>()

  const fetchData = useCallback(() => {
    const filter = getStartAndEndDateByTimeInterval(BreakdownRequest.interval._1_MONTH);
    OdometerService.getOdometerConsumption({
      ...filter,
      consumptionUnit: "1 month"
    }).then((response) => setData(response.data));
  }, [])

  useEffect(() => fetchData(), [])

  const [nodes, links] = useMemo(() => {
    const nodes: { name: string }[] = [];
    const links: { source: string, target: string, value: number }[] = [];

    data?.forEach((item) => {
      nodes.push({name: item.odometer.name})
      if (item.odometer.branchOffice && !nodes.find(node => node.name === item.odometer.branchOffice?.name)) {
        nodes.push({name: item.odometer.branchOffice.name});
      }
      if (item.odometer.workshop && !nodes.find(node => node.name === item.odometer.workshop?.name)) {
        nodes.push({name: item.odometer.workshop.name});
      }
      if (item.odometer.group && !nodes.find(node => node.name === item.odometer.group?.name)) {
        nodes.push({name: item.odometer.group.name});
      }

      if (item.odometer.workshop) {
        links.push({source: item.odometer.workshop.name, target: item.odometer.name, value: item.data[0].value})

        if (item.odometer.branchOffice) {
          const link = links.find(link => link.source === item.odometer.branchOffice?.name && link.target === item.odometer.workshop?.name)
          if (link) {
            link.value += item.data[0].value
          } else {
            links.push({
              source: item.odometer.branchOffice.name,
              target: item.odometer.workshop.name,
              value: item.data[0].value
            })
          }
        }
      } else if (item.odometer.branchOffice) {
        links.push({source: item.odometer.branchOffice.name, target: item.odometer.name, value: item.data[0].value})
      }

      if (item.odometer.group) {
        links.push({source: item.odometer.name, target: item.odometer.group.name, value: item.data[0].value})
      } else {
        if (!nodes.find(node => node.name === 'Bez skupiny')) {
          nodes.push({name: 'Bez skupiny'});
        }
        links.push({source: item.odometer.name, target: 'Bez skupiny', value: item.data[0].value})
      }
    })
    return [nodes, links];
  }, [data])

  const option = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    series: {
      type: 'sankey',
      emphasis: {
        focus: 'adjacency'
      },
      nodeAlign: 'right',
      data: nodes,
      links: links,
      levels: [
        {
          depth: 0,
          itemStyle: {
            color: '#fbb4ae'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 1,
          itemStyle: {
            color: '#b3cde3'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 2,
          itemStyle: {
            color: '#ccebc5'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 3,
          itemStyle: {
            color: '#decbe4'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        }
      ],
      lineStyle: {
        curveness: 0.5
      }
    }
  };

  return (
    <ReactECharts opts={{locale: 'cs'}} style={{height: 800, width: '100%'}} option={option}/>
  );
}
