import { FC, PropsWithChildren } from 'react';
import {Box, SxProps} from '@mui/material';

interface FilterItemWrapperProps extends PropsWithChildren {
  width?: string | number;
  sx?: SxProps;
}

const FilterItemWrapper: FC<FilterItemWrapperProps> = ({
  width = 200,
  children,
  sx
}) => {
  return (
    <Box
      sx={{ flex: `0 0 ${width}px`, display: 'flex', flexDirection: 'column', ...sx }}
    >
      {children}
    </Box>
  );
};

export default FilterItemWrapper;
