/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';
import type { WorkshopDto } from '../models/WorkshopDto';
import type { WorkshopRequestDto } from '../models/WorkshopRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkshopService {
  /**
   * @returns any Workshops list.
   * @throws ApiError
   */
  public static getWorkshopList({
    take,
    skip,
    deleted,
    name,
    branchOfficeId,
    sortBy,
    descending,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    name?: string;
    branchOfficeId?: string;
    sortBy?: 'id' | 'name' | 'comment' | 'branchOffice';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<WorkshopDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/workshop/',
      query: {
        deleted: deleted,
        name: name,
        branchOfficeId: branchOfficeId,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postWorkshopCreate({
    requestBody,
  }: {
    /**
     * Create new workshop
     */
    requestBody: WorkshopRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: WorkshopDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/workshop/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getWorkshopFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: WorkshopDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/workshop/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Branch office not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putWorkshopUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update workshop
     */
    requestBody: WorkshopRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: WorkshopDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/workshop/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Workshop not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteWorkshopDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/workshop/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Workshop not found`,
      },
    });
  }
}
