import { useContext, useMemo } from 'react';
import { Redirect, Route, useRoute } from 'wouter';

import { UserContext } from '@/contexts/user-context/UserContext';
import { ROUTE_PATHS } from '@/Routes';

export const AuthRoute: typeof Route = (props) => {
  const [isMatching] = useRoute(props.path ?? '');

  const { user, auth } = useContext(UserContext);

  const shouldRedirectToLogin = useMemo(
    () => isMatching && !auth.isAuthenticated,
    [isMatching, auth.isAuthenticated]
  );

  const shouldRedirectToChangePassword = useMemo(
    () => isMatching && auth.isAuthenticated && !user?.confirmed,
    [isMatching, auth.isAuthenticated, user?.confirmed]
  );

  if (shouldRedirectToChangePassword) {
    return <Redirect to={ROUTE_PATHS.CHANGE_PASSWORD} />;
  }

  if (shouldRedirectToLogin) {
    return <Redirect to={ROUTE_PATHS.LOGIN} />;
  }

  return <Route {...props}>{props.children}</Route>;
};

export const UnAuthRoute: typeof Route = (props) => {
  const [isMatching] = useRoute(props.path ?? '');
  const { user, auth } = useContext(UserContext);

  const shouldRedirectToHP = useMemo(
    () => isMatching && auth.isAuthenticated && user?.confirmed,
    [isMatching, auth.isAuthenticated, user?.confirmed]
  );

  if (shouldRedirectToHP) {
    return (
      <Redirect to={`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ENERGY_DASHBOARD}`} />
    );
  }

  return <Route {...props}>{props.children}</Route>;
};
