import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LangSwitcher from '@/components/lang-switcher/LangSwitcher';

const Main: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      component="main"
      sx={{
        padding: theme.spacing(3),
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <LangSwitcher />
      </Box>
    </Box>
  );
};

export default Main;
