import { FC, PropsWithChildren } from 'react';
import { Link } from 'wouter';

const WithLink: FC<
  PropsWithChildren<{
    href?: string;
  }>
> = ({ children, href }) => {
  if (href !== undefined) {
    return (
      <Link href={href} style={{ cursor: 'pointer' }}>
        {children}
      </Link>
    );
  }

  return <>{children}</>;
};

export default WithLink;
