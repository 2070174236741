import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import ApiClient from '@/components/api/ApiClient';
import { useIsMobile } from '@/hooks/use-is-mobile';

import { locales } from './constants/locales';
import { UserProvider } from './contexts/user-context/UserContext';
import MuiThemeProvider from './theme/MuiThemeProvider';
import Routes from './Routes';

const App: FC = () => {
  const isMobile = useIsMobile();
  const { i18n } = useTranslation();

  return (
    <div style={{ height: '100vh' }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locales[i18n.language]}
      >
        <MuiThemeProvider>
          <SnackbarProvider dense={isMobile}>
            <UserProvider>
              <ApiClient />
              <Routes />
            </UserProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </div>
  );
};

export default App;
