/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OdometerConsumptionDataDto } from './OdometerConsumptionDataDto';
import type { OdometerHeaderDto } from './OdometerHeaderDto';

export type OdometerConsumptionSummaryDto = {
  unit: OdometerConsumptionSummaryDto.unit;
  consumptionData: Array<OdometerConsumptionDataDto>;
  odometers: Array<OdometerHeaderDto>;
};

export namespace OdometerConsumptionSummaryDto {
  export enum unit {
    YEAR = 'Year',
    MONTH = 'Month',
    WEEK = 'Week',
  }
}
