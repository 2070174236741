/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BreakdownRequest } from '../models/BreakdownRequest';
import type { BreakdownResponse } from '../models/BreakdownResponse';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeService {
  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postBreakdown({
    requestBody,
  }: {
    requestBody: BreakdownRequest;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: BreakdownResponse;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/breakdown',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
