import {useMemo} from 'react';
import useLocalStorage from "use-local-storage";
import {ElectricityDashboardsFilter} from "@/components/ui/filters/ElectricityDashboardFilters";

const filterOrder: (keyof ElectricityDashboardsFilter)[] = [
  'typeId',
  'branchOfficeId',
  'workshopId',
  'centerId',
  'groupId',
  'odometerId',
  'timeInterval',
  'consumptionStartDate',
  'consumptionEndDate',
  'consumptionUnit',
];

export const useDashboardFilter = (
  requiredFilters: (keyof ElectricityDashboardsFilter)[] = [],
  filterPrefix = 'electricity'
) => {
  const [filter, setFilter] = useLocalStorage<ElectricityDashboardsFilter>(
    `${filterPrefix}-dashboard-filter`,
    {},
    {syncData: false}
  );

  console.log(filter);

  const updateFilter = async (params: ElectricityDashboardsFilter) => {
    // Both interfaces are generated automatically based on the api definition, unfortunately incompatible
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedFilter: any = {};
    let hitKey = false;

    for (const filterKey of filterOrder) {
      if (hitKey) {
        break;
      }

      if (params[filterKey] !== undefined) {
        updatedFilter[filterKey] = params[filterKey];
        // if (params[filterKey] === '') {
        //   hitKey = true;
        // }
      } else {
        updatedFilter[filterKey] =
          filter[filterKey as keyof ElectricityDashboardsFilter];
      }
    }

    setFilter(updatedFilter);
  };

  const remainingFiltersToFetch = useMemo(() => {
    const remainingFilters = [];

    for (const filterName of filterOrder.filter(
      (filterName) =>
        !['consumptionStartDate', 'consumptionEndDate'].includes(filterName)
    )) {
      const filterValue = filter[filterName];

      if (requiredFilters.includes(filterName) && (filterValue === null || filterValue === undefined || filterValue === '')) {
        remainingFilters.push(filterName.replace('Id', ''));
      }
    }

    return remainingFilters;
  }, [filter]);

  return {filter, updateFilter, remainingFiltersToFetch}
};
