import { FC, useEffect, useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import classNames from 'classnames';
import styles from '@/components/settings-workshops/SettingsWorkshopPlan.module.css';
import DialogTitle from '@mui/material/DialogTitle';
import OdometerGaugeGraph from '@/components/graphs/odometer-gauge-graph/OdometerGaugeGraph';
import Dialog from '@mui/material/Dialog';
import { Odometer } from '@/pages/electricity-workshop-page/Odometer';
import { Medium } from '@/constants/mediums';

interface PlanProps {
  plan: string;
}

export const Plan: FC<PlanProps> = ({plan}) => {
  const [open, setOpen] = useState(false);
  const {editor, selectedObjects, onReady} = useFabricJSEditor();

  const planJSON: any = JSON.parse(plan);

  useEffect(() => {
    editor?.canvas?.loadFromJSON(planJSON, () => {
      editor?.canvas?.renderAll();
      editor?.canvas?.forEachObject((object) => {
        if (object.type == 'rect') {
          editor?.canvas?.remove(object);
        }
        object.selectable = false;
      })
      editor?.canvas?.renderAll();
    });
  }, [plan, editor?.canvas]);

  return <>
    <div style={{position: 'relative'}}>
      <FabricJSCanvas className={classNames(styles.canvasReadonly)} onReady={onReady}/>
      {
        planJSON?.objects?.filter((object: any) => object.type == 'rect' && object.data.value > 0).map((object: any) => (
          <div style={{position: 'absolute', left: object.left, top: object.top}}>
            <Odometer key={object.data.value + '-' + object.top} id={object.data.value} onClick={() => setOpen(true)} />
          </div>
        ))
      }
    </div>

    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Detail elektroměru</DialogTitle>
      <OdometerGaugeGraph
        consumption={11.3}
        maxConsumption={1}
        totalConsumption={0}
        lastThreeMonthData={[]}
        linkToDetail={`/energy/electricity/readings/39`}
        title={''}
        medium={Medium.electricity}
      />
    </Dialog>
  </>
};
