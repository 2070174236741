import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import useLocalStorage from 'use-local-storage';
import { useLocation } from 'wouter';

import { AuthService, UserDto, UserService } from '@/_generatedApi';
import { ErrorCodes } from '@/constants/errorCodes';
import { useShowToast } from '@/hooks/use-show-toast';
import { LoginValues } from '@/pages/login-page/LoginPage';
import { ROUTE_PATHS } from '@/Routes';

import { Auth, AuthContextType } from './types';

export const UserContext = createContext<AuthContextType>({
  user: null,
  setUser: noop,
  auth: {
    token: null,
    isAuthenticated: false,
  },
  login: noop,
  logout: noop,
  updateToken: noop,
});

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { showToast, showGenericErrorToast } = useShowToast();

  const [, setLocation] = useLocation();

  const [user, setUser] = useLocalStorage<UserDto | null>('user', null);
  const [auth, setAuth] = useLocalStorage<Auth>('auth', {
    token: null,
    isAuthenticated: false,
  });
  const [userId, setUserId] = useState<number | null>(null);

  const { t } = useTranslation();

  const isAuthenticated = user !== null;

  const fetchUser = useCallback(async () => {
    try {
      if (!userId || !auth.token) return;

      const user = (await UserService.getUserFind({ id: userId })).data;

      if (user) {
        setUser(user);

        if (!user.confirmed) {
          setLocation(ROUTE_PATHS.CHANGE_PASSWORD);
        } else {
          setLocation(`${ROUTE_PATHS.ENERGY}${ROUTE_PATHS.ENERGY_DASHBOARD}`);
        }

        showToast('success', t('loginPage.successLoginMessage'));
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [auth.token, userId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const login = useCallback(
    async (values: LoginValues) => {
      try {
        const auth = await AuthService.postLogin({ requestBody: values });

        if (auth.id && auth.accessToken) {
          setAuth({ token: auth.accessToken, isAuthenticated: true });
          setUserId(auth.id);
        }
      } catch (e) {
        if ((e as { status: number }).status === ErrorCodes.UNAUTHORIZED) {
          showToast('error', t('error.unauthorized'));
        }
      }
    },
    [setAuth, showToast, t]
  );

  const updateToken = useCallback(
    async (accessToken: string) => {
      setAuth({ token: accessToken, isAuthenticated });
      setUser((user) => {
        if (user) return { ...user, confirmed: true };
        return null;
      });
    },
    [isAuthenticated, setAuth, setUser]
  );

  const logout = useCallback(() => {
    setAuth({
      token: null,
      isAuthenticated: false,
    });
    setUser(null);
  }, [setAuth, setLocation, setUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        auth,
        login,
        logout,
        updateToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
