import { FC, useContext } from 'react';
import { List } from '@mui/material';
import { useLocation, useRoute } from 'wouter';

import { MenuContext } from '@/contexts/layout/MenuContext';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { Menu } from '@/types/core-types';

import DrawerButton from './DrawerButton';

const useMenu = () => {
  const [location] = useLocation();

  const isMobile = useIsMobile();

  const { menu } = useContext(MenuContext);

  if (isMobile) {
    return menu;
  }

  const visiblePaths = Object.entries(menu ?? {}).reduce<string[]>(
    (acc, [path]) => {
      if (location.startsWith(path)) {
        acc.push(path);
      }

      return acc;
    },
    []
  );

  const matchedPath = visiblePaths.sort((a, b) => b.length - a.length).at(0);

  if (matchedPath === undefined) {
    return undefined;
  }

  return menu?.[matchedPath]?.menu;
};

const Item: FC<
  {
    path: string;
  } & Menu[string]
> = ({ path, name, icon, isMenuHidden, menu, isRoot }) => {
  const [isMatching] = useRoute(`${path}/:rest*`);

  const isMobile = useIsMobile();

  if (
    menu &&
    import.meta.env.VITE_ENABLED_FEATURE_FLAGS &&
    !import.meta.env.VITE_ENABLED_FEATURE_FLAGS.includes(name)
  ) {
    return null;
  }

  if (
    isMenuHidden === (isMobile ? 'mobile' : 'desktop') ||
    isMenuHidden === true
  ) {
    return null;
  }

  return (
    <DrawerButton
      name={name}
      icon={isRoot ? undefined : icon}
      href={path}
      active={isMatching}
      menu={menu}
    />
  );
};

const SideNav: FC<{
  menu?: Menu;
}> = ({ menu }) => {
  const localMenu = useMenu();
  const finalMenu = menu ?? localMenu;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      disablePadding
    >
      {Object.entries(finalMenu ?? {}).map(([path, rest]) => (
        <Item key={path} path={path} {...rest} />
      ))}
    </List>
  );
};

export default SideNav;
