import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Link } from 'wouter';
import * as yup from 'yup';

import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import Password from '@/components/ui/fields/Password';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { UserContext } from '@/contexts/user-context/UserContext';
import { ROUTE_PATHS } from '@/Routes';

const validationSchema = yup.object({
  username: yup.string().email().required('validation.required'),
  password: yup.string().required('validation.required'),
});

export type LoginValues = yup.InferType<typeof validationSchema>;

const initialValues = {
  username: '',
  password: '',
} satisfies LoginValues;

const LoginPage: FC = () => {
  const { t } = useTranslation();

  const { login } = useContext(UserContext);

  const handleSubmit = async (values: LoginValues) => {
    login(values);
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="username"
              render={(props) => (
                <Input
                  label={t('loginPage.emailAddress')}
                  {...props}
                  data-cy="username"
                />
              )}
            />
            <Field<string>
              name="password"
              render={(props) => (
                <Password
                  label={t('loginPage.password')}
                  {...props}
                  data-cy="password"
                />
              )}
            />
            <Button
              type="submit"
              size="large"
              variant="add"
              disabled={isSubmitting}
              data-cy="login"
              // fix disappearing button causing first button click not working
              onMouseDown={(event)=>{event.preventDefault()}}
              startIcon={null}
            >
              {t('loginPage.login')}
            </Button>
          </Box>
        )}
      </Form>
      <Link
        href={ROUTE_PATHS.FORGOT_PASSWORD}
        style={{ color: '#23244f', fontSize: '14px', marginTop: '24px' }}
      >
        {t('loginPage.forgotPassword')}
      </Link>
    </Box>
  );
};

export default LoginPage;
