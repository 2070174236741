import { FC } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classnames from 'classnames';

import { DOTS, usePagination } from '../../../../../hooks/use-pagination';

import styles from './pagination.module.css';

type PaginationProps = {
  onPageChange: (page: number) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
};

const Pagination: FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
}) => {
  const SIBLING_COUNT = 1;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: SIBLING_COUNT,
    pageSize,
  });

  if (!paginationRange) return null;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={classnames(styles.pages)}>
      <button
        className={classnames(
          styles.button,
          currentPage === 1 ? styles.disabled : styles.block
        )}
        onClick={onPrevious}
      >
        <ChevronLeftIcon />
      </button>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <button className={classnames(styles.button)} key={pageNumber}>
              &#8230;
            </button>
          );
        }

        return (
          <button
            key={pageNumber}
            className={classnames(
              styles.button,
              pageNumber === currentPage ? styles.currentPage : styles.page
            )}
            onClick={() => onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        className={classnames(
          styles.button,
          currentPage === lastPage ? styles.disabled : styles.block
        )}
        onClick={onNext}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default Pagination;
