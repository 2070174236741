import {FC, useState} from "react";
import {useWorkshops} from "@/hooks/data/use-workshops";
import Select from "@/components/ui/fields/Select";
import {useWorkshop} from "@/hooks/data/use-workshop";
import {Plan} from "@/pages/electricity-workshop-page/Plan";
import {useBranchOffices} from "@/hooks/data/use-branch-offices";

const ElectricityWorkshopPage: FC = () => {
  const {workshopsOptions} = useWorkshops();
  const [workshopId, setWorkshopId] = useState<number>((workshopsOptions?.[0]?.value as number) || 0);
  const workshop = useWorkshop(workshopId);
  const {branchOffices} = useBranchOffices();

  return (
    <div style={{position: 'relative'}}>

      {
        branchOffices.map(office => (<Plan plan={office.plan || '[]'} />))
      }

      <br/>
      <br/>
      <br/>
      <br/>

      <div>
        <Select
          name="workshop"
          options={workshopsOptions}
          onChange={(value) => {
            setWorkshopId(value as number);
          }}
          onBlur={() => {
          }}
          error=""
          required={false}
          label={'Hala'}
          value={workshopId as any}
          withoutHelperText
          clearable
          width={300}
        />
      </div>

      {
        workshop && workshop.plan && <Plan plan={workshop.plan} />
      }
    </div>
  );
};

export default ElectricityWorkshopPage;
