import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

import { OdometerRequestDto, OdometerService } from '@/_generatedApi';
import SettingsOdometerForm, {
  SettingsOdometerFormOutputValues,
} from '@/components/settings-odometers/SettingsOdometerForm';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';
import { nullFieldsToUndefined } from '@/utils/validation';

const SettingsOdometerCreatePage: FC = () => {
  const [, setLocation] = useLocation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const { t } = useTranslation();

  const handleSubmit = async (values: SettingsOdometerFormOutputValues) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);

    try {
      await OdometerService.postOdometerCreate({
        requestBody: valuesWithoutNullFields as OdometerRequestDto,
      });

      setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`);
      showToast('success', t('notifications.itemWasSuccessfullyCreated'));
    } catch (e) {
      showGenericErrorToast();
    }
  };

  return (
    <SettingsOdometerForm
      onSubmit={handleSubmit}
      onCancel={() =>
        setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`)
      }
      onBack={() =>
        setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`)
      }
    />
  );
};

export default SettingsOdometerCreatePage;
