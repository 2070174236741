export default {
  translation: {
    common: {
      actions: 'Actions',
      detail: 'Detail',
      open: 'Open',
      close: 'Close',
      back: 'Back',
      delete: 'Delete',
      confirm: 'Confirm',
      cancel: 'Cancel',
      add: 'Add',
      edit: 'Edit',
      show: 'Show',
      hide: 'Hide',
      clear: 'Clear',
      select: 'Select',
      continue: 'Continue',
      clearSelect: 'Clear select',
      save: 'Save',
      areYouSureToDeleteItem: 'Do you really want to remove item: {{item}}?',
      areYouSureToDeleteItemCommon: 'Do you really want to remove this item',
      empty: 'Empty',
      rowsPerPage: 'Rows per page',
      noDataFound: 'No data found',
      submit: 'Submit',
      minutesShort: '{{value}} min',
    },
    localization: {
      cs: 'Česky',
      en: 'English',
      language: 'Language',
      changeLanguageAction: 'Change language',
    },
    navigation: {
      energy: 'Energy',
      'energy-dashboard': 'Energy',
      backToHomepage: 'Back to homepage',
      environment: 'Environment',
      electricity: 'Electricity',
      water: 'Water',
      gas: 'Gas',
      readings: 'Readings',
      alarms: 'Alarms',
      dashboard: 'Dashboard',
      settings: 'Settings',
      'branch-offices': 'Branch offices',
      'historical-odometers': 'Odometers history',
      'alarms-history': 'Alarms history',
      workshops: 'Workshops',
      centers: 'Centers',
      odometers: 'Odometers',
      users: 'Users',
    },
    error: {
      unauthorized: 'Invalid credentials.',
      internalServerError: 'Something get wrong. Please contact support.',
      generic: 'Something get wrong.',
      pageNotFound: 'Page not found',
    },
    notifications: {
      itemWasSuccessfullyCreated: 'Item was successfully created.',
      itemWasSuccessfullyEdited: 'Item was successfully edited.',
      itemWasSuccessfullyDeleted: 'Item was successfully deleted.',
      successChangePassword: 'Password successfully changed.',
      requestForResetPasswordWasSent: 'Request for reset password was sent.',
    },
    validation: {
      nameMinChars: 'Name must contain min 4 characters',
      minChars: 'The number of characters must be at least {{count}}',
      maxChars: 'The maximum number of characters must be {{count}}',
      required: 'Required field',
      phone: 'Invalid phone number (example: +420999999999).',
      invalidDate: 'Invalid date',
      zeroOrMore: 'Must be 0 or more',
      hundredOrLess: 'Must be 100 or less',
    },
    loginPage: {
      login: 'Login',
      logout: 'Logout',
      successLoginMessage: 'User has successfully logged in.',
      username: 'Username',
      password: 'Password',
      forgotPassword: 'Forgotten password',
      newPassword: 'New password',
      'toggle password visibility': 'Toggle password visibility',
      oldPassword: 'Old password',
      repeatNewPassword: 'Repeat new password',
      sendLink: 'Send link',
      emailAddress: 'Email address',
      forgotPasswordInfo:
        'Enter the email address to which the password reset link should be sent.',
      validation: {
        passwordsDontMatch: 'Passwords dont match',
        'One uppercase character': 'At least one uppercase character',
        'One lowercase character': 'At least one lowercase character',
        'One number': 'At least one number',
        'One special character': 'At least one special character',
        'Must be at least 8 characters in length':
          'Must be at least 8 characters in length',
      },
    },
    forgotPasswordPage: {
      backToLogin: 'Back to login page',
    },
    changePasswordPage: {
      backToLogin: 'Back to login page',
    },
    underConstructionPage: {
      title: 'Page is under construction',
    },
    profilePage: {
      title: 'Profile settings',
      logout: 'Logout',
      username: 'Username',
      tooltip: 'Profile settings',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      contact: 'Contact',
      changePassword: 'Change password',
    },
    odometersSettingsPage: {
      title: 'Odometers',
      odometerInfo: 'Odometer information',
      assigned: 'Assigned',
      mapToOdometer: 'Map to odometer',
      name: 'Odometer name',
      medium: 'Medium',
      type: 'Odometer type',
      installationDate: 'Instalation Date',
      branchOffice: 'Branch office',
      branchOfficeName: 'Branch office name',
      workshop: 'Workshop',
      workshopName: 'Workshop name',
      center: 'Center',
      centerName: 'Center name',
      comment: 'Comment',
      optimalConsumption: 'Optimal consumption',
      maxHourlyValue: 'Max hourly limit',
      coefficient: 'Coeficient',
      idByUser: 'ID by user',
      counter: 'Counter',
      inputNumber: 'Input number',
      technicalName: 'Odometer technical name',
      externalId: 'External ID',
      remainingFilters: 'The following filters remain to be selected',
    },
    usersSettingsPage: {
      title: 'Users',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      phone: 'Phone',
      role: 'Role',
      username: 'Username',
      registrationPassword: 'Registration password',
      active: 'Active',
    },
    workshopsSettingsPage: {
      title: 'Workshops',
      name: 'Name',
      location: 'Location',
      branchOffice: 'Branch office',
      comment: 'Comment',
    },
    centersSettingsPage: {
      title: 'Centers',
      name: 'Name',
      location: 'Location',
      branchOffice: 'Branch office',
      workshop: 'Workshop',
      comment: 'Comment',
    },
    branchOfficesSettingsPage: {
      title: 'Branch offices',
      name: 'Name',
      city: 'City',
      comment: 'Comment',
      superiorBranchOffice: 'Superior branch office',
    },
    electricityPage: {
      alarmPage: {
        title: 'Alarm',
        odometer: 'Odometer name',
        value: 'Max value',
        users: 'Notified users',
        sendByPhone: 'Send by phone',
        sendByEmail: 'Send by e-mail',
        notify: 'Notify:',
        externalNotification: 'External notification',
        externalEmail: 'Add external e-mail',
        externalPhone: 'Add external phone',
      },
      alarmHistoryPage: {
        title: 'Alarm history',
        odometer: 'Odometer name',
        reason: 'Trigger',
        eventDate: 'Date of occurrence',
      },
      dashboardPage: {
        title: 'Dashboard',
        consumptionStartDate: 'Od',
        consumptionEndDate: 'Do',
        units: 'Time unit',
        consumptionUnit: 'Time unit',
        type: 'Odometer type',
        timeInterval: 'Time interval',
        odometerName: 'Odometer name',
        odometerType: 'Odometer type',
        medium: 'Medium',
        branchOffice: 'Branch office',
        workshop: 'Workshop',
        center: 'Center',
        electricityConsumptionForInterval:
          'Electricity consumption for period according to the filter',
        electricityIncrementalConsumptionGraph:
          'Incremental graph of electricity consumption for the selected period',
        electricityTrendOfHighestElectricityConsumptionGraph:
          'Trend of highest electricity consumption',
        electricityQuarterHourPeakGrowthGraph:
          'Evolution of the 1/4 hour highs',
        consumptionUnitEnum: {
          '1 minute': '1 minute',
          '15 minutes': '15 minutes',
          '1 hour': '1 hour',
          '1 day': '1 day',
          '1 week': '1 week',
          '1 month': '1 month',
          '3 months': '3 months',
          '6 months': '6 months',
          '1 year': '1 year',
        },
        timeIntervalEnum: {
          '1 day': '1 day',
          '1 week': '1 week',
          '1 month': '1 month',
          '3 months': '3 months',
          '6 months': '6 months',
          '1 year': '1 year',
          selectInterval: 'Vlastní interval',
        },
        electricity: 'Electricity',
        energyConsumption: 'Energy consumption {{unit}}',
      },
      readingsPage: {
        title: 'Readings',
      },
      electricityOdometersHistoryPage: {
        title: 'Odometers history',
        name: 'Internal name',
        medium: 'Medium',
        type: 'Odometer type',
        installationDate: 'Installation date',
        deletedAt: 'Remove date',
        branchOffice: 'Branch office',
        workshop: 'Workshop',
        center: 'Center',
        note: 'Note',
      },
    },
    electricityReadingsPage: {
      electricityTitle: 'Electricity',
      odometerName: 'Odometer name',
      gauge: {
        counterTitle: 'Total consumption',
        counterUnit: 'kWh',
        graphUnit: 'kWh/hour',
        detail: {
          period: 'Period',
          consumptionPer: 'Consumption per',
          perWeek: 'week',
          perMonth: 'month',
          perYear: 'year',
          month: 'Month',
          value: 'Value',
          consumption: 'Consumption',
          consumptionPerYear: 'Consumption per year: {{value}} kWH',
          from: 'From',
          to: 'To',
        },
      },

      filters: {
        medium: 'Medium',
        type: 'Odometer type',
        branchOffice: 'Branch office',
        workshop: 'Workshop',
        center: 'Center',
      },
    },
    dashboardPage: {
      consumption: 'Consumption',
      fromStartOfActualMonth: 'from start of actual month',
      electricity: 'Electricity',
      water: 'Water',
      gas: 'Gas',
      wastes: 'wastes / sludges',
      alarms: 'Alarms',
      forLastSelectedPeriod: 'for the last period',
      moreAlarms: 'More alarms',
      noAlarms: 'No alarms yet.',
      electricityGraphDescription:
        'Electricity consumption trend for the previous period by months - bar graph',
      lastYearValue: 'Last year value',
      totalValue: 'Total value',
      valueBelowAverage: 'Value below average',
      valueAboveAverage: 'Value above average',
      currentYearValue: 'Actual value',
      helloUser: 'Hello, {{value}}!',
      todayIs: 'Today is {{value}}',
      months: {
        JAN: 'JAN',
        FEB: 'FEB',
        MAR: 'MAR',
        APR: 'APR',
        MAY: 'MAY',
        JUN: 'JUN',
        JUL: 'JUL',
        AUG: 'AUG',
        SEP: 'SEP',
        OCT: 'OCT',
        NOV: 'NOV',
        DEC: 'DEC',
      },
    },
    enums: {
      userRoles: {
        ROLE_USER: 'User',
        ROLE_MANAGER: 'Manager',
        ROLE_ADMIN: 'Admin',
      },
    },
  },
};
