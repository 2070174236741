import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';

import {
  OdometerDto,
  OdometerRequestDto,
  OdometerService,
} from '@/_generatedApi';
import SettingsOdometerForm, {
  SettingsOdometerFormOutputValues,
} from '@/components/settings-odometers/SettingsOdometerForm';
import { useShowToast } from '@/hooks/use-show-toast';
import { ROUTE_PATHS } from '@/Routes';
import { nullFieldsToUndefined } from '@/utils/validation';

const SettingsOdometerEditPage: FC = () => {
  const [, params] = useRoute(
    `${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETER_EDIT}`
  );
  const [odometer, setOdometer] = useState<OdometerDto>();
  const [, setLocation] = useLocation();
  const { showToast, showGenericErrorToast } = useShowToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (params?.id) {
      OdometerService.getOdometerFind({ id: Number(params.id) })
        .then((response) => {
          setOdometer(response.data);
        })
        .catch(() => {
          showGenericErrorToast();
        });
    }
  }, [params?.id, showGenericErrorToast]);

  const handleSubmit = async (
    values: SettingsOdometerFormOutputValues,
    id?: number
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    if (id) {
      try {
        await OdometerService.putOdometerUpdate({
          id,
          requestBody: valuesWithoutNullFields as OdometerRequestDto,
        });
        setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`);
        showToast('success', t('notifications.itemWasSuccessfullyCreated'));
      } catch (e) {
        showGenericErrorToast();
      }
    }
  };

  return (
    <>
      {odometer && (
        <SettingsOdometerForm
          odometer={odometer}
          onSubmit={handleSubmit}
          onCancel={() =>
            setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`)
          }
          onBack={() =>
            setLocation(`${ROUTE_PATHS.SETTINGS}${ROUTE_PATHS.ODOMETERS}`)
          }
        />
      )}
    </>
  );
};
export default SettingsOdometerEditPage;
