import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react';
import ReactECharts from 'echarts-for-react';
import {OdometerConsumptionMultipleDto} from "@/_generatedApi";
import {format, getMinutes, setMinutes, subSeconds} from "date-fns";

const ElectricityPeakGraph: FC<{
  data: OdometerConsumptionMultipleDto;
  peakData: [Date, number][];
  zoom: { start: number, end: number }
  setZoom: Dispatch<SetStateAction<{ start: number, end: number }>>
}> = ({data, peakData, zoom, setZoom}) => {

  const option = useMemo(() => {
    let finalData = [...peakData];

    if (peakData.length > 0) {
      let lastDate = peakData[peakData.length - 1][0]
      const minutes = getMinutes(lastDate);
      if (minutes%15 !== 0) {
        lastDate = setMinutes(lastDate, (Math.floor(minutes / 15) + 1) * 15);
        finalData[peakData.length - 1] = [lastDate, peakData[peakData.length - 1][1]]
      }
    }

    finalData = finalData.map(item => [subSeconds(item[0], 450), item[1]])

    return {
      title: {
        text: '1/4 maxima',
        subtext: '',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function (params: any) {
          const param = params[0];
          return format(subSeconds(param.value[0], 450), 'HH:mm') + ': ' + (param?.value[1] || 0) + ' ' + data.odometer.unit;
        },
      },
      grid: {
        left: '1%',
        right: '1%',
        containLabel: true
      },
      xAxis: {
        type: 'time',
      },
      yAxis: {
        type: 'value'
      },
      dataZoom: [
        {
          type: 'slider',
          labelFormatter: '',
          start: zoom.start,
          end: zoom.end,
        },
        {
          type: 'inside',
          zoomLock: true,
        }
      ],
      visualMap: {
        seriesIndex: 0,
        pieces: [
          {'gt': data.odometer.maxHourlyValue, color: 'red'},
          {'gt': data.odometer.optimalConsumption, 'lte': data.odometer.maxHourlyValue, color: 'orange'},
          {'gte': 0, 'lte': data.odometer.optimalConsumption, color: '#4bcfa7'}
        ],
        show: false
      },
      legend: { show: false },
      series: [
        {
          name: '',
          type: 'bar',
          barCategoryGap: '2%',
          data: finalData.map(value => [value[0], Math.round(value[1] * 4 * 100) / 100]),
        },
      ]
    }
  }, [peakData, zoom]);

  return (
    <ReactECharts style={{height: 350, width: '100%'}} option={option} notMerge={true} onChartReady={(chart) => {
      chart.on('dataZoom', (params: any) => setZoom({start: params.start, end: params.end}))
    }}/>
  );
};

export default ElectricityPeakGraph;
