import {
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react';
import { deepFind, filter } from 'react-children-utilities';
import { RouterObject, useRouter } from 'wouter';

const useLocationFromRouter = (router: RouterObject) => router.hook(router);

const RoutesGroup: FC<
  PropsWithChildren<{
    page404?: ReactElement;
  }>
> = ({ children, page404 }) => {
  const router = useRouter();
  const matcher = router.matcher;
  const [location] = useLocationFromRouter(router);

  const filteredChildren = useMemo(() => {
    return filter(children, (child) => {
      return !!deepFind(child, (child) => {
        if (!isValidElement(child)) {
          return false;
        }

        const [isMatching] = matcher(child.props.path, location);

        return !!(isMatching || child.props.page404);
      });
    });
  }, [children, location, matcher]);

  if (!filteredChildren.length) {
    return page404 ?? null;
  }

  return <>{filteredChildren.at(0)}</>;
};

export default RoutesGroup;
